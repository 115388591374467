import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  max-width: 400px;
  width: 90%;
  margin-left: 30px;
  margin-right: 30px;

  &.s {
    width: 95%;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const AdminInfoText = styled("span")`
  margin-top: 0;
  font-size: 16px;
  text-align: flex-start;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 300px;
    word-break: break-all;
    overflow: hidden;
  }
`;

export const ButtonContainer = styled("div")`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Subheader = styled("span")`
  font-weight: 500;
  font-size: 16px;
  text-align: flex-start;
  margin-top: 5px;
`;

export const InfoHeader = styled("p")`
  text-align: flex-start;
`;

export const LinkText = styled("p")`
  font-style: italic;
  font-size: 16px;
  text-align: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
