import { StyledDataGrid, cellStyle } from "./styles";

import React from "react";
import { columns } from "./columns";
import { localizedTextsMap } from "../../../../../localization/dataGrid";

const CommentsTable = ({ comments }) => {
  return (
    <>
      <StyledDataGrid
        disableColumnSelector
        disableRowSelectionOnClick
        stickyHeader
        sx={cellStyle}
        localeText={localizedTextsMap}
        columns={columns}
        rows={comments}
        getRowId={(row) => row.key}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
      />
    </>
  );
};

export default CommentsTable;
