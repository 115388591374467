import { ActionAlertDropdown, ActionContainer, Container } from "./styles";
import React, { useEffect, useState } from "react";

import ActionList from "./ActionList";
import { CreateEmployee } from "./CreateEmployee";
import CreationModal from "./modals/CreationModal";
import DeleteModal from "./modals/DeleteModal";
import InvitationAlert from "./InvitationAlert";
import InvitationModal from "./modals/InvitationModal";
import ReminderModal from "./ReminderModal";
import SearchUser from "./SearchUser";

const ActionArea = ({
  updateEmployeeList,
  employees,
  setSearchQuery,
  selected,
  setSelected,
  handleSelectAllClick,
  someChecked,
  allChecked,
}) => {
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState(false);
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [newEmps, setNewEmps] = useState(false);
  const [invitedEmps, setInvitedEmps] = useState([]);
  const [superAdmins, setSuperAdmins] = useState([]);

  const hasUnregisteredEmps = employees.some(
    (emp) => emp.status === "created" || emp.status === "invited"
  );

  useEffect(() => {
    const empsWithMail = employees.filter((emp) => emp.mail);
    const selectedEmps = empsWithMail.filter((emp) =>
      selected.includes(emp.id)
    );
    const newEmps = selectedEmps.filter((emp) => emp.status === "created");
    const invitedEmps = selectedEmps.filter((emp) => emp.status === "invited");
    const superAdmins = selectedEmps.filter(
      (emp) => emp.role === "super admin"
    );

    setNewEmps(newEmps);
    setInvitedEmps(invitedEmps);
    setSuperAdmins(superAdmins);
  }, [employees, selected]);

  return (
    <Container>
      <InvitationModal
        isModalOpen={isInvitationModalOpen}
        closeModal={() => setIsInvitationModalOpen(false)}
        setNewEmps={setNewEmps}
        updateEmployeeList={updateEmployeeList}
        setSelected={setSelected}
        selected={newEmps}
      />
      <ReminderModal
        isModalOpen={isReminderModalOpen}
        closeModal={() => setIsReminderModalOpen(false)}
        updateEmployeeList={updateEmployeeList}
        setSelected={setSelected}
        selected={invitedEmps}
      />
      <CreationModal
        isModalOpen={isCreateNewModalOpen}
        closeModal={() => setIsCreateNewModalOpen(false)}
        updateEmployeeList={updateEmployeeList}
      />
      <DeleteModal
        selected={selected}
        isModalOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        updateEmployeeList={updateEmployeeList}
        setSelected={setSelected}
      />
      <ActionContainer>
        <SearchUser setSearchQuery={setSearchQuery} />
        <ActionAlertDropdown>
          <InvitationAlert showAlert={hasUnregisteredEmps} />
          <CreateEmployee
            onOpenCreateModal={() => setIsCreateNewModalOpen(true)}
          />
        </ActionAlertDropdown>
      </ActionContainer>
      <ActionList
        selected={selected}
        handleSelectAllClick={handleSelectAllClick}
        onOpenInviteModal={() => setIsInvitationModalOpen(true)}
        onOpenReminderModal={() => setIsReminderModalOpen(true)}
        onOpenDeleteModal={() => setIsDeleteModalOpen(true)}
        isInviteActive={newEmps.length > 0}
        isRemindActive={invitedEmps.length > 0}
        isDeleteActive={!superAdmins.length > 0}
        someChecked={someChecked}
        allChecked={allChecked}
      />
    </Container>
  );
};

export default ActionArea;
