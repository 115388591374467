import Dropdown from "../../../../../common/Dropdown";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import { disabilityOptions } from "../../../diversityOptions";

const DisabilityInput = ({ props }) => {
  const { employee, updateDisabilities, dynamicSize } = props;
  return (
    <FieldContainer>
      <Dropdown
        size={dynamicSize}
        value={employee.disabilities}
        label="Körperliche / Geistliche Fähigkeiten"
        menuItems={disabilityOptions}
        multiple
        onChange={({ target }) => updateDisabilities(target.value)}
      />
    </FieldContainer>
  );
};

export default DisabilityInput;
