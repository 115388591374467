import { AreasTitle, GridContainer } from "./styles";
import React, { useEffect, useState } from "react";

import AreaMenuItem from "../AreaMenuItems";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const AreaSelection = () => {
  const { getAreas } = useLightsApiPrivate();
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAreas = async () => {
      const areas = await getAreas();
      setAreas(areas);
    };

    if (loading) {
      setLoading(false);
      fetchAreas();
    }
  }, [loading, getAreas]);

  const normalAreas = areas.filter((area) => !area.isCustom);
  const customAreas = areas.filter((area) => area.isCustom);

  return (
    <>
      <GridContainer>
        {customAreas.length > 0 && <AreasTitle>Diverlyze Bereiche</AreasTitle>}
        {normalAreas.map((area) => (
          <AreaMenuItem
            key={area._id}
            areaTitle={area.name}
            areaId={area._id}
            areaIdentifier={area.identifier}
            isCustom={area.isCustom}
          />
        ))}
      </GridContainer>
      {customAreas.length > 0 && (
        <GridContainer>
          <AreasTitle>Custom Bereiche</AreasTitle>
          {customAreas.map((area) => (
            <AreaMenuItem
              key={area._id}
              areaTitle={area.name}
              areaId={area._id}
              areaIdentifier={area.identifier}
              isCustom={area.isCustom}
            />
          ))}
        </GridContainer>
      )}
    </>
  );
};

export default AreaSelection;
