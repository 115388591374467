import { DownloadLink, DownloadText } from "./styles";
import {
  initiateCsvDownload,
  initiateInstructionDownload,
} from "./downloadHelper";

import React from "react";

const DownloadSection = () => {
  return (
    <div>
      <DownloadText>Lade hier die </DownloadText>
      <DownloadLink href onClick={() => initiateCsvDownload()}>
        CSV-Datei
      </DownloadLink>
      <DownloadText> für den Import und die </DownloadText>
      <DownloadLink href onClick={() => initiateInstructionDownload()}>
        Anleitung
      </DownloadLink>
      <DownloadText> zum Hochladen der Datei herunter.</DownloadText>
    </div>
  );
};

export default DownloadSection;
