import styled from "@emotion/styled/macro";

export const Container = styled("div")`
  width: 47%;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  &.s {
    margin: 10px 5px;
    min-width: ${({ minWidth }) => minWidth || "47%"};
    text-align: start;
  }
`;
