export const columns = [
  { field: "firstName", headerName: "Vorname", width: 150 },
  { field: "lastName", headerName: "Nachname", width: 150 },
  {
    field: "mail",
    headerName: "E-Mail",
    width: 290,
  },
  {
    field: "teams",
    headerName: "Teams",
    width: 290,
  },
];
