export const buildSurveeOverview = (employees) => {
  const surveeOverview = {
    id: "1",
    name: `${employees.length} Pulse-Check-Teilnehmende`,
  };

  const data = [
    {
      name: "active",
      value: 0,
    },
    { name: "invited", value: 0 },
    { name: "created", value: 0 },
  ];

  employees.forEach((employee) => {
    if (employee.status === "active") {
      data[0].value++;
    } else if (employee.status === "invited") {
      data[1].value++;
    } else {
      data[2].value++;
    }
  });

  surveeOverview.data = data;
  return surveeOverview;
};
