import { TextField } from "@mui/material";
import styled from "@emotion/styled";

export const SearchUserInput = styled(TextField)`
  margin-bottom: 10px;
  margin-top: -20px;
  width: 20%;
  margin-left: 1.5%;
  .MuiInputBase-input {
    font-size: 14px;
  }
`;
