import colors from "../components/common/colors";
import { deDE as coreDeDE } from "@mui/material/locale";
import { createTheme } from "@mui/material";
import { deDE as dataGridDeDE } from "@mui/x-data-grid";
import { deDE } from "@mui/x-date-pickers/locales";

const THEME = createTheme(deDE, coreDeDE, dataGridDeDE, {
  palette: {
    white: {
      main: colors.white,
    },
    primary: {
      main: colors.midnight,
      white: colors.white,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          minWidth: "140px",
          fontWeight: "400",
          maxHeight: "40px",
          fontFamily: ["Rubik"].join(","),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              fontFamily: "Rubik",
              fontWeight: "400",
              backgroundColor: colors.midnight,
              color: colors.white,
              boxShadow: "none",
              textTransform: "none",
              fontSize: 14,
              padding: "6px 12px",
              "&:hover": {
                backgroundColor: colors.midnight,
                boxShadow: "none",
              },
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
              fontFamily: "Rubik",
              fontWeight: "400",
              backgroundColor: colors.white,
              color: colors.midnight,
              boxShadow: "none",
              textTransform: "none",
              padding: "6px 12px",
              fontSize: 14,
              "&:hover": {
                backgroundColor: colors.grey,
              },
            }),
          ...(ownerState.variant === "neutral" &&
            ownerState.color === "primary" && {
              fontFamily: "Rubik",
              fontWeight: "400",
              backgroundColor: colors.lavender,
              color: colors.white,
              boxShadow: "none",
              textTransform: "none",
              padding: "6px 12px",
              fontSize: 14,

              "&:hover": {
                backgroundColor: colors.lavender,
              },
            }),
          ...(ownerState.variant === "warning" &&
            ownerState.color === "primary" && {
              fontFamily: "Rubik",
              fontWeight: "400",
              backgroundColor: colors.white,
              color: colors.red,
              boxShadow: "none",
              textTransform: "none",
              padding: "6px 12px",
              border: "1px solid",
              fontSize: 14,
              "&:hover": {
                backgroundColor: colors.grey,
              },
            }),
          ...(ownerState.variant === "white" &&
            ownerState.color === "primary" && {
              fontFamily: "Rubik",
              fontWeight: "400",
              backgroundColor: colors.white,
              color: colors.white,
              boxShadow: "none",
              textTransform: "none",
              padding: "6px 12px",
              border: "1px solid",
              fontSize: 14,
              "&:hover": {
                backgroundColor: colors.grey,
              },
            }),
          ...(ownerState.variant === "confirm" &&
            ownerState.color === "primary" && {
              fontFamily: "Rubik",
              fontWeight: "400",
              backgroundColor: colors.lavender,
              color: colors.white,
              boxShadow: "none",
              textTransform: "none",
              padding: "6px 12px",
              fontSize: 14,
              "&:hover": {
                backgroundColor: colors.lavender,
              },
            }),
          ...(ownerState.variant === "confirm" &&
            ownerState.disabled && {
              fontFamily: "Rubik",
              fontWeight: "400",
              backgroundColor: colors.white,
              color: colors.white,
              boxShadow: "none",
              textTransform: "none",
              padding: "6px 12px",
              fontSize: 14,
              "&:hover": {
                backgroundColor: colors.lavender,
              },
            }),
        }),
      },
    },
  },
  typography: {
    fontFamily: `Rubik`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

export default THEME;
