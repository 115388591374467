import Heading from "./styles";
import React from "react";
import colors from "../colors";

const Heading1 = (props) => {
  const fntColor = props.fontColor ? props.fontColor : colors.midnight;

  return <Heading fontColor={fntColor}>{props.children}</Heading>;
};
export default Heading1;
