const buildEmployee = (employee) => {
  const employeeUpdate = {
    firstName: employee.firstName.trim(),
    lastName: employee.lastName.trim(),
    mail: employee.mail?.trim() || undefined,
    pronouns: employee.pronouns,
    sexuality: employee.sexuality,
    religion: employee.religion,
    ethnicities: employee.ethnicities,
    birthdate: employee.birthdate,
    nationality: employee.nationality,
    disabilities: employee.disabilities,
    hadParentingTime: employee.hadParentingTime,
    role: employee.role,
    gender: employee.gender,
    social_background: employee.social_background,
    verified: employee.verified,
  };

  return employeeUpdate;
};

export default buildEmployee;
