import {
  LeftQuestionCard,
  QuestionGrid,
  QuestionGridContainer,
  RightQuestionCard,
} from "./styles";

import QuestionCard from "./QuestionCard";
export const QuestionList = ({ questions, areaName, areaIdentifier }) => {
  return (
    <QuestionGridContainer>
      <QuestionGrid>
        {questions.map((question, index) => {
          if (index % 2 === 0) {
            return (
              <LeftQuestionCard>
                <QuestionCard
                  index={index}
                  question={question}
                  areaName={areaName}
                  areaIdentifier={areaIdentifier}
                />
              </LeftQuestionCard>
            );
          } else {
            return (
              <RightQuestionCard>
                <QuestionCard
                  index={index}
                  question={question}
                  areaName={areaName}
                  areaIdentifier={areaIdentifier}
                />
              </RightQuestionCard>
            );
          }
        })}
      </QuestionGrid>
    </QuestionGridContainer>
  );
};
