import { Box } from "@mui/material";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const BoxStyled = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 1;
  width: 95%;
  margin-top: -10px;
`;

export const CheckBoxStyled = styled(Checkbox)`
  color: ${colors.lavender};
  &.Mui-checked {
    color: ${colors.lavender};
  },
  &.MuiCheckbox-indeterminate {
    color:
      ${colors.lavender};,
  },
`;

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  align-self: center;
  margin-top: 5px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: -5px;
  margin-bottom: 5px;
  height: 30px;
  text-transform: none;
  align-self: center;
  max-width: 200px;
  font-size: 13px;
  &:disabled {
    opacity: 0.5;
    color: ${colors.lightGrey};
  }
`;

export const ContainerCheckBox = styled.div`
  width: 60px;
  text-align: center;
  margin-left: 20px;
`;

export const SelectedNumber = styled("div")`
  display: flex;
  align-self: center;
  width: 120px;
  font-size: 14px;
  font-family: "Rubik";
  font-weight: 500;
`;
