import colors from "../../common/colors";
import styled from "@emotion/styled";

export const OuterContainer = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 700px;

  &.l {
    width: 60%;
  }
  &.m {
    width: 70%;
  }
  &.s {
    width: 90%;
  }
`;

export const Container = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.white};
  height: 555px;
  border-radius: 20px;
  border: 3px solid ${colors.midnight};
  align-self: center;
  padding-bottom: 20px;
  width: 100%;

  &.s {
    height: 100%;
  }
`;
