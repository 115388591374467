import React from "react";
import WindowSizeContext from "../context/WindowSizeContext";

function getSize(width) {
  if (width > 1199) {
    return "xl";
  } else if (width > 959) {
    return "l";
  } else if (width > 599) {
    return "m";
  } else return "s";
}

function usePortalSize() {
  const { width } = React.useContext(WindowSizeContext);
  return getSize(width);
}

export default usePortalSize;
