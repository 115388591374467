import { InputLabel, Select } from "@mui/material";
import { StyledFormControl, StyledMenuItem } from "./styles";

const Dropdown = ({
  menuItems,
  label,
  multiple,
  value,
  onChange,
  disabled,
  size,
}) => {
  return (
    <StyledFormControl size={size}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        multiple={multiple}
        onChange={onChange}
        disabled={disabled}
      >
        {menuItems.map((item) => {
          return <StyledMenuItem value={item.id}>{item.label}</StyledMenuItem>;
        })}
      </Select>
    </StyledFormControl>
  );
};

export default Dropdown;
