import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const Bubble = styled("div")`
  display: flex;
  align-self: ${({ isCurrentAuthor }) => (isCurrentAuthor ? "end" : "start")};
  background-color: ${({ isCurrentAuthor }) =>
    isCurrentAuthor ? colors.lavender + "55" : colors.grey};
  margin: 4px;
  margin-right: 14px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  margin: 4px;
  white-space: pre-wrap;
  margin-left: ${({ isCurrentAuthor }) => isCurrentAuthor && "20%"};
  margin-right: ${({ isCurrentAuthor }) => !isCurrentAuthor && "20%"};
  word-break: break-word;
`;

export const BubbleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AuthorInfo = styled("div")`
  display: flex;
  font-size: 11px;
  color: ${colors.darkGrey};
  font-style: italic;
  align-self: ${({ isCurrentAuthor }) => (isCurrentAuthor ? "end" : "start")};
  margin-right: 10px;
  margin-bottom: 6px;
  margin-left: 10px;
`;
