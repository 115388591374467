import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const ItemContainer = styled("div")`
  background-color: ${({ isCustom }) =>
    isCustom ? colors.lavenderLight : colors.natural};
  border: 1px solid
    ${({ isCustom }) => (isCustom ? colors.lavender : colors.darkGrey)};
  padding: 20px;
  font-size: 30px;
  height: 90px;
  width: 200px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
`;

export const AreaTitle = styled("p")`
  color: ${colors.midnight};
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
`;
