import { Divider } from "@mui/material";
import styled from "@emotion/styled";

export const AlertContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-self: center;
  max-height: 35px;
  margin-top: -2px;
  width: 75%;
`;

export const DividerAction = styled(Divider)`
  margin-left: 7px;
  height: 30px;
  margin-top: 3px;
`;
