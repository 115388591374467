import Dropdown from "../../../common/Dropdown";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import { SOCIAL_BACKGROUND_STEP_TITLE } from "./stepTitles";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { socialBackgroundOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";
// import usePortalSize from "../../../../hooks/usePortalSize";

const SocialBackgroundStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [socialBackground, setSocialBackground] = React.useState(
    employee.social_background
  );

  const { updateEmployee } = useLightsApiPrivate();
  // const size = usePortalSize();

  const saveAndNext = async () => {
    const emp = buildEmployee({
      ...employee,
      social_background: socialBackground,
    });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({
      ...employee,
      social_background: socialBackground,
    });
    handleNextStep();
  };

  props = {
    ...props,
    selected: socialBackground,
    step,
    saveAndNext,
    dimension: SOCIAL_BACKGROUND_STEP_TITLE,
  };

  return (
    <OptionStepContainer props={props}>
      <Dropdown
        value={socialBackground ? socialBackground : "not_applicable"}
        label="Soziale Herkunft"
        menuItems={socialBackgroundOptions}
        onChange={({ target }) => setSocialBackground(target.value)}
        size="small"
      />
    </OptionStepContainer>
  );
};

export default SocialBackgroundStep;
