import { TextField } from "@mui/material";
import styled from "@emotion/styled";

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  &.s {
    justify-content: space-around;
  }
`;

export const FieldContainer = styled("div")`
  width: 47%;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  &.s {
    width: 100%;
    margin: 10px 5px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;
