import Dropdown from "../../../../../common/Dropdown";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import { religionOptions } from "../../../diversityOptions";

const ReligionInput = ({ props }) => {
  const { employee, setEmployee, dynamicSize } = props;
  return (
    <FieldContainer>
      <Dropdown
        size={dynamicSize}
        value={employee.religion}
        label="Religion"
        menuItems={religionOptions}
        onChange={({ target }) =>
          setEmployee({ ...employee, religion: target.value })
        }
      />
    </FieldContainer>
  );
};

export default ReligionInput;
