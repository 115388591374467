import { Container } from "./styles";
import React from "react";
import usePortalSize from "../../../hooks/usePortalSize";

const FieldContainer = ({ children, minWidth }) => {
  const windowSize = usePortalSize();

  return (
    <Container className={windowSize} minWidth={minWidth}>
      {children}
    </Container>
  );
};

export default FieldContainer;
