import {
  BackButtonContainer,
  ContentContainer,
  Header,
  MobileOuterContainer,
  OuterContainer,
  ProgressContainer,
  ProgressPositioner,
  SideContainer,
  StyledCircularProgress,
  TextContainer,
} from "./styles";

import { ArrowBackRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import Heading1 from "../../../common/Heading1";
import SurveeOnboardingContainer from "../SurveeOnboardingContainer";
import Text from "../../../common/Text";
import WelcomeText from "../../../../content/onboarding/admin/WelcomeText.json";
import colors from "../../../common/colors";
import usePortalSize from "../../../../hooks/usePortalSize";

const SurveeWelcomeStep = ({ props }) => {
  const { handleNextStep, step, handlePrevStep } = props;

  const windowSize = usePortalSize();
  const progressValue = 34 + 33 * step;

  const buttonLabel = step === 2 ? "Los geht's!" : "Weiter";
  const isFirstStep = step === 0;
  const isMobile = windowSize === "s";

  return (
    <SurveeOnboardingContainer>
      {isMobile && <MobileOuterContainer />}
      <OuterContainer className={windowSize}>
        <SideContainer className={windowSize}>
          <BackButtonContainer className={windowSize}>
            {!isFirstStep && (
              <ArrowBackRounded
                className={windowSize}
                onClick={() => handlePrevStep()}
                color="primary"
                style={{ color: colors.lavender }}
                fontSize="large"
              />
            )}
          </BackButtonContainer>
        </SideContainer>
        <ContentContainer className={windowSize}>
          <Header className={windowSize}>
            <ProgressContainer className={windowSize} />
            <Heading1>{WelcomeText[step].title}</Heading1>
            <ProgressContainer className={windowSize}>
              <ProgressPositioner className={windowSize}>
                <StyledCircularProgress
                  className={windowSize}
                  variant="determinate"
                  value={progressValue}
                />
              </ProgressPositioner>
            </ProgressContainer>
          </Header>
          <TextContainer className={windowSize}>
            <Text fontSize="18px">{WelcomeText[step].description}</Text>
          </TextContainer>
          {!isMobile && (
            <Button
              variant="confirm"
              onClick={() => {
                handleNextStep();
              }}
              size="large"
            >
              {buttonLabel}
            </Button>
          )}
        </ContentContainer>
        <SideContainer />
      </OuterContainer>
      {isMobile && (
        <MobileOuterContainer reverseDirection={isFirstStep}>
          {!isFirstStep && (
            <ArrowBackRounded
              onClick={() => handlePrevStep()}
              color="primary"
              style={{ color: colors.lavender }}
              fontSize="large"
            />
          )}
          <Button
            variant="confirm"
            onClick={() => {
              handleNextStep();
            }}
          >
            {buttonLabel}
          </Button>
        </MobileOuterContainer>
      )}
    </SurveeOnboardingContainer>
  );
};

export default SurveeWelcomeStep;
