export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    flex: 0.25,
  },
  {
    field: "lead",
    headerName: "Führungskräfte",
    minWidth: 200,
    flex: 0.25,
  },
  {
    field: "amount",
    headerName: "Mitgliederanzahl",
    type: "number",
    minWidth: 100,
    align: "left",
    headerAlign: "left",
    flex: 0.17,
  },
  {
    field: "createdAt",
    headerName: "Erstellt am",
    type: "date",
    minWidth: 100,
    valueGetter: (params) => new Date(params.value),
    flex: 0.13,
  },
];
