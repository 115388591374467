import styled from "@emotion/styled";

export const InfoText = styled("div")`
  text-align: center;
  margin: 20px;
  width: 50%;
`;

export const ButtonContainer = styled("div")`
  width: 50%;
  text-align: center;
  margin-bottom: 20px;
`;

export const ChipContainer = styled("div")`
  text-align: center;
  margin: 20px;
`;

export const LoadingContainer = styled("div")`
  margin-top: 120px;
  margin-bottom: 130px;
`;
