import colors from "../../../components/common/colors";
import styled from "@emotion/styled";

export const ContentContainer = styled("div")`
  &.m {
    width: 100%;
  }
  &.s {
    width: 100%;
  }
  align-items: center;
  align-self: center;
  justify-self: center;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/*Container for text*/
export const TextContainer = styled("div")`
  &.m {
    width: 60%;
    align-items: center;
    text-align: center;
  }
  &.s {
    max-width: 80%;
    align-items: center;
    text-align: center;
  }
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  max-width: 70%;
`;

/*Attributes for text*/
export const InfoText = styled("p")`
  font-size: 14px;
  margin-bottom: 20px;
  color: ${colors.midnight};
  font-weight: 500;
`;
