import { createContext, useEffect, useState } from "react";

const RoleContext = createContext({});

export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(localStorage.getItem("role") || "survee");

  useEffect(() => {
    localStorage.setItem("role", role);
  }, [role]);

  return (
    <RoleContext.Provider value={{ role, setRole }}>
      {children}
    </RoleContext.Provider>
  );
};

export default RoleContext;
