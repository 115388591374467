import { DataGrid } from "@mui/x-data-grid";
import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const StyledDataGrid = styled(DataGrid)`
  margin: 20px 0;
  width: 100%;
`;
export const cellStyle = {
  "& .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
  "& .rating.good": {
    fontWeight: "bold",
    color: colors.green,
  },
  "& .rating.moderate": {
    fontWeight: "bold",
    color: colors.orange,
  },
  "& .rating.bad": {
    fontWeight: "bold",
    color: colors.red,
  },
};
