const formatEmployee = (emp) => {
  const mail = formatMail(emp.mail);

  const teams = formatTeams(emp.teams);

  return { ...emp, mail, teams };
};

const formatTeams = (teams) => {
  if (!teams) return [];
  return teams.split(",").map((team) => team.trim());
};

const formatMail = (mail) => {
  if (!mail) return undefined;
  return mail.toLowerCase().trim();
};

export default formatEmployee;
