import React, { useEffect } from "react";

import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import Heading1 from "../../common/Heading1";
import ProfileEditForm from "./ProfileEditForm";
import colors from "../../common/colors";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import { useParams } from "react-router-dom";

const ProfilePage = () => {
  const { id } = useParams();
  const { getEmployee } = useLightsApiPrivate();

  const [employee, setEmployee] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchEmployee = async () => {
      setLoading(false);
      const employee = await getEmployee(id);
      setEmployee(employee);
    };

    if (loading) {
      fetchEmployee().catch(console.error);
    }
  });

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Mitglieder</Heading1>
      </AreaSectionHeader>
      <AreaSection
        content={sectionContent.profileSection}
        backLinkTo="/settings/users"
      >
        {employee && <ProfileEditForm employee={employee} />}
      </AreaSection>
      {/* <AreaSection content={sectionContent.historySection}></AreaSection> */}
    </>
  );
};

export default ProfilePage;

const sectionContent = {
  profileSection: {
    id: "profile",
    title: `Profil`,
    infoText: "Ein Überblick aller Mitarbeiter*innen",
    hoverText: "Diese Daten wurden beim Onboarding importiert. ",
  },
  historySection: {
    id: "history",
    title: "Edit history",
    infoText: "see all changes made to this member",
    hoverText: "",
  },
};
