import { Route, Routes } from "react-router-dom";

import AreaNavigation from "../components/areas/AreaNavigation";
import AreaReportPage from "../components/areas/AreaReportPage";
import ContrastPage from "../components/contrast/ContrastPage";
import DialogPage from "../components/dialog/DialogPage";
import ForgotPasswordPage from "../components/login/ForgotPasswordPage";
import LoginPage from "../components/login/LoginPage";
import MailPulseCheckPage from "../components/MailPulseCheckPage";
import NewPasswordPage from "../components/login/NewPasswordPage";
import NotFoundPage from "./RequireRole/NotFoundPage";
import OnboardingPage from "../components/onboarding/AdminOnboarding";
import PageContainer from "../components/pageContainer/PageContainer";
import PasswordAlreadyCreatedPage from "../components/login/NewPasswordPage/PasswordAlreadyCreatedPage";
import PersistLogin from "../components/auth/PersistLogin";
import PersonalProfilePage from "../components/settings/profile/personal/PersonalProfilePage";
import ProfilePage from "../components/settings/profile/ProfilePage";
import PulseCheckPage from "../components/PulseCheckPage";
import React from "react";
import ReportPage from "../components/report/ReportPage";
import RequireAuth from "../components/auth/RequireAuth";
import RequireRole from "./RequireRole";
import SchedulePage from "../components/settings/schedule/SchedulePage";
import SurveeOnboarding from "../components/onboarding/SurveeOnboarding";
import SurveeRedirect from "./SurveeRedirect";
import TeamProfilePage from "../components/settings/teamProfile/TeamProfilePage";
import TeamsPage from "../components/settings/teams/TeamPage";
import TwoFaValidationPage from "../components/login/TwoFaValidationPage";
import UsersPage from "../components/settings/users/UsersPage";

function AllRoutes() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route
        path="forgot-password/confirmation"
        element={<ForgotPasswordPage />}
      />
      <Route path="new-password" element={<NewPasswordPage />} />
      <Route path="new-password/confirmation" element={<NewPasswordPage />} />
      <Route
        path="password-already-created"
        element={<PasswordAlreadyCreatedPage />}
      />
      <Route path="login" element={<LoginPage />} />
      <Route path="validate_2fa" element={<TwoFaValidationPage />} />

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth roles={["super admin", "admin"]} />}>
          <Route path="onboarding" element={<OnboardingPage />} />
        </Route>
      </Route>

      <Route element={<PersistLogin />}>
        <Route
          element={<RequireAuth roles={["super admin", "admin", "survee"]} />}
        >
          <Route
            path="/settings/profile/onboarding"
            element={<SurveeOnboarding />}
          />
          <Route path="/settings" element={<SurveeRedirect />} />
        </Route>
      </Route>

      <Route path="/" element={<PageContainer />}>
        {/* protected routes */}
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth roles={["super admin", "admin", "survee"]} />}
          >
            <Route element={<RequireRole />}>
              <Route path="dialog" element={<DialogPage />} />
            </Route>
            <Route element={<RequireRole />}>
              <Route
                path="settings/profile"
                element={<PersonalProfilePage />}
              />
            </Route>
            <Route element={<RequireRole />}>
              <Route path="pulse" element={<PulseCheckPage />} />
            </Route>
          </Route>
          <Route element={<RequireAuth roles={["super admin", "admin"]} />}>
            <Route element={<RequireRole />}>
              <Route path="report" element={<ReportPage />} />
              <Route path="contrast" element={<ContrastPage />} />
              <Route path="/" element={<ReportPage />} />
              <Route
                path="settings/users/:id/profile"
                element={<ProfilePage />}
              />
              <Route path="areas" element={<AreaNavigation />} />
              <Route path="areas/:id/report" element={<AreaReportPage />} />
              <Route
                path="areas/:id/report/actions"
                element={<AreaReportPage />}
              />
              <Route path="settings/teams" element={<TeamsPage />} />
              <Route
                path="settings/team/:id/profile"
                element={<TeamProfilePage />}
              />
              <Route path="settings/users" element={<UsersPage />} />
              <Route path="settings/schedule" element={<SchedulePage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="fl/survey/:id" element={<MailPulseCheckPage />} />
      <Route path="survey/:id" element={<MailPulseCheckPage />} />
      <Route path="sl/survey/:id" element={<MailPulseCheckPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AllRoutes;
