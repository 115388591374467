import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Checkcontainer = styled("img")`
  min-width: 150px;
  min-height: 150px;
  width: 40%;
  margin: -8% 0;
`;

export const ThankYouMessage = styled("h2")`
  font-weight: 500;
  font-size: 22px;
  color: ${colors.midnight};
  margin: 25px 0;
`;

export const ButtonContainer = styled("div")`
  margin: 20px;
`;
