import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";

export const Container = styled("div")`
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 600px;
`;

export const Footer = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

export const StyledDataGrid = styled(DataGrid)`
  max-height: 20%;
  width: 100%;
`;
