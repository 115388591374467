import React, { useEffect } from "react";
import { defaultSteps, dimensionSteps } from "./steps/dimensionSteps";

import LoadingAnimation from "../../common/LoadingAnimation";
import ProfileRedirect from "./ProfileRedirect";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import { useNavigate } from "react-router-dom";

const SurveeOnboarding = () => {
  const navigate = useNavigate();

  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [employee, setEmployee] = React.useState(null);
  const [availableSteps, setAvailableSteps] = React.useState([]);

  const { getEmployeePD, getAccountDimensions } = useLightsApiPrivate();

  useEffect(() => {
    const fetchData = async () => {
      const employee = await getEmployeePD();
      const dimensions = await getAccountDimensions();

      const steps = dimensionSteps.filter(
        (step) =>
          defaultSteps.includes(step.title) || dimensions.includes(step.title)
      );

      setEmployee(employee);
      setAvailableSteps(steps);

      if (employee.onboardingStep) {
        employee.onboardingStep > availableSteps.length - 1
          ? setStep(0)
          : setStep(employee.onboardingStep);
      }

      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectTo =
    employee && employee.role === "survee" ? "/settings/profile" : "/report";

  const handleNextStep = () => {
    if (step === availableSteps.length - 1) {
      navigate(redirectTo);
    } else {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const props = {
    handleNextStep,
    handlePrevStep,
    step,
    employee,
    setEmployee,
    redirectTo,
  };

  if (loading) return <LoadingAnimation />;

  if (step === "done") return <ProfileRedirect redirectTo={redirectTo} />;

  return availableSteps[step].component(props);
};

export default SurveeOnboarding;
