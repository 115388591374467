import styled from "@emotion/styled";

export const ScoreGrid = styled("div")`
  display: grid;
  width: 85%;
  row-gap: 20px;
  grid-template-columns: auto auto;
  column-gap: max;
`;

export const ScoreGridContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LeftScoreCard = styled("div")`
  justify-self: start;
`;

export const RightScoreCard = styled("div")`
  justify-self: end;
`;

export const AreasTitleCustom = styled("h2")`
  font-weight: 500;
  font-size: 20px;
  width: 85%;
  text-align: left;
  margin: 30px 0px 10px 0px;
`;

export const AreasTitleDiverlyze = styled("h2")`
  font-weight: 500;
  font-size: 20px;
  width: 85%;
  text-align: left;
  margin: 0px 0px 10px 0px;
`;
