import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const TextContainer = styled("div")`
  margin-bottom: 1rem;
  width: 70%;
  text-align: center;
  align-items: center;
`;

export const ContentContainer = styled("div")`
  background-color: ${colors.white};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding-bottom: 2rem;
  padding-top: 1rem;
  height: 250px;

  &.s {
    height: 250px;
    width: 90%;
    height: 180px;
    padding-bottom: 1rem;
  }
`;

export const ConfettiContainer = styled("div")`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
`;

export const MobileOuterContainer = styled("div")`
  display: flex;
  width: 90%;
  height: 100px;
  padding-top: 10px;
  justify-content: center;
  align-items: flex-end;
`;
