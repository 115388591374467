import ClockImage from "../../../../../assets/images/Time-Clock.png";
import Switch from "@mui/material/Switch";
import colors from "../../../../common/colors";
import scheduleImage from "../../../../../assets/images/schedule.png";
import styled from "@emotion/styled";

export const ScheduleContainer = styled("div")`
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.midnight};
  border-radius: 10px;
  height: 420px;
  width: 95%;
  margin-bottom: 14px;
`;

export const ImageContainer = styled("div")`
  background-image: url(${scheduleImage});
  background-size: cover;
  width: 233px;
  height: 150px;
`;

export const ClockContainer = styled("div")`
  background-image: url(${ClockImage});
  background-size: contain;
  background-repeat: no-repeat;
  width: 70px;
`;

export const ScheduleHeader = styled("div")`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  width: 83%;
  margin-top: 25px;
  margin-left: 30px;
  gap: 10px;
`;

export const ScheduleBody = styled("div")`
  margin-top: 10px;
  display: flex;
  width: 95%;
  margin-bottom: -21px;
  justify-content: space-between;
`;

export const PrimaryToggleHeader = styled("h1")`
  font-weight: 500;
  font-size: 26px;
  color: ${colors.white};
  margin: 10px 0 10px 0;
`;

export const SecondaryToggleHeader = styled("h1")`
  font-weight: 500;
  font-size: 26px;
  color: ${colors.white};
  margin: -5px 0 4px 0;
`;

export const ScheduleText = styled("h2")`
  font-weight: 400;
  font-size: 18px;
  color: ${colors.white};
  margin: 0;
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-switchBase {
    color: ${colors.grey};
    + .MuiSwitch-track {
      background-color: ${colors.grey};
    }
    &.Mui-checked {
      color: ${colors.lavender};
      + .MuiSwitch-track {
        background-color: ${colors.lavender};
      }
    }
  }
`;

export const ToggleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  width: 83%;
  margin-top: 10px;
  margin-left: 30px;
  gap: 10px;
`;

export const ToggleText = styled("h2")`
  font-weight: 400;
  font-size: 18px;
  color: ${colors.white};
  margin: 8px 0 0 0;
`;
export default StyledSwitch;
