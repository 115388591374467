import { Container, Logo, LogoContainer } from "./styles";

import React from "react";
import logo from "../../../assets/images/diverlyze_logo.png";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function MenuItem() {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const isSurvee = auth?.role === "survee";
  const logoCursor = isSurvee ? "default" : "pointer";

  return (
    <Container onClick={() => !isSurvee && navigate("/")}>
      <LogoContainer cursor={logoCursor}>
        <Logo src={logo} />
      </LogoContainer>
    </Container>
  );
}

export default React.memo(MenuItem);
