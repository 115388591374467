import styled from "@emotion/styled";

export const DownloadLink = styled("p")`
  cursor: pointer;
  font-weight: 500;
  display: inline;
  text-decoration: underline;
`;

export const DownloadText = styled("p")`
  display: inline;
`;
