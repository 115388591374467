import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styled from "@emotion/styled";

export const CloseIcon = styled(CloseRoundedIcon)`
  right: 7px;
  top: 7px;
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
`;

export const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    transform: "translate(-50%, -50%)",
  },
};
