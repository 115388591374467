import {
  BIRTHDAY_STEP_TITLE,
  DISABILITY_STEP_TITLE,
  ETHNICITY_STEP_TITLE,
  GENDER_STEP_TITLE,
  PARENTAL_LEAVE_STEP_TITLE,
  RELIGION_STEP_TITLE,
  SEXUALITY_STEP_TITLE,
  SOCIAL_BACKGROUND_STEP_TITLE,
} from "../../../../../onboarding/SurveeOnboarding/steps/stepTitles";

import BirthdateInput from "./BirthdateInput";
import DisabilityInput from "./DisabilityInput";
import EthnicityInput from "./EthnicityInput";
import GenderInput from "./GenderInput";
import ParentalLeaveInput from "./ParentalLeaveInput";
import ReligionInput from "./ReligionInput";
import SexualityInput from "./SexualityInput";
import SocialBackgroundInput from "./SocialBackgroundInput";

export const inputFields = [
  {
    title: GENDER_STEP_TITLE,
    component: (props) => <GenderInput props={props} />,
  },
  {
    title: RELIGION_STEP_TITLE,
    component: (props) => <ReligionInput props={props} />,
  },
  {
    title: SEXUALITY_STEP_TITLE,
    component: (props) => <SexualityInput props={props} />,
  },
  {
    title: ETHNICITY_STEP_TITLE,
    component: (props) => <EthnicityInput props={props} />,
  },
  {
    title: DISABILITY_STEP_TITLE,
    component: (props) => <DisabilityInput props={props} />,
  },
  {
    title: SOCIAL_BACKGROUND_STEP_TITLE,
    component: (props) => <SocialBackgroundInput props={props} />,
  },
  {
    title: BIRTHDAY_STEP_TITLE,
    component: (props) => <BirthdateInput props={props} />,
  },
  {
    title: PARENTAL_LEAVE_STEP_TITLE,
    component: (props) => <ParentalLeaveInput props={props} />,
  },
];
