import {
  BackText,
  Container,
  QuestionText,
  TextFieldContainer,
} from "./styles";
import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

import { PULSEVIEWS } from "../pulseViews";
import Text from "../../../common/Text";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";
import usePortalSize from "../../../../hooks/usePortalSize";

const PulseSkip = ({ askedQuestions, setView, setAskedQuestions }) => {
  const [comment, setComment] = useState(
    "Ich habe hierzu keine Informationen."
  );
  const alert = useAlert();
  const windowSize = usePortalSize();
  const { createAnswer } = useLightsApiPrivate();
  const currQuestion = askedQuestions[0];

  const submitComment = async () => {
    try {
      const answer = {
        askedQuestionId: currQuestion.id,
        commentary: {
          text: comment,
          read: false,
        },
      };
      alert.removeAll();
      await createAnswer(answer);
      setAskedQuestions(askedQuestions.slice(1));
      setView(PULSEVIEWS.thanks);
      setAskedQuestions(askedQuestions.slice(1));
    } catch (e) {
      alert.error("Es ist ein Problem aufgetreten.");
    }
  };

  return (
    <Container className={windowSize}>
      <Text>
        Teile uns bitte mit, warum Du die Frage nicht beantworten kannst.
      </Text>
      <QuestionText>{currQuestion.question}</QuestionText>
      <BackText onClick={() => setView(PULSEVIEWS.question)}>
        Ich möchte sie doch beantworten.
      </BackText>
      <TextFieldContainer className={windowSize}>
        <TextField
          value={comment || ""}
          id="outlined-multiline-flexible"
          label="Dein Kommentar zur Frage"
          multiline
          maxRows={2}
          onChange={(e) => setComment(e.target.value)}
        />
      </TextFieldContainer>
      <Button
        onClick={submitComment}
        disabled={comment === ""}
        variant="confirm"
      >
        Absenden
      </Button>
    </Container>
  );
};

export default PulseSkip;
