import { IconButton } from "@mui/material";
import colors from "../../common/colors";
import styled from "@emotion/styled";

export const BackButtonContainer = styled("div")`
  position: absolute;
  margin-left: -50px;
`;

export const BackIconContainer = styled(IconButton)`
  border: 1px solid ${colors.darkGrey};
  background-color: white;
  width: 40px;
  height: 30px;
  color: primary;
  border-radius: 5px;
  &:hover {
    background-color: ${colors.grey};
  }
`;
