import React, { useEffect, useState } from "react";

import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import ChatSection from "../ChatSection";
import { ContentContainer } from "./styles";
import Heading1 from "../../common/Heading1";
import LoadingAnimation from "../../common/LoadingAnimation";
import MessageSection from "../MessageSection";
import colors from "../../common/colors";
import { sectionContent } from "./sectionContent";
import { useAlert } from "react-alert";
import useAuth from "../../../hooks/useAuth";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import useRole from "../../../hooks/useRole";

const DialogPage = () => {
  const {
    getAdminDialogs,
    getSurveeDialogs,
    getChatMessages,
    patchDialogRead,
    getAccount,
  } = useLightsApiPrivate();
  const { auth } = useAuth();
  const { role } = useRole();

  const isSurvee = role === "survee";
  const content = isSurvee ? sectionContent.survee : sectionContent.admin;

  const [loading, setLoading] = useState(true);
  const [dialogs, setDialogs] = useState([]);
  const [isDialogActive, setIsDialogActive] = useState(false);

  const alert = useAlert();

  const [chatMessages, setChatMessages] = useState([]);
  const [selectedDialog, setSelectedDialog] = useState(null);
  const [isChatLoading, setIsChatLoading] = useState(false);

  useEffect(() => {
    getAccount().then((data) => {
      if (data.isDialogActive) setIsDialogActive(true);
    });
  }, [getAccount]);

  useEffect(() => {
    const role = localStorage.getItem("role") || "survee";

    const getDialogs = async (role) => {
      if (role === "survee") {
        return await getSurveeDialogs();
      }
      return await getAdminDialogs();
    };

    getDialogs(role).then((dialogs) => {
      setDialogs(dialogs);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDialog = async (dialog) => {
    const { id } = dialog;
    setIsChatLoading(true);

    try {
      const chatMessages = await getChatMessages(id);

      setChatMessages(chatMessages);
      setSelectedDialog(dialog);

      const wasNewMessage = dialog.hasNewMessage;
      dialogs.find((dialog) => dialog.id === id).hasNewMessage = false;
      setDialogs([...dialogs]);

      if (wasNewMessage) {
        await patchDialogRead(dialog.id);
      }
    } catch (e) {
      alert.error("Es ist ein Fehler aufgetreten.");
    }

    setIsChatLoading(false);
  };

  if (loading) return <LoadingAnimation />;
  const newDialogs = dialogs.filter((dialog) => dialog.hasNewMessage);

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Dialog</Heading1>
      </AreaSectionHeader>
      <AreaSection content={content}>
        <ContentContainer>
          <MessageSection
            dialogs={dialogs}
            openDialog={openDialog}
            isChatLoading={isChatLoading}
            selectedDialog={selectedDialog}
          />
          <ChatSection
            newDialogsAmount={newDialogs.length}
            selectedDialog={selectedDialog}
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            isChatLoading={isChatLoading}
            loggedInUser={auth}
            role={role}
            isDialogActive={isDialogActive}
          />
        </ContentContainer>
      </AreaSection>
    </>
  );
};

export default React.memo(DialogPage);
