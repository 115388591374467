import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 95%;
`;

export const UpperContainer = styled("div")`
  display: flex;
  &.l {
    width: 80%;
  }
  &.m {
    width: 85%;
  }
  &.s {
    width: 95%;
    margin-top: 35px;
  }
  height: 280px;
  margin-top: 20px;
  width: 65%;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const QuestionText = styled("div")`
  font-size: 18px;
  font-weight: 500;
`;

export const RatingContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SkipText = styled("a")`
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  color: ${colors.black};
  cursor: pointer;
  text-decoration: underline;
  align-self: flex-start;
`;

export const TextFieldContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 60%;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  z-index: 0;

  &.s {
    width: 90%;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &.m {
    width: 80%;
  }

  &.l {
    width: 70%;
  }
`;
export const CompanyQuestionText = styled("div")`
  font-size: 14px;
  font-weight: 500;
`;
