import { Button, ButtonText } from "./styles";

import React from "react";
import usePortalSize from "../../../../hooks/usePortalSize";

const NumberButton = ({ rating, active = false, onClick, setColor }) => {
  const windowSize = usePortalSize();

  return (
    <Button
      color={setColor}
      onClick={() => onClick(rating)}
      className={windowSize}
      active={active}
    >
      <ButtonText active={active}>{rating}</ButtonText>
    </Button>
  );
};

export default NumberButton;
