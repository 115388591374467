import { AuthorInfo, Bubble, BubbleContainer } from "./styles";

import React from "react";
import { getGermanFormat } from "../../../../../utils/date";

const MessageBubble = ({ chatMessage, nextMessage }) => {
  const { authorName, message, isCurrentAuthor, createdAt } = chatMessage;
  const { authorName: nextAuthor, isCurrentAuthor: isNextCurrentAuthor } =
    nextMessage;
  const isNextAuthorSame =
    authorName === nextAuthor || (isNextCurrentAuthor && isCurrentAuthor);

  const date = getGermanFormat(new Date(createdAt));

  const showAuthorName = !isNextAuthorSame;

  const authorDateDisplay = !isCurrentAuthor ? `${authorName} - ${date}` : date;

  return (
    <BubbleContainer>
      <Bubble isCurrentAuthor={isCurrentAuthor}>{message}</Bubble>
      {showAuthorName && (
        <AuthorInfo isCurrentAuthor={isCurrentAuthor}>
          {authorDateDisplay}
        </AuthorInfo>
      )}
    </BubbleContainer>
  );
};

export default MessageBubble;
