import landingpage_background from "../../assets/images/thankyoupage/landingpage_background.png";
import styled from "@emotion/styled";

/*OuterContainer (for Background)*/
export const ContentContainer = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url(${landingpage_background});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LogoContainer = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 120px;
`;

export const Logo = styled("img")`
  align-self: center;
  height: 60px;
`;
