import { routes } from "./routes";

const checkAllowedRoutes = (role, pathname) => {
  if (
    (role === "admin" && pathname === "/") ||
    (role === "super admin" && pathname === "/")
  )
    return true;
  if (role === "survee" && pathname === "/") return false;

  const allowedRoutes = routes[role] || routes.survee;
  const isAllowedRoute = allowedRoutes.some((route) => {
    return pathname.includes(route);
  });

  return isAllowedRoute;
};

export default checkAllowedRoutes;
