import React from "react";
import { StyledDataGrid } from "./styles";
import { cellStyle } from "./styles";
import { columns } from "./columns";
import { localizedTextsMap } from "../../../../localization/dataGrid";
import { useNavigate } from "react-router-dom";

const EmployeesTable = ({
  employees,
  selected,
  handleSelectAllClick,
  handleSelectClick,
  paginationModel,
  setPaginationModel,
}) => {
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    if (params.field !== "select") {
      navigate(`/settings/users/${params.row.id}/profile`);
    }
  };

  return (
    <>
      <StyledDataGrid
        disableColumnSelector
        stickyHeader
        onCellClick={handleRowClick}
        sx={cellStyle}
        localeText={localizedTextsMap}
        disableRowSelectionOnClick
        columns={columns(
          selected,
          handleSelectClick,
          handleSelectAllClick,
          employees
        )}
        rows={employees}
        getRowId={(row) => row.id}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
      />
    </>
  );
};

export default EmployeesTable;
