import formatEmployee from "./formatEmployee";

export const mapData = (csvFormat) => {
  const mappedEmployees = [];
  csvFormat.shift();

  csvFormat.forEach((user, row) => {
    const emp = {};

    user.forEach((entry, index) => {
      const key = objectStruc[index];
      emp[key] = entry;
    });

    const employee = formatEmployee(emp);
    mappedEmployees.push(employee);
  });
  return mappedEmployees;
};

const objectStruc = ["firstName", "lastName", "mail", "teams"];
