import { Button } from "@mui/material";
import { Flex } from "@react-spectrum/layout";
import React from "react";
import { Title } from "./styles";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import useRole from "../../../../hooks/useRole";

const ModalContent = ({ toRole, heading }) => {
  const { setRole } = useRole();
  const navigate = useNavigate();
  const alert = useAlert();

  const handleRoleChange = () => {
    const roleString = toRole === "survee" ? "Survee" : "Admin";

    setRole(toRole);
    alert.success("Erfolgreich zur " + roleString + " Ansicht gewechselt!");
  };

  const handleAbort = () => {
    alert.error("Wechsel abgebrochen");
    navigate(-1);
  };

  return (
    <Flex alignItems="center" direction="column">
      <Title>{heading}</Title>
      <Flex direction="row" justifyContent="space-between" width="100%">
        <Button variant="outlined" onClick={handleAbort}>
          Abbrechen
        </Button>
        <Button variant="confirm" onClick={handleRoleChange}>
          Wechseln
        </Button>
      </Flex>
    </Flex>
  );
};

export default ModalContent;
