import {
  ACTIVE_STATUS,
  CREATED_STATUS,
  DEACTIVATED_STATUS,
  INVITED_STATUS,
} from "./statusOptions";

import Chip from "@mui/material/Chip";
import React from "react";

const StatusChip = ({ status }) => {
  switch (status) {
    case "created":
      return <Chip label={CREATED_STATUS} color="error" />;
    case "invited":
      return <Chip label={INVITED_STATUS} color="error" variant="outlined" />;
    case "active":
      return <Chip label={ACTIVE_STATUS} color="success" />;
    case "deactivated":
      return (
        <Chip label={DEACTIVATED_STATUS} color="default" variant="filled" />
      );
    default:
      return <Chip />;
  }
};

export default StatusChip;
