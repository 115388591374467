import {
  CheckContainer,
  StyledParagraph,
  TextContainer,
  ThankYouMessage,
} from "./styles";
import React, { useEffect, useState } from "react";

import FactAlert from "./FactAlert";
import LoadingAnimation from "../common/LoadingAnimation";
import PulseCheck from "./PulseCheck";
import PulseCheckContainer from "./PulseCheckContainer";
import backUpFact from "./backUpFact";
import check_gif from "../../assets/animations/check.gif";
import { getEngagementFact } from "../../api/lightsApi";
import getNextPulseCheckDay from "./getNextPulseCheckDay";
import useAuth from "../../hooks/useAuth";
import useLightsApiPrivate from "../../hooks/useLightsApiPrivate";

const PulseCheckPage = () => {
  const [loading, setLoading] = useState(true);
  const [askedQuestions, setAskedQuestions] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [fact, setFact] = useState([]);

  const { auth } = useAuth();

  const { getUnansweredQuestions, getAccount } = useLightsApiPrivate();
  const notCachedGif = check_gif + "?" + performance.now();

  useEffect(() => {
    getUnansweredQuestions(auth?.id).then((data) => {
      setAskedQuestions(data);
      setLoading(false);
    });
    getAccount().then((data) => {
      if (data.schedule) setSchedule(data.schedule);
    });
    getEngagementFact()
      .then((data) => {
        setFact(data.fact);
      })
      .catch((err) => {
        console.error("Error fetching engagement fact:", err);
        setFact(backUpFact);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingAnimation />;

  const nextPulseCheckDay = getNextPulseCheckDay(schedule);

  if (askedQuestions.length === 0) {
    return (
      <PulseCheckContainer>
        <TextContainer>
          <ThankYouMessage>Danke für Deine Teilnahme!</ThankYouMessage>
          <CheckContainer src={notCachedGif}></CheckContainer>
          <StyledParagraph>
            Schau {nextPulseCheckDay} wieder für den nächsten Pulse Check
            vorbei.
          </StyledParagraph>
        </TextContainer>
        <FactAlert fact={fact} />
      </PulseCheckContainer>
    );
  }

  return (
    <PulseCheckContainer>
      <PulseCheck
        askedQuestions={askedQuestions}
        setAskedQuestions={setAskedQuestions}
        fact={fact}
      />
    </PulseCheckContainer>
  );
};

export default PulseCheckPage;
