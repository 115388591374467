import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const QuestionGrid = styled("div")`
  display: grid;
  width: 90%;
  row-gap: 20px;
  grid-template-columns: auto auto;
  column-gap: max;
`;

export const QuestionGridContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LeftQuestionCard = styled("div")`
  justify-self: start;
`;

export const RightQuestionCard = styled("div")`
  justify-self: end;
`;

export const MissingActionsInfo = styled("div")`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
  color: ${colors.darkGrey};
`;

export const MessageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;
