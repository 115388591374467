import {
  Activity,
  ActivityContainer,
  ActivityHeader,
  CompareNumber,
  Cont,
  FemaleInfo,
  LoadingContainer,
  MainScoreSectionContainer,
  MetaInfoArea,
  OtherInfo,
  RatingFemale,
  ScoreArea,
  ScoreContainer,
  ScoreGraphArea,
  ScoreHeader,
  ScoreInfoArea,
  TrendMale,
} from "./styles";

import { ReactComponent as ArrowDownIcon } from "../../../icons/trend_arrow_down_right.svg";
import { ReactComponent as ArrowUpIcon } from "../../../icons/trend_arrow_up_right.svg";
import DividerHorizontal from "../../common/DividerHorizontal";
import LoadingSection from "../../scores/ScoreCard/LoadingSection";
import OverviewChart from "./SurveeOverview/OverviewChart";
import React from "react";
import Timeline from "../Timeline";
import { buildSurveeOverview } from "./buildSurveeOverview";
import colors from "../../common/colors";
import { computeRatingColor } from "../../../styles/computeScoreColors";

const MainScoreSL = ({ score, scoreTimeline, employees }) => {
  const { activity, ratingCombined, trendRatingCombined, answersLeft } = score;

  const loading = !ratingCombined;

  const surveeOverview = buildSurveeOverview(employees);

  return (
    <MainScoreSectionContainer>
      <ScoreInfoArea>
        {loading ? (
          <LoadingContainer>
            <LoadingSection answersLeft={answersLeft} id={"mainScore"} />
          </LoadingContainer>
        ) : (
          <>
            <ScoreArea>
              <FemaleInfo>
                <ScoreHeader>Score</ScoreHeader>
                <ScoreContainer>
                  <RatingFemale score={ratingCombined}>
                    {ratingCombined}
                  </RatingFemale>
                  <CompareNumber>/10</CompareNumber>
                </ScoreContainer>
              </FemaleInfo>
              <OtherInfo>
                <ActivityContainer>
                  <ActivityHeader>Antwortrate</ActivityHeader>
                  <Activity score={activity}>{activity} %</Activity>
                </ActivityContainer>
                <ActivityContainer>
                  <ActivityHeader>Trend</ActivityHeader>
                  <Cont>
                    <TrendMale>{trendRatingCombined}</TrendMale>
                    {trendRatingCombined !== "0.0" &&
                      (trendRatingCombined > 0 ? (
                        <ArrowUpIcon width={15} stroke={colors.midnight} />
                      ) : (
                        <ArrowDownIcon width={15} stroke={colors.midnight} />
                      ))}
                  </Cont>
                </ActivityContainer>
              </OtherInfo>
            </ScoreArea>
            <DividerHorizontal />
            <MetaInfoArea>
              <OverviewChart
                key={surveeOverview.id}
                name={surveeOverview.name}
                data={surveeOverview.data}
                alignLegend="right"
                verticalAlign="middle"
                width={"450px"}
                fontSize={"18px"}
              ></OverviewChart>
            </MetaInfoArea>
          </>
        )}
      </ScoreInfoArea>
      <ScoreGraphArea>
        <Timeline
          scoreOverTime={loading ? null : scoreTimeline}
          lineColor={computeRatingColor(ratingCombined)}
        />
      </ScoreGraphArea>
    </MainScoreSectionContainer>
  );
};

export default MainScoreSL;
