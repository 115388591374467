import { Button, FormControl, TextField } from "@mui/material";

import styled from "@emotion/styled";

export const Container = styled("form")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  width: 95%;
  margin-left: 34px;
  margin-right: 34px;
`;

export const TitleContainer = styled("div")`
  align-self: flex-start;
`;

export const AdminInfoText = styled("i")`
  margin-top: 10px;
  font-size: 14px;
`;

export const ButtonContainer = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: space-between;
`;

export const NameContainer = styled("div")`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const SubHeader = styled("h4")`
  font-weight: 500;
`;

export const StyledFormControl = styled(FormControl)`
  width: 300px;
`;

export const StatusContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 80px;
`;

export const InviteButtonContainer = styled("div")`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  align-self: flex-start;
`;

export const ModalTitle = styled("p")`
  margin-bottom: 0px;
`;

export const DeactivateText = styled("p")`
  font-size: 15px;
  margin-bottom: 0;
  margin-top: -5px;
`;

export const ActivateButton = styled(Button)`
  text-transform: none;
`;
