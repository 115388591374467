import "react-toggle/style.css";
import "moment/locale/de";

import * as Sentry from "@sentry/react";

import {
  AdminInfoText,
  ButtonContainer,
  CloseIcon,
  Container,
  IconContainer,
  InputContainer,
  NameContainer,
  RoleFormController,
  StyledTextField,
  Subheader,
  TitleContainer,
} from "./styles";
import { InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

import Button from "@mui/material/Button";
import DividerHorizontal from "../../../../../common/DividerHorizontal";
import Modal from "react-modal";
import { getModalStyle } from "../../../../../common/modalStyle";
import unifyMailFormat from "../../../../../../utils/unifyMailFormat";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../../hooks/useLightsApiPrivate";
import { validateProfile } from "../../../../../login/formValidation";

const CreationModal = ({ updateEmployeeList, isModalOpen, closeModal }) => {
  // TODO move state into Context for splitting up into multiple components

  const alert = useAlert();

  const [role, setRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setMail] = useState("");

  const [buttonInactive, setButtonInactive] = useState(false);

  const { createEmployee } = useLightsApiPrivate();

  const handleClose = () => {
    closeModal();
    setRole("");
    setFirstName("");
    setLastName("");
    setMail("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonInactive(true);

    const newEmployee = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      role,
      mail: unifyMailFormat(mail),
    };

    const { error } = validateProfile(newEmployee);
    if (error) {
      alert.removeAll();
      alert.show(error.details[0].message);
      setButtonInactive(false);
      return;
    }

    try {
      alert.removeAll();
      await createEmployee(newEmployee);
      alert.success("Teammitglied erfolgreich hinzugefügt.");
      await updateEmployeeList();
      handleClose();
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        alert.error("No Server Response.");
      } else if (err.response?.status === 400) {
        alert.error("Invalid Data.");
      } else if (err.response?.status === 404) {
        alert.error("Couldn't find user.");
      } else if (err.response?.status === 406) {
        alert.error(err.response.data.message);
      } else if (err.response?.status === 409) {
        alert.error(
          "ES EXISTIERT SCHON EIN MITGLIED MIT DIESER E-MAIL-ADRESSE."
        );
      } else {
        alert.error("We encountered an error.");
      }
    }
    setButtonInactive(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={getModalStyle()}
      contentLabel="Profile Edit Modal"
    >
      <Container onSubmit={handleSubmit}>
        <IconContainer>
          <CloseIcon onClick={handleClose} />
        </IconContainer>
        <TitleContainer>
          <Subheader>Erstelle ein neues Mitglied</Subheader>
        </TitleContainer>
        <InputContainer>
          <NameContainer>
            <StyledTextField
              defaultValue={firstName}
              autoComplete="false"
              onChange={(event) => setFirstName(event.target.value)}
              id="outlined-basic"
              variant="outlined"
              label="Vorname"
            />
          </NameContainer>
          <NameContainer>
            <StyledTextField
              defaultValue={lastName}
              autoComplete="false"
              onChange={(event) => setLastName(event.target.value)}
              id="outlined-basic"
              variant="outlined"
              label="Nachname"
            />
          </NameContainer>
        </InputContainer>
        <InputContainer>
          <NameContainer>
            <StyledTextField
              defaultValue={mail}
              autoComplete="false"
              onChange={(event) => setMail(event.target.value)}
              id="outlined-basic"
              variant="outlined"
              label="E-Mail"
            />
          </NameContainer>
          <NameContainer></NameContainer>
        </InputContainer>
        <DividerHorizontal />
        <TitleContainer>
          <Subheader>Zugriff</Subheader>
        </TitleContainer>
        <RoleFormController>
          <InputLabel id="demo-simple-select-label">Rolle</InputLabel>
          <Select
            defaultValue={role}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Rolle"
            onChange={(e) => setRole(e.target.value)}
          >
            <MenuItem value={"admin"}>Admin</MenuItem>
            <MenuItem value={"survee"}>Survee</MenuItem>
          </Select>
        </RoleFormController>
        <AdminInfoText>
          Ein Admin erhält vollen Zugriff auf das Tool.
        </AdminInfoText>
        <ButtonContainer>
          <Button variant="neutral" isLoading={buttonInactive} type="submit">
            Hinzufügen
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default CreationModal;
