import { LinkContainer, SubHeader } from "./styles";

import { Button } from "@mui/material";
import Heading1 from "../../common/Heading1";
import React from "react";
import { useNavigate } from "react-router-dom";

const Confirmation = () => {
  const navigate = useNavigate();
  return (
    <>
      <Heading1>Erfolgreich.</Heading1>
      <SubHeader>
        Du kannst Dich jetzt mit Deinem neuen Passwort einloggen.
      </SubHeader>
      <LinkContainer>
        <Button variant="contained" onClick={() => navigate(`/Login`)}>
          Zum Login
        </Button>
      </LinkContainer>
    </>
  );
};

export default Confirmation;
