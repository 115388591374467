import login_background from "../../../assets/images/login_background.jpg";
import styled from "@emotion/styled";

export const Container = styled("div")`
  height: 100vh;
  width: 100%;
  display: flex;
`;

export const ImageContainer = styled("div")`
  &.m {
    width: 0%;
  }
  &.s {
    width: 0%;
  }
  background-image: url(${login_background});
  background-size: cover;
  background-position: 96%;
  height: 100%;
  width: 50%;
`;
