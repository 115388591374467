import {
  ClockContainer,
  ImageContainer,
  PrimaryToggleHeader,
  ScheduleBody,
  ScheduleContainer,
  ScheduleHeader,
  ScheduleText,
  SecondaryToggleHeader,
  StyledSwitch,
  ToggleContainer,
  ToggleText,
} from "./styles";
import React, { useEffect, useState } from "react";

import Dropdown from "../Dropdown";
import colors from "../../../../common/colors";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";

const Scheduler = () => {
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const [isToggleMailMinuteRandom, setIsToggleMailMinuteRandom] =
    useState(false);

  const { patchIsMailMinuteRandom, getAccount } = useLightsApiPrivate();
  useEffect(() => {
    if (loading) {
      getAccount().then((data) => {
        if (data.schedule) setSchedule(data.schedule);
        if (data.isMailMinuteRandom)
          setIsToggleMailMinuteRandom(data.isMailMinuteRandom);
        setLoading(false);
      });
    }
  }, [loading, getAccount]);

  const handleToggleChange = async () => {
    const updatedValue = !isToggleMailMinuteRandom;
    setIsToggleMailMinuteRandom(updatedValue);

    try {
      await patchIsMailMinuteRandom({
        isMailMinuteRandom: updatedValue,
      });
    } catch (error) {
      console.error("Error al actualizar isMailMinuteRandom:", error);
    }
  };

  const findHourByWeekday = (weekday) => {
    const dayTime = schedule.find((dayTime) => dayTime.weekday === weekday);
    if (dayTime) return dayTime.hour;

    return null;
  };

  return (
    <ScheduleContainer>
      <ScheduleHeader>
        <ImageContainer />
        <div>
          <PrimaryToggleHeader fontColor={colors.white}>
            Plane die Zeiten.
          </PrimaryToggleHeader>
          <ScheduleText fontColor={colors.white}>
            Manage das Verschicken der Fragen ganz individuell nach Deinen
            Bedürfnissen.
          </ScheduleText>
        </div>
      </ScheduleHeader>

      <ScheduleBody>
        <Dropdown day={"Montag"} dayIndex={1} curHour={findHourByWeekday(1)} />
        <Dropdown
          day={"Dienstag"}
          dayIndex={2}
          curHour={findHourByWeekday(2)}
        />
        <Dropdown
          day={"Mittwoch"}
          dayIndex={3}
          curHour={findHourByWeekday(3)}
        />
        <Dropdown
          day={"Donnerstag"}
          dayIndex={4}
          curHour={findHourByWeekday(4)}
        />
        <Dropdown day={"Freitag"} dayIndex={5} curHour={findHourByWeekday(5)} />
        <Dropdown day={"Samstag"} dayIndex={6} curHour={findHourByWeekday(6)} />
        <Dropdown day={"Sonntag"} dayIndex={0} curHour={findHourByWeekday(0)} />
      </ScheduleBody>
      <ScheduleHeader>
        <ClockContainer />
        <div>
          <SecondaryToggleHeader fontColor={colors.white}>
            Sendezeitpunkt aufteilen?
          </SecondaryToggleHeader>
          <ScheduleText fontColor={colors.white}>
            Du kannst den Versand über eine Stunde verteilen, um die
            Wahrscheinlichkeit zu verringern, <br /> dass die Pulse-Check-Mails
            als Spam markiert werden.
          </ScheduleText>
        </div>
      </ScheduleHeader>
      <ToggleContainer>
        <StyledSwitch
          checked={isToggleMailMinuteRandom}
          onChange={handleToggleChange}
        />
        <div>
          <ScheduleText fontColor={colors.white}>
            {isToggleMailMinuteRandom ? (
              <ToggleText>
                Alle E-Mails separat 1 bis 60 Minuten nach der gewählten Uhrzeit
                versenden.
              </ToggleText>
            ) : (
              <ToggleText>
                Nein, alle E-Mails gleichzeitig zur gewählten Uhrzeit versenden.
              </ToggleText>
            )}
          </ScheduleText>
        </div>
      </ToggleContainer>
    </ScheduleContainer>
  );
};

export default Scheduler;
