import { Autocomplete, Chip, TextField } from "@mui/material";
import {
  ButtonContainer,
  Container,
  DiversityInfoText,
  LeadAutoComplete,
  MemberContainer,
  StyledTextField,
  TopContainer,
} from "./styles";

import Button from "@mui/material/Button";
import Heading2 from "../../../common/Heading2";
import Modal from "react-modal";
import React from "react";
import { captureException } from "@sentry/react";
import { getModalStyle } from "../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";
import { useNavigate } from "react-router-dom";

const TeamEditForm = ({ team, employees }) => {
  const [teamName, setTeamName] = React.useState(team.name || "");
  const [teamLeads, setTeamLeads] = React.useState(team.teamLeads || []);
  const [teamMembers, setTeamMembers] = React.useState(team.members || []);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { updateTeam, deleteTeam } = useLightsApiPrivate();
  const navigate = useNavigate();
  const alert = useAlert();

  const handleSubmit = async () => {
    const teamMemberIds = teamMembers.map((teamMember) => teamMember._id);
    const teamLeadIds = teamLeads.map((employee) => employee._id);

    const teamData = {
      name: teamName,
      teamLeadIds,
      teamMemberIds,
    };

    try {
      alert.removeAll();
      await updateTeam(team.id, teamData);
      alert.success("update erfolgreich");
      navigate("/settings/teams");
    } catch (err) {
      captureException(err);
      if (!err?.response) {
        alert.error("Der Server ist nicht erreichbar");
      } else if (err.response?.status === 400) {
        alert.error("Bitte fülle alle Felder aus");
      } else if (err.response?.status === 404) {
        alert.error("team nicht gefunden");
      } else if (err.response?.status === 409) {
        alert.error("ES EXISTIERT SCHON EIN TEAM MIT DIESEM NAMEN");
      } else {
        alert.error("Es ist ein Fehler aufgetreten");
      }
    }
  };

  const handleDelete = async () => {
    const teamId = team.id;

    try {
      await deleteTeam(teamId);
      alert.success("Team erfolgreich entfernt");
      setIsModalOpen(false);
      navigate("/settings/teams");
    } catch (err) {
      captureException(err);
      alert.error("Es ist ein Fehler aufgetreten");
    }
  };

  const handleTeamLeadChange = (newTeamLeads) => {
    setTeamLeads(newTeamLeads);

    setTeamMembers((prevTeamMembers) => {
      const membersWithoutLeads = prevTeamMembers.filter(
        (member) => !newTeamLeads.find((mem) => mem._id === member._id)
      );

      return [...membersWithoutLeads, ...newTeamLeads];
    });
  };

  const getEmployeeOptions = (employees) => {
    const empWithDepartment = employees.filter((emp) => emp.department);
    if (empWithDepartment.length < 2) return employees;
    return employees.sort((a, b) => -b.department.localeCompare(a.department));
  };

  const fixedOption = teamLeads;

  return (
    <Container>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={getModalStyle()}
        contentLabel="Team Deletion Modal"
      >
        <Heading2>
          Bist Du sicher, dass Du dieses Team aus der App entfernen willst?
        </Heading2>
        <ButtonContainer>
          <Button onClick={() => setIsModalOpen(false)} variant="neutral">
            Abbrechen
          </Button>
          <Button onClick={handleDelete} variant="warning">
            Löschen
          </Button>
        </ButtonContainer>
      </Modal>
      <TopContainer>
        <StyledTextField
          defaultValue={teamName}
          autoComplete="false"
          onChange={(event) => setTeamName(event.target.value)}
          id="outlined-basic"
          variant="outlined"
          label="Name"
        />
        <LeadAutoComplete
          value={teamLeads}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          onChange={(event, value) => handleTeamLeadChange(value)}
          disablePortal
          multiple
          id="combo-box-demo"
          options={getEmployeeOptions(employees)}
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          groupBy={(option) => option.department}
          renderInput={(params) => (
            <TextField label="Führungskräfte" {...params} />
          )}
        />
      </TopContainer>
      <MemberContainer>
        <Autocomplete
          value={teamMembers}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          onChange={(event, newValue) => {
            setTeamMembers([
              ...fixedOption,
              ...newValue.filter(
                (option) => !teamLeads.find((mem) => mem._id === option._id)
              ),
            ]);
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.firstName + " " + option.lastName}
                {...getTagProps({ index })}
                disabled={teamLeads.find((member) => member._id === option._id)}
              />
            ))
          }
          disableCloseOnSelect
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          multiple
          groupBy={(option) => option.department}
          id="multiple-limit-tags"
          options={getEmployeeOptions(employees)}
          renderInput={(params) => (
            <TextField {...params} placeholder="Mitglieder" />
          )}
        />
        <DiversityInfoText>
          Um Scores berechnen zu können, muss es im Team mindestens 4 Mitglieder
          geben.
        </DiversityInfoText>
      </MemberContainer>
      <ButtonContainer>
        <Button type="submit" variant="neutral" onClick={() => handleSubmit()}>
          Speichern
        </Button>
        <Button variant="warning" onClick={() => setIsModalOpen(true)}>
          Entfernen
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default React.memo(TeamEditForm);
