import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { TimelineContainer } from "./styles";

const Timeline = ({ scoreOverTime, lineColor }) => {
  return (
    <TimelineContainer>
      <LineChart
        width={850}
        height={385}
        data={scoreOverTime}
        margin={{ top: 30, right: 20, bottom: 25, left: 0 }}
      >
        <Line
          type="monotone"
          dataKey="ratingCombined"
          stroke={lineColor}
          strokeWidth={3}
          name="Score"
        />
        <Tooltip />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="date" domain={["", " "]} minTickGap={24}>
          <Label position="insideBottomRight" offset={-15}>
            Datum
          </Label>
        </XAxis>
        <YAxis domain={[0, 10]} tickCount={6}>
          <Label position="top" offset={15}>
            Score
          </Label>
        </YAxis>
      </LineChart>
    </TimelineContainer>
  );
};

export default Timeline;
