import { FieldContainer, InputContainer, StyledTextField } from "./styles";

import React from "react";
import usePortalSize from "../../../../../../hooks/usePortalSize";

const StaticFields = ({ employee }) => {
  const { firstName, lastName, mail } = employee;
  const windowSize = usePortalSize();
  const dynamic_size = windowSize === "s" ? "small" : "medium";
  return (
    <>
      <InputContainer className={windowSize}>
        <FieldContainer className={windowSize}>
          <StyledTextField
            size={dynamic_size}
            defaultValue={firstName}
            label="Vorname"
            disabled
          />
        </FieldContainer>
        <FieldContainer className={windowSize}>
          <StyledTextField
            size={dynamic_size}
            defaultValue={lastName}
            label="Nachname"
            disabled
          />
        </FieldContainer>
      </InputContainer>
      <InputContainer className={windowSize}>
        <FieldContainer className={windowSize}>
          <StyledTextField
            size={dynamic_size}
            defaultValue={mail}
            label="E-Mail"
            disabled
          />
        </FieldContainer>
        <FieldContainer className={windowSize}>
          <StyledTextField
            size={dynamic_size}
            defaultValue={employee.userId}
            label="Benutzername"
            disabled
          />
        </FieldContainer>
      </InputContainer>
    </>
  );
};

export default StaticFields;
