const getReader = (onLoad, setErrMsg) => {
  const reader = new FileReader();

  reader.onabort = () => setErrMsg();
  reader.onerror = () => setErrMsg();
  reader.onload = () => onLoad(reader);

  return reader;
};

export default getReader;
