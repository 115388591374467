import colors from "../../common/colors";
import styled from "@emotion/styled";

const computeRatingFemaleColor = (score) => {
  switch (true) {
    case parseFloat(score) > 7.5:
      return colors.green;
    case parseFloat(score) > 5.0:
      return colors.orange;
    default:
      return colors.red;
  }
};

const computeTrendColor = (trend) => {
  switch (true) {
    case parseFloat(trend) < 0:
      return `${colors.red}`;
    case parseFloat(trend) > 0:
      return `${colors.green}`;
    default:
      return `${colors.midnight}`;
  }
};

export const MainScoreSectionContainer = styled("div")`
  width: 100%;
  height: 400px;
  display: flex;
  margin-left: 83px;
`;

export const ScoreInfoArea = styled("div")`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ScoreArea = styled("div")`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
`;

export const RatingFemale = styled("div")`
  color: ${(props) => computeRatingFemaleColor(props.score)};
  font-size: 68px;
  font-weight: 500;
  margin-top: 10px;
`;

export const RatingMale = styled("div")`
  color: ${colors.darkGrey};
  font-size: 58px;
  font-weight: 500;
  width: 50%;
`;

export const TrendContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  width: 120px;
  height: 20px;
`;

export const TrendFemale = styled("div")`
  color: ${(props) => computeTrendColor(props.trend)};
  font-size: 20px;
  font-weight: 500;
  margin-right: 5px;
`;

// TODO refactor Trends to own reusable component
export const TrendMale = styled("div")`
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
  align-self: right;
`;

export const MetaInfoArea = styled("div")`
  width: 130%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  margin-left: -85px;
`;

export const ScoreGraphArea = styled("div")`
  width: 65%;
  height: 100%;
  display: flex;
`;

export const ActivityHeader = styled("div")`
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 5px;
  text-align: start;
`;

export const Activity = styled("div")`
  font-size: 16px;
  font-weight: 500;
`;

export const FemaleInfo = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

export const OtherInfo = styled("div")`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

export const ScoreHeader = styled("h2")`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`;

export const LoadingContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
`;

export const ScoreContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: end;
`;

export const CompareNumber = styled("div")`
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
  height: 35%;
  align-items: end;
`;

export const ActivityContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 90%;
  height: 26px;
  margin-bottom: 11px;
`;

export const Cont = styled("div")`
  display: flex;
`;
