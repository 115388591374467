import Alert from "@mui/material/Alert";
import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const StyledAlert = styled(Alert)`
  color: ${colors.midnight};
  background-color: ${colors.lightGrey};
  border-color: ${colors.darkGrey};
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  & .MuiAlert-icon {
    color: ${colors.midnight};
  }
`;

export const AlertMailLink = styled("a")`
  color: ${colors.lavender};
`;
