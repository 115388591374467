import { ReactComponent as BelongingIcon } from "../../icons/areas/belonging.svg";
import { ReactComponent as FeedbackIcon } from "../../icons/areas/feedback.svg";
import { ReactComponent as HammerWrenchIcon } from "../../icons/areas/hammerWrench.svg";
import { ReactComponent as InclusiveCareerIcon } from "../../icons/areas/inclusiveCareer.svg";
import { ReactComponent as InclusiveCultureIcon } from "../../icons/areas/culture.svg";
import { ReactComponent as InclusiveLeadershipIcon } from "../../icons/areas/inclusiveLeadership.svg";
import { ReactComponent as InclusiveTeamworkIcon } from "../../icons/areas/inclusiveTeamwork.svg";
import { ReactComponent as PersonalDevelopmentIcon } from "../../icons/areas/personalDevelopment.svg";
import React from "react";
import { ReactComponent as RecognitionIcon } from "../../icons/areas/recognition.svg";
import { ReactComponent as SafenessIcon } from "../../icons/areas/safeness.svg";
import { ReactComponent as WellnessIcon } from "../../icons/areas/wellness.svg";
import { ReactComponent as WorkLifeBalanceIcon } from "../../icons/areas/workLifeBalance.svg";
import { ReactComponent as WorkingConditionsIcon } from "../../icons/areas/workingConditions.svg";
import colors from "../common/colors";

// TODO select them by ID or identifier
const AreaIcon = ({ type, size = 35, stroke = `${colors.midnight}` }) => {
  switch (type) {
    case "belonging":
      return <BelongingIcon width={size} stroke={stroke} />;
    case "inclusiveLeadership":
      return <InclusiveLeadershipIcon width={size} stroke={stroke} />;
    case "inclusiveTeamwork":
      return <InclusiveTeamworkIcon width={size} stroke={stroke} />;
    case "inclusiveCulture":
      return <InclusiveCultureIcon width={size} stroke={stroke} />;
    case "workLifeBalance":
      return <WorkLifeBalanceIcon width={size} stroke={stroke} />;
    case "recognitionNew":
      return <RecognitionIcon width={size} stroke={stroke} />;
    case "wellness":
      return <WellnessIcon width={size} stroke={stroke} />;
    case "safeness":
      return <SafenessIcon width={size} stroke={stroke} />;
    case "personalDevelopment":
      return <PersonalDevelopmentIcon width={size} stroke={stroke} />;
    case "feedback":
      return <FeedbackIcon width={size} stroke={stroke} />;
    case "inclusiveCareer":
      return <InclusiveCareerIcon width={size} stroke={stroke} />;
    case "workingConditions":
      return <WorkingConditionsIcon width={size} stroke={stroke} />;
    case "customArea":
      return <HammerWrenchIcon width={size} stroke={stroke} />;
    default:
      return <InclusiveCareerIcon width={size} stroke={stroke} />;
  }
};

export default React.memo(AreaIcon);
