import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const MissingTeamsInfo = styled("div")`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 20px;
  color: ${colors.darkGrey};
`;
