const validateEmployeeUpload = (
  employee,
  oldEmployees,
  newEmployees,
  index
) => {
  const { mail, firstName, lastName, teams } = employee;

  const mailError = validateMail(mail, oldEmployees, newEmployees, index);
  const firstNameError = validateFirstName(firstName);
  const lastNameError = validateLastName(lastName);
  const teamError = validateTeams(teams);

  const errorString = concatenateNonEmptyStrings(
    mailError,
    firstNameError,
    lastNameError,
    teamError
  );

  return errorString;
};

function concatenateNonEmptyStrings(...strings) {
  const nonEmptyStrings = strings.filter((str) => str !== "");
  return nonEmptyStrings.join(", ");
}

const validateMail = (mail, oldEmployees, newEmployees, index) => {
  if (!mail) {
    return "";
  }
  if (!mailRegex.test(mail)) {
    return "Die E-Mail-Adresse ist ungültig";
  }
  if (oldEmployees.find((oldEmployee) => oldEmployee.mail === mail)) {
    return "Es gibt bereits einen Eintrag mit dieser E-Mail-Adresse";
  }
  const duplicateErrorMsg = checkForDuplicates(mail, newEmployees, index);
  if (duplicateErrorMsg !== "") {
    return duplicateErrorMsg;
  }
  return "";
};

const checkForDuplicates = (mail, newEmployees, index) => {
  const duplicateIndices = newEmployees.reduce(
    (acc, newEmployee, currentIndex) => {
      if (newEmployee.mail === mail && currentIndex !== index) {
        acc.push(currentIndex + 1);
      }
      return acc;
    },
    []
  );

  if (duplicateIndices.length > 1) {
    const indicesText = duplicateIndices.join(", ");
    return `In der CSV ist diese E-Mail schon in den Zeilen ${indicesText} vorhanden`;
  }
  if (duplicateIndices.length === 1) {
    return `Die E-Mail-Adresse ist bereits in der CSV in Zeile ${duplicateIndices[0]} vorhanden`;
  }
  return "";
};

const validateFirstName = (firstName) => {
  if (!firstName) {
    return "Der Vorname ist erforderlich";
  }
  if (firstName.length > 255) {
    return "Der Vorname darf maximal 255 Zeichen lang sein";
  }
  if (!nameRegex.test(firstName)) {
    return "Der Vorname darf keine Zahlen enthalten";
  }

  return "";
};

const validateLastName = (lastName) => {
  if (!lastName) {
    return "Der Nachname ist erforderlich";
  }
  if (lastName.length > 255) {
    return "Der Nachname darf maximal 255 Zeichen lang sein";
  }
  if (!nameRegex.test(lastName)) {
    return "Der Nachname darf keine Zahlen enthalten";
  }
  return "";
};

const validateTeams = (teams) => {
  if (!teams) {
    return "";
  }
  if (teams.some((team) => team.length > 255)) {
    return "Ein Teamname darf maximal 255 Zeichen lang sein";
  }
  return "";
};

const mailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const nameRegex = /^([^0-9]*)$/;

export { validateEmployeeUpload };
