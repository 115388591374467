import { CircularProgress } from "@mui/material";
import { Container } from "./styles";
import React from "react";

const LoadingChat = () => {
  return (
    <Container>
      <div></div>
      <CircularProgress color="primary" />
      <div></div>
    </Container>
  );
};

export default LoadingChat;
