import {
  CommentContainer,
  Container,
  Question,
  SendButton,
  SendButtonContainer,
  TextFieldContainer,
} from "./styles";
import React, { useEffect, useRef, useState } from "react";

import ChatPlaceholder from "./ChatPlaceholder";
import CustomAlert from "../DialogPage/CustomAlert";
import LoadingChat from "./LoadingChat";
import MessageSection from "./MessageSection";
import SendIcon from "@mui/icons-material/Send";
import { TextField } from "@mui/material";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";

const ChatSection = ({
  chatMessages,
  setChatMessages,
  selectedDialog,
  isChatLoading,
  newDialogsAmount,
  loggedInUser,
  role,
  isDialogActive,
}) => {
  const [inputMessage, setInputMessage] = useState(null);
  const [user, setUser] = useState(null);

  const chatRef = useRef(null);
  const { postChatMessage, getEmployee } = useLightsApiPrivate();

  const chatMessageLength = useRef(chatMessages.length);

  useEffect(() => {
    getEmployee(loggedInUser.id).then((employee) => {
      setUser(employee);
      scrollToBottom(chatRef);
    });

    if (chatMessageLength.current !== chatMessages.length && chatRef.current) {
      scrollToBottom(chatRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  const handleSend = () => {
    if (!inputMessage || inputMessage.length === 0) return;
    const sender = role === "survee" ? "survee" : user?.firstName;

    const newMessage = {
      message: inputMessage,
      sender,
      createdAt: new Date(),
      authorName: "Du",
      isAnonymous: false,
      isCurrentAuthor: true,
    };

    setChatMessages([...chatMessages, newMessage]);
    setInputMessage(null);

    try {
      postChatMessage({
        dialogId: selectedDialog.id.toString(),
        message: inputMessage,
      });
    } catch (error) {
      chatMessages.pop();
    }
  };

  if (isChatLoading) return <LoadingChat />;

  if (!selectedDialog || chatMessages.length === 0) {
    return <ChatPlaceholder newCommentsAmount={newDialogsAmount} />;
  }

  return (
    <Container>
      <Question>{selectedDialog.question}</Question>
      <MessageSection
        chatRef={chatRef}
        chatMessages={chatMessages}
        loggedInUser={loggedInUser}
      />
      {isDialogActive ? (
        <CommentContainer>
          <TextFieldContainer>
            <TextField
              value={inputMessage || ""}
              id="outlined-multiline-flexible"
              multiline
              maxRows={2}
              fullWidth={true}
              onChange={(e) => setInputMessage(e.target.value)}
            />
          </TextFieldContainer>
          <SendButtonContainer>
            <SendButton onClick={() => handleSend()}>
              <SendIcon color="white" />
            </SendButton>
          </SendButtonContainer>
        </CommentContainer>
      ) : (
        <CustomAlert />
      )}
    </Container>
  );
};

export default ChatSection;
