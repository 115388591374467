import styled from "@emotion/styled";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 100%;
  overflow-y: scroll;
`;

export default Container;
