import styled from "@emotion/styled";

export const Container = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  &.s {
    text-align: center;
  }
`;
