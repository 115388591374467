import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProfileRedirect = ({ redirectTo }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(redirectTo);
  });
  return null;
};

export default ProfileRedirect;
