import Container from "./styles";
import MessageBubble from "./MessageBubble";
import React from "react";

const MessageSection = ({ chatMessages, chatRef }) => {
  return (
    <Container ref={chatRef}>
      {chatMessages.map((chatMessage, index) => {
        const nextMessage = chatMessages[index + 1];
        const isLastMessage = index === chatMessages.length - 1;
        return (
          <MessageBubble
            chatMessage={chatMessage}
            nextMessage={isLastMessage ? {} : nextMessage}
          />
        );
      })}
    </Container>
  );
};

export default MessageSection;
