import React from "react";
import { StyledDataGrid } from "./styles";
import { cellStyle } from "./styles";
import { columns } from "./columns";
import { localizedTextsMap } from "../../../../localization/dataGrid";
import { useNavigate } from "react-router-dom";

const TeamsTable = ({ teams }) => {
  const navigate = useNavigate();

  const handleRowClick = ({ row }) => {
    navigate(`/settings/team/${row.id}/profile`);
  };

  return (
    <>
      <StyledDataGrid
        disableColumnSelector
        stickyHeader
        onRowClick={handleRowClick}
        sx={cellStyle}
        localeText={localizedTextsMap}
        columns={columns}
        rows={teams}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        pageSizeOptions={[5, 10, 50]}
      />
    </>
  );
};
export default TeamsTable;
