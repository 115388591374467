import styled from "@emotion/styled";

export const Container = styled("div")`
  position: fixed;
  width: 100%;
  height: 65px;
  left: 0px;
  top: -2px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #c1c1c1;
`;

export const SettingsPictureContainer = styled("div")`
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-right: 15px;
  border-radius: 10px;
  float: right;
`;

export const Settings = styled("div")`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const MenuContainer = styled("div")`
  position: fixed;
  right: 0px;
  top: 64px;
`;
