import menuItems from "./menuItems";

function getMenuItems({ activeRole, isSurvee }) {
  const adminMenuItems = [...menuItems.admin];
  const surveeMenuItems = [...menuItems.survee];

  const logoutItem = surveeMenuItems[2];
  const switchItem = surveeMenuItems[0];

  if (activeRole === "admin" || activeRole === "super admin") {
    return adminMenuItems;
  }

  isSurvee && surveeMenuItems.shift();

  if (activeRole === "survee") {
    return surveeMenuItems;
  }

  if (isSurvee) {
    return [logoutItem];
  }

  return [switchItem, logoutItem];
}

export default getMenuItems;
