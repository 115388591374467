import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { Container } from "./styles";
import React from "react";
import mobilePageList from "./mobilePageList";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MobileWarningPage = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isUnsupportedScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { pathname } = useLocation();

  const isNonOptimized = mobilePageList.includes(pathname);

  if (isNonOptimized && isUnsupportedScreen) {
    return (
      <Container>
        <p>Diese Seite ist noch nicht für Mobilgeräte optimiert.</p>
        <Button
          sx={{
            minWidth: "110px",
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: "5px",
            marginTop: "-5px",
            fontSize: "12px",
            paddingTop: "2px",
            paddingBottom: "2px",
          }}
          onClick={() => navigate("/pulse")}
          variant="contained"
        >
          Zum Pulse Check
        </Button>
      </Container>
    );
  }

  return <>{children}</>;
};

export default MobileWarningPage;
