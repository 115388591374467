const colors = {
  white: "#ffffff",
  black: "#202124",
  orange: "#EE930B",
  yellow: "#FFB703",
  grey: "#E5E5E5",
  lightGrey: "#F4F4F4",
  darkGrey: "#C1C1C1",
  blue: "#14213D",
  lightBlue: "#2C4884",
  red: "#AB0000",
  green: "#78AB00",
  natural: "#EBE1E1",
  coral: "#FFA582",
  lime: "#8CFA73",
  lavender: "#7378EB",
  lavenderLight: "#DDDEF8",
  midnight: "#1E2341",
  transparent: "#ffffff99",
};

export default colors;
