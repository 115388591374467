const getGermanFormat = (date) => {
  const OPTIONS = {
    month: "short",
    day: "numeric",
  };

  return date.toLocaleDateString("de-DE", OPTIONS);
};

module.exports = { getGermanFormat };
