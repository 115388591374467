import {
  FactHeading,
  StyledAlert,
  StyledParagraph,
  TextContainer,
} from "./styles";
import React, { useState } from "react";

import RedirectModal from "./RedirectModal";

const FactsAlert = ({ fact }) => {
  const [modalLink, setModalLink] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { fact: description, link, source, year } = fact;

  const handleLinkClick = (e) => {
    e.preventDefault();
    setModalLink(link);
    setShowModal(true);
  };

  return (
    <>
      <StyledAlert severity="info" variant="filled">
        <TextContainer>
          <FactHeading>Fakt des Tages</FactHeading>
          <StyledParagraph>{description}</StyledParagraph>
          <a href={link} onClick={handleLinkClick}>
            Quelle: {source} ({year})
          </a>
        </TextContainer>
      </StyledAlert>
      <RedirectModal
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
        link={modalLink}
      />
    </>
  );
};

export default FactsAlert;
