import * as Sentry from "@sentry/react";

import {
  ConfirmationContainer,
  DragDrop,
  DragDropInput,
  DragText,
  ImportForm,
  ImportSection,
  UploadConfirmation,
} from "./styles.js";
import React, { useState } from "react";

import DownloadSection from "./DownloadSection/index.js";
import OnboardingPageContainer from "../OnboardingPageContainer/index.js";
import PreviewModal from "./Preview/PreviewModal";
import Text from "../../../common/Text";
import getReader from "./csvHelper/getReader";
import { mapData } from "./csvHelper/processCsv";
import { useAlert } from "react-alert";
import { useDropzone } from "react-dropzone";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";
import { validateEmployeeUpload } from "./csvHelper/employeeValidation.js";

const UserImportStep = ({ props }) => {
  const alert = useAlert();

  const [err, setErr] = React.useState(null);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [errorPreviewData, setErrorPreviewData] = useState([]);

  const { createEmployees, getEmployees } = useLightsApiPrivate();

  const onDrop = async (acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const reader = getReader(onLoad, displayErrorMsg);
      reader.readAsText(file);
    });
  };

  const onLoad = async (reader) => {
    try {
      setErr(null);
      const binaryStr = reader.result;

      const processedData = await processData(binaryStr);

      const amountColumns = processedData[0].length;
      if (amountColumns !== 4 && amountColumns !== 3 && amountColumns !== 2) {
        alert.error(
          "Falsches Format - bitte verwende die Struktur der CSV-Datei aus der Anleitung."
        );
        return;
      }

      const employees = mapData(processedData);
      const oldEmployees = await getEmployees();
      const validatedEmployees = employees.map((employee, index) => {
        const errorMsg = validateEmployeeUpload(
          employee,
          oldEmployees,
          employees,
          index
        );
        if (errorMsg !== "") {
          return {
            ...employee,
            error: errorMsg,
            csvIndex: index + 1,
            role: "survee",
          };
        }
        return { ...employee, role: "survee" };
      });

      const entriesWithErrors = validatedEmployees.filter((emp) => emp.error);
      const entriesWithoutErrors = validatedEmployees.filter(
        (emp) => !emp.error
      );

      setErrorPreviewData(entriesWithErrors);
      setPreviewData(entriesWithoutErrors);

      setErr(null);
    } catch (err) {
      setErr(err);
      Sentry.captureException(err);
    }
  };

  const displayErrorMsg = () => {
    alert.error(
      "Leider ist etwas schief gelaufen. Schau dir nochmal unsere Anleitung zum CSV-Upload an oder kontaktiere support@diverlyze.com"
    );
  };

  const processData = async (csv) => {
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(";");
      var tarr = [];
      for (var j = 0; j < data.length; j++) {
        tarr.push(data[j]);
      }
      lines.push(tarr);
    }
    return lines;
  };

  const exitPreview = () => {
    setPreviewData([]);
    setErrorPreviewData([]);
    alert.error("Upload abgebrochen");
  };

  const confirmUpload = async () => {
    try {
      await createEmployees(previewData);
      setUploadSuccessful(true);
      setPreviewData([]);
      setErrorPreviewData([]);
      alert.success("Upload erfolgreich.");
    } catch (err) {
      Sentry.captureException(err);
      setPreviewData([]);
      setErrorPreviewData([]);
      alert.error("Es gab ein Problem beim Upload.");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const uploadInactive = uploadSuccessful;
  const showModal = previewData.length > 0 || errorPreviewData.length > 0;

  return (
    <OnboardingPageContainer
      props={{
        ...props,
        headline: "Importiere ausgewählte Personaldaten.",
        buttonLabel: "Weiter",
      }}
    >
      <PreviewModal
        errorPreviewData={errorPreviewData}
        isModalOpen={showModal}
        closeModal={exitPreview}
        confirmUpload={confirmUpload}
        previewData={previewData}
      />
      <ImportForm>
        <ImportSection>
          <DownloadSection />
          {uploadInactive ? (
            <ConfirmationContainer>
              <UploadConfirmation>Import erfolgreich!</UploadConfirmation>
            </ConfirmationContainer>
          ) : (
            <DragDrop {...getRootProps()}>
              <DragDropInput {...getInputProps()} accept=".csv,.xlsx,.xls" />
              {isDragActive ? (
                <DragText>Droppe die Datei hier...</DragText>
              ) : (
                <DragText>
                  Drag and Drop eine Datei, oder klicke um eine Datei
                  auszuwählen
                </DragText>
              )}
            </DragDrop>
          )}
          {err ? <Text>{err}</Text> : null}
        </ImportSection>
      </ImportForm>
    </OnboardingPageContainer>
  );
};

export default UserImportStep;
