import {
  Container,
  ListItem,
  StyledDetails,
  StyledSpan,
  StyledSummary,
  StyledText,
  StyledUl,
} from "./styles";

import Heading3 from "../../../../common/Heading3";
import React from "react";
import usePortalSize from "../../../../../hooks/usePortalSize";

const ConsentSection = () => {
  const windowSize = usePortalSize();
  const detailsOpen = windowSize !== "s";

  return (
    <Container>
      <Heading3>
        Lasst uns gemeinsam für Chancengleichheit und ein vorurteilsfreies
        Miteinander eintreten!
      </Heading3>
      <StyledSpan>
        <StyledDetails className={windowSize} open={detailsOpen}>
          <StyledSummary className={windowSize}></StyledSummary>
          <StyledText>
            Bei Diverlyze wollen wir ein inklusives und vielfältiges
            Arbeitsumfeld schaffen, in dem sich in dem sich alle wertgeschätzt,
            respektiert und unterstützt fühlen.
          </StyledText>
          <StyledText>
            Um dieses Ziel zu erreichen, brauchen wir Deine Hilfe. Wir bitten
            Dich, uns einige Informationen zur Verfügung zu stellen. Wir möchten
            betonen, dass Deine Teilnahme freiwillig ist. Du kannst jedoch
            sicher sein, dass alle Deine Angaben vertraulich behandelt und nur
            in anonymisierter Form datenschutzkonform verarbeitet werden.
          </StyledText>
          <StyledText>
            Warum ist es wichtig, diese Angaben zu machen? Hier sind drei
            Gründe:
          </StyledText>
          <Heading3>1. Chancengleichheit ist noch keine Realität</Heading3>
          <StyledText>Einige Fakten</StyledText>
          <StyledUl className={windowSize}>
            <ListItem>
              47% der Erwerbstätigen haben Diskriminierung am Arbeitsplatz
              erlebt.
            </ListItem>
            <ListItem>
              68% der Frauen haben sexuelle Belästigung am Arbeitsplatz erlebt.
            </ListItem>
            <ListItem>
              12% der Homosexuellen treten am Arbeitsplatz offen homosexuell
              auf.
            </ListItem>
          </StyledUl>
          <StyledText>Erschreckend im Jahr 2024, oder?</StyledText>
          <Heading3>2. Gerechtigkeit fördern</Heading3>
          <StyledText>
            Die Daten ermöglichen es uns, die vielfältigen Hintergründe Eurer
            Organisation besser zu verstehen und potenzielle Ungleichheiten zu
            erkennen. Dieses Wissen hilft uns, Richtlinien, Programme und
            Initiativen zu entwickeln, damit sich alle willkommen und vertreten
            fühlen.
          </StyledText>
          <Heading3>3. Fortschritte verfolgen</Heading3>
          <StyledText id="detailsPadding" className={windowSize}>
            Die von Dir bereitgestellten Daten helfen uns, unsere Fortschritte
            bei der Erfüllung unserer Mission im Laufe der Zeit zu verfolgen.
            Sie ermöglichen es uns, die Wirksamkeit unserer Maßnahmen zu messen,
            Bereiche für Verbesserungen zu identifizieren und Erfolge zu feiern.
          </StyledText>
        </StyledDetails>
      </StyledSpan>
    </Container>
  );
};

export default ConsentSection;
