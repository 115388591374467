import {
  ButtonContainer,
  Checkcontainer,
  Container,
  ThankYouMessage,
} from "./styles";

import { Button } from "@mui/material";
import FactAlert from "../../FactAlert";
import { PULSEVIEWS } from "../pulseViews";
import React from "react";
import check_gif from "../../../../assets/animations/check.gif";

const PulseThanks = ({ setView, fact }) => {
  const notCachedGif = check_gif + "?" + performance.now();

  return (
    <Container>
      <ThankYouMessage>Danke für Deine Teilnahme!</ThankYouMessage>
      <Checkcontainer src={notCachedGif}></Checkcontainer>
      <ButtonContainer>
        <Button variant="confirm" onClick={() => setView(PULSEVIEWS.question)}>
          Nächster Pulse Check
        </Button>
      </ButtonContainer>
      <FactAlert fact={fact} />
    </Container>
  );
};

export default PulseThanks;
