import {
  BIRTHDAY_STEP_TITLE,
  PARENTAL_LEAVE_STEP_TITLE,
} from "../steps/stepTitles";
import {
  BackButtonContainer,
  ButtonSection,
  CheckContainer,
  DimensionCheck,
  DimensionContainer,
  DropdownContainer,
  FactContainer,
  InfoIconContainer,
  MobileOuterContainer,
  OuterContainer,
  QuestionContainer,
  QuestionLink,
  QuestionText,
  QuestionTextContainer,
  SideContainer,
} from "./styles";
import React, { useState } from "react";

import { ArrowBackRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import DimensionContent from "../../../../content/onboarding/admin/DimensionInfos.json";
import DimensionInfoModal from "../DimensionInfoModal";
import Fact from "./Fact";
import { ReactComponent as InfoIcon } from "../../../../icons/info.svg";
import RedirectModal from "../../../PulseCheckPage/FactAlert/RedirectModal";
import SurveeOnboardingContainer from "../SurveeOnboardingContainer";
import colors from "../../../common/colors";
import usePortalSize from "../../../../hooks/usePortalSize";

const OptionStepContainer = ({ props, children }) => {
  const { saveAndNext, selected, handlePrevStep, dimension } = props;
  const [isRedirectModalOpen, setRedirectModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [isDimensionModalOpen, setDimensionModalOpen] = React.useState(false);

  const buttonLabel = selected ? "Weiter" : "Überspringen";
  const size = usePortalSize();
  const isMobile = size === "s";

  const content = DimensionContent.find((entry) => entry.id === dimension);

  const MODAL_INFO_HEADER =
    "Warum fragen wir nach dieser Dimension und was genau bedeuten die Auswahlmöglichkeiten? Das erfährst Du auf unserer Website.";

  const isSmallDropdown =
    dimension === BIRTHDAY_STEP_TITLE ||
    dimension === PARENTAL_LEAVE_STEP_TITLE;

  const hasExternalLink = dimension !== PARENTAL_LEAVE_STEP_TITLE;

  return (
    <SurveeOnboardingContainer>
      <RedirectModal
        isOpen={isDimensionModalOpen}
        onRequestClose={() => {
          setDimensionModalOpen(false);
        }}
        link={content.externalLink}
        infoHeader={MODAL_INFO_HEADER}
      />
      <RedirectModal
        isOpen={isRedirectModalOpen}
        onRequestClose={() => {
          setRedirectModalOpen(false);
        }}
        link={content.factLink}
      />
      <DimensionInfoModal
        isModalOpen={isInfoModalOpen}
        closeModal={() => setIsInfoModalOpen(false)}
        content={content}
        hasExternalLink={hasExternalLink}
      />
      <OuterContainer className={size}>
        {isMobile && (
          <MobileOuterContainer>
            <ArrowBackRounded
              onClick={() => handlePrevStep()}
              color="primary"
              style={{ color: colors.lavender }}
              fontSize="large"
            />
            <Button variant="confirm" onClick={saveAndNext}>
              {buttonLabel}
            </Button>
          </MobileOuterContainer>
        )}
        <DimensionContainer className={size}>
          <SideContainer className={size}>
            <BackButtonContainer className={size}>
              <ArrowBackRounded
                className={size}
                onClick={() => handlePrevStep()}
                color="primary"
                style={{ color: colors.lavender }}
                fontSize="large"
              />
            </BackButtonContainer>
          </SideContainer>
          <QuestionContainer className={size}>
            <DimensionCheck className={size}>
              <QuestionTextContainer className={size}>
                <QuestionText className={size}>
                  {content.question.map((part, index) =>
                    part.type === "link" ? (
                      <QuestionLink
                        className={size}
                        key={index}
                        onClick={() => setIsInfoModalOpen(true)}
                      >
                        {part.value}
                      </QuestionLink>
                    ) : (
                      part.value
                    )
                  )}
                </QuestionText>
              </QuestionTextContainer>
              <CheckContainer className={size}>
                <DropdownContainer
                  size={isSmallDropdown ? "small" : "large"}
                  className={size}
                >
                  {children}
                </DropdownContainer>
                <InfoIconContainer onClick={() => setDimensionModalOpen(true)}>
                  {hasExternalLink && (
                    <InfoIcon width={"25px"} strokeWidth={"2px"}></InfoIcon>
                  )}
                </InfoIconContainer>
              </CheckContainer>
            </DimensionCheck>
            {!isMobile && (
              <ButtonSection>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveAndNext}
                  style={{ backgroundColor: colors.lavender }}
                >
                  {buttonLabel}
                </Button>
              </ButtonSection>
            )}
          </QuestionContainer>
          <SideContainer className={size}></SideContainer>
        </DimensionContainer>
        <FactContainer className={size}>
          <Fact
            content={content}
            openModal={() => setRedirectModalOpen(true)}
          />
        </FactContainer>
      </OuterContainer>
    </SurveeOnboardingContainer>
  );
};

export default OptionStepContainer;
