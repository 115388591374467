import * as Sentry from "@sentry/react";

import {
  ButtonContainer,
  FormContainer,
  HintContainer,
  InputContainer,
  LoginContainer,
  Logo,
  ResendButton,
  ResendInfoText,
  StyledButton,
  StyledTextField,
  SubHeader,
} from "./styles";
import React, { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Heading1 from "../../common/Heading1";
import LoginPageContainer from "../LoginPageContainer";
import logo from "../../../assets/images/diverlyze_logo.png";
import useAuth from "../../../hooks/useAuth";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import usePortalSize from "../../../hooks/usePortalSize";

const TwoFaValidationPage = () => {
  const size = usePortalSize();
  const { setAuth } = useAuth();
  const { postTwofaCode, getTwofaCheck, postTwofaResend } =
    useLightsApiPrivate();
  const location = useLocation();

  const navigate = useNavigate();
  const errRef = useRef();

  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [didResend, setDidResend] = useState(false);

  useEffect(() => {
    setErrMsg("");

    const checkFor2FaCode = async () => {
      try {
        await getTwofaCheck();
      } catch (err) {
        navigate("/login", { state: { ...location.state } });
      }
    };

    checkFor2FaCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await postTwofaCode({ code });

      const accessToken = response?.accessToken;
      const role = response?.role;
      const id = response?.id;

      setAuth({ role, accessToken, id });
      setCode("");

      navigate("/settings", { state: { ...location.state } });
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        setErrMsg("Der Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        setErrMsg("Bitte gebe einen Code ein.");
      } else if (err.response?.status === 401) {
        setErrMsg("Der Code ist ungültig.");
      } else if (err.response?.status === 404) {
        setErrMsg("Der Code ist ungültig.");
      } else {
        setErrMsg(
          "Authentifizierung fehlgeschlagen. Versuche es zu einem späteren Zeitpunkt erneut."
        );
      }
    }
  };

  return (
    <LoginPageContainer>
      <LoginContainer className={size}>
        <Logo className={size} src={logo} />
        <FormContainer className={size}>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <Heading1>Zwei-Faktor-Authentifizierung</Heading1>
          <SubHeader>
            Wir haben Dir eine E-Mail mit dem Code geschickt.
          </SubHeader>
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <StyledTextField
                size="small"
                label="2-Faktor-Code"
                type="text"
                id="2fa"
                autoComplete="false"
                onChange={({ target }) => setCode(target.value)}
                value={code}
                InputLabelProps={{ required: false, shrink: true }}
              ></StyledTextField>
            </InputContainer>
            <ButtonContainer>
              <StyledButton variant="contained" type="submit">
                Weiter
              </StyledButton>
            </ButtonContainer>
            <HintContainer>
              {didResend ? (
                <ResendInfoText>
                  E-Mail mit Code erfolgreich versendet! Bitte oben eingeben.
                </ResendInfoText>
              ) : (
                <ResendButton
                  onClick={() => {
                    postTwofaResend();
                    setDidResend(true);
                  }}
                >
                  Code erneut senden
                </ResendButton>
              )}
            </HintContainer>
          </form>
        </FormContainer>
      </LoginContainer>
    </LoginPageContainer>
  );
};

export default memo(TwoFaValidationPage);
