import Button from "@mui/material/Button";
import colors from "../../common/colors";
import styled from "@emotion/styled";

/*Outer Container*/
export const LoginContainer = styled("div")`
  &.m {
    width: 100%;
  }
  &.s {
    width: 100%;
  }
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/*Container for buttons and inputfields*/
export const FormContainer = styled("div")`
  &.m {
    max-width: 80%;
  }
  &.s {
    min-width: 80%;
  }
  width: 35%;
`;

/*Container for button*/
export const ButtonContainer = styled("div")`
  margin-top: 20px;
  margin-bottom: 10px;
`;

/*Attributes for subheader*/
export const SubHeader = styled("div")`
  color: ${colors.darkGrey};
  font-weight: 500;
`;

/*Container for inputfields*/
export const InputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

/*Attributes for button*/
export const StyledButton = styled(Button)`
  width: 100%;
`;
