import colors from "../../../common/colors.js";
import styled from "@emotion/styled";

export const TextContainer = styled("div")`
  width: 50%;
  text-align: center;
  margin-bottom: 20px;
`;

export const Text = styled("p")`
  color: ${colors.midnight};
`;
