const mobilePageList = [
  "/report",
  "/contrast",
  "/areas",
  "/dialog",
  "/settings/users",
  "/settings/teams",
  "/settings/schedule",
];

export default mobilePageList;
