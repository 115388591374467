import {
  Container,
  IconContainer,
  ScoreContainer,
  SectionHeader,
  TitleContainer,
} from "./styles";

import BackButton from "../../settings/BackButton";
import Heading2 from "../Heading2";
import React from "react";
import Text from "../Text";
import TextWithTooltip from "../TextWithTooltip";
import { useNavigate } from "react-router-dom";
import usePortalSize from "../../../hooks/usePortalSize";

const AreaSection = ({ content, children, backLinkTo }) => {
  const navigate = useNavigate();
  const size = usePortalSize();

  const { title, infoText, hoverText, id } = content;
  const hasBackButton = backLinkTo !== undefined;

  return (
    <Container className={size}>
      {hasBackButton && <BackButton onClick={() => navigate(backLinkTo)} />}
      <SectionHeader className={size}>
        <TitleContainer>
          <Heading2>{title}</Heading2>
          {infoText && <Text fontSize="16px">{infoText}</Text>}
        </TitleContainer>
        <IconContainer>
          {hoverText && (
            <TextWithTooltip text={hoverText} id={id}></TextWithTooltip>
          )}
        </IconContainer>
      </SectionHeader>
      <ScoreContainer>{children}</ScoreContainer>
    </Container>
  );
};

export default AreaSection;
