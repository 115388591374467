import {
  BoxStyled,
  ButtonContainer,
  ButtonStyled,
  CheckBoxStyled,
  ContainerCheckBox,
  SelectedNumber,
} from "./styles";

import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { getActions } from "./getActions";

const ActionList = ({
  selected,
  handleSelectAllClick,
  onOpenInviteModal,
  onOpenReminderModal,
  onOpenDeleteModal,
  isInviteActive,
  isRemindActive,
  isDeleteActive,
  someChecked,
  allChecked,
}) => {
  const actions = getActions({
    selected,
    isInviteActive,
    isRemindActive,
    isDeleteActive,
  });

  const handleActionClick = (action) => {
    switch (action) {
      case "invite":
        onOpenInviteModal();
        break;
      case "reminder":
        onOpenReminderModal();
        break;
      case "delete":
        onOpenDeleteModal();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {selected.length > 0 && (
        <BoxStyled>
          <ContainerCheckBox>
            <CheckBoxStyled
              indeterminate={someChecked}
              checked={allChecked}
              onChange={handleSelectAllClick}
              size="small"
            />
          </ContainerCheckBox>
          <SelectedNumber>{selected.length} ausgewählt</SelectedNumber>
          <ButtonContainer>
            {actions.map((actionItem) => (
              <Tooltip
                key={actionItem.action}
                placement="bottom"
                arrow
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -12],
                      },
                    },
                  ],
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      maxWidth: "400px",
                    },
                  },
                }}
                title={actionItem.disable ? actionItem.tooltip : ""}
              >
                <span>
                  <ButtonStyled
                    size="small"
                    variant={
                      actionItem.action === "delete" ? "contained" : "neutral"
                    }
                    color={actionItem.action === "delete" ? "error" : "primary"}
                    onClick={() =>
                      !actionItem.disable &&
                      handleActionClick(actionItem.action)
                    }
                    disabled={actionItem.disable}
                  >
                    {actionItem.label}
                  </ButtonStyled>
                </span>
              </Tooltip>
            ))}
          </ButtonContainer>
        </BoxStyled>
      )}
    </>
  );
};

export default ActionList;
