export const localizedTextsMap = {
  columnMenuUnsort: "Nicht sortiert",
  columnMenuSortAsc: "Aufsteigend sortieren",
  columnMenuSortDesc: "Absteigend sortieren",
  columnMenuFilter: "Filter",
  columnMenuLabel: "Menü",
  // Filterpanel-Text
  filterPanelAddFilter: "Filter hinzufügen",
  filterPanelDeleteIconLabel: "Löschen",
  filterPanelLinkOperator: "Logischer Operator",
  filterPanelOperators: "Operator",
  filterPanelOperatorAnd: "Und",
  filterPanelOperatorOr: "Oder",
  filterPanelColumns: "Spalten",
  filterPanelInputLabel: "Wert",
  filterPanelInputPlaceholder: "Filterwert",
  // Text der Filteroperatoren
  filterOperatorContains: "enthält",
  filterOperatorEquals: "gleich",
  filterOperatorStartsWith: "beginnt mit",
  filterOperatorEndsWith: "endet mit",
  filterOperatorIs: "ist",
  filterOperatorNot: "ist nicht",
  filterOperatorAfter: "ist nach",
  filterOperatorOnOrAfter: "ist am oder nach",
  filterOperatorBefore: "ist vor",
  filterOperatorOnOrBefore: "ist am oder vor",
  filterOperatorIsEmpty: "ist leer",
  filterOperatorIsNotEmpty: "ist nicht leer",
  filterOperatorIsAnyOf: "ist eines von",
  // Text der Filterwerte
  filterValueAny: "beliebig",
  filterValueTrue: "wahr",
  filterValueFalse: "falsch",
};
