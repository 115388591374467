import CircularProgress from "@mui/material/CircularProgress";
import { LoadingContainer } from "./styles";
import React from "react";

const LoadingAnimation = (props) => {
  return (
    <LoadingContainer>
      <CircularProgress color="primary" />
    </LoadingContainer>
  );
};

export default LoadingAnimation;
