import { CircularProgress, TableContainer } from "@mui/material";

const LoadingAnimation = () => {
  return (
    <TableContainer
      sx={{ marginLeft: "100%", marginBottom: "20px", marginTop: "-20px" }}
    >
      <CircularProgress color="primary" />
    </TableContainer>
  );
};
export default LoadingAnimation;
