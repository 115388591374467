import { ContentContainer, InfoText, TextContainer } from "./styles";

import Button from "@mui/material/Button";
import Heading1 from "../../../components/common/Heading1";
import LoginPageContainer from "../../../components/login/LoginPageContainer";
import React from "react";
import { useNavigate } from "react-router-dom";
import usePortalSize from "../../../hooks/usePortalSize";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const size = usePortalSize();

  return (
    <LoginPageContainer>
      <ContentContainer className={size}>
        <TextContainer className={size}>
          <Heading1>404 - Not Found</Heading1>
          <InfoText>
            Sieht aus, als hättest Du Dich verlaufen. Die angeforderte URL wurde
            von diesem Server nicht gefunden.
          </InfoText>
          <Button variant="contained" onClick={() => navigate("/settings")}>
            Zurück zur App
          </Button>
        </TextContainer>
      </ContentContainer>
    </LoginPageContainer>
  );
};

export default NotFoundPage;
