import React, { useEffect, useState } from "react";

import AreaSection from "../common/AreaSection";
import { AreaSectionHeader } from "../common/AreaSectionHeader";
import ContrastTable from "./ContrastTable";
import DropdownContext from "./DropdownContext";
import Heading1 from "../common/Heading1";
import LoadingAnimation from "../common/LoadingAnimation";
import colors from "../common/colors";
import { getOptionById } from "../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../hooks/useLightsApiPrivate";

const ContrastPage = () => {
  const [selectedDimension, setSelectedDimension] = useState(null);
  const [scores, setScores] = useState([]);
  const [options, setOptions] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const {
    getAreas,
    getDiversityScoresForAreas,
    getDiversityScoresForQuestions,
    getAccountDimensions,
  } = useLightsApiPrivate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const areas = await getAreas();
        areas.sort((a, b) => a.name.localeCompare(b.name));
        setAreas(areas);
        if (!selectedArea && areas.length) {
          setSelectedArea(areas[0].id);
        }

        const dimensionsResponse = await getAccountDimensions();
        setDimensions(dimensionsResponse);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsTableLoading(true);

        if (!selectedDimension) {
          return;
        }

        const scoreResponse = await getScores(selectedArea, selectedDimension);

        setScores(scoreResponse);

        const dimensionOptions = scoreResponse.map((score) => score.option);
        dimensionOptions.sort(sortOptions);

        const uniqueOptions = new Set(dimensionOptions);
        setOptions([...uniqueOptions]);
        setIsTableLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea, selectedDimension]);

  const getScores = async (selectedArea, selectedDimension) => {
    if (selectedArea) {
      return await getDiversityScoresForQuestions(
        selectedArea,
        selectedDimension
      );
    } else {
      return await getDiversityScoresForAreas(selectedDimension);
    }
  };

  const updateSelection = (label) => {
    setSelectedDimension(label);
  };

  const updateLabelSelection = (label) => {
    const selected = areas.find((area) => area.name === label);
    if (selected) {
      setSelectedArea(selected._id);
    }
  };

  const sortOptions = (a, b) => {
    const optionA = getOptionById(a);
    const optionB = getOptionById(b);

    if (!optionA || !optionB) {
      return 0;
    }

    const { label: labelA } = optionA;
    const { label: labelB } = optionB;

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Contrast</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.SLTeamsSection}>
        <DropdownContext
          selected={selectedDimension}
          updateSelection={updateSelection}
          dimensions={dimensions}
          areas={areas}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
        />
        <ContrastTable
          options={options}
          scores={scores}
          selectedDimension={selectedDimension}
          selectedArea={selectedArea}
          isTableLoading={isTableLoading}
          onLabelClick={updateLabelSelection}
        />
      </AreaSection>
    </>
  );
};

export default ContrastPage;

const sectionContent = {
  TeamsSection: {
    id: "teams",
    title: "Deine Teams Analyse",
    infoText: "Vergleiche und analysiere alle Teams im Überblick.",
    hoverText:
      "Die erstellten Teams können im Teams Management bearbeitet und erstellt werden.",
  },
  SLTeamsSection: {
    id: "teams",
    title: "Deine Inklusions- & Gleichstellungsanalyse",
    infoText:
      "Analysiere und vergleiche die Ergebnisse der sieben Dimensionen der Vielfalt.",
  },
};
