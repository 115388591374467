import Heading from "./styles";
import React from "react";

const Heading2 = (props) => {
  const fntColor = props.fontColor ? props.fontColor : "black";

  return <Heading fontColor={fntColor}>{props.children}</Heading>;
};

export default Heading2;
