import { Container, ContentContainer } from "./styles";
import React, { useEffect, useState } from "react";

import EmployeeContext from "../../../context/EmployeeContext";
import Header from "../Header";
import MobileWarningPage from "../../common/MobileWarningPage";
import { Outlet } from "react-router-dom";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import usePortalSize from "../../../hooks/usePortalSize";

function PageContainer() {
  const [employees, setEmployees] = useState();
  const { getEmployees } = useLightsApiPrivate();
  const windowSize = usePortalSize();

  useEffect(() => {
    const fetchData = async () => {
      getEmployees().then((res) => setEmployees(res));
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <EmployeeContext.Provider value={{ employees, setEmployees }}>
        <Header />
        <ContentContainer className={windowSize}>
          <MobileWarningPage>
            <Outlet />
          </MobileWarningPage>
        </ContentContainer>
      </EmployeeContext.Provider>
    </Container>
  );
}

export default React.memo(PageContainer);
