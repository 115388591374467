import {
  CloseIcon,
  Container,
  IconContainer,
  QuestionContainer,
  Subheader,
} from "./styles";

import CommentsTable from "./CommentTable";
import React from "react";

const CommentModal = ({ comments, question, close }) => {
  return (
    <Container>
      <IconContainer>
        <CloseIcon onClick={close} />
      </IconContainer>
      <QuestionContainer>
        <Subheader>{question}</Subheader>
      </QuestionContainer>
      <CommentsTable comments={comments} />
    </Container>
  );
};

export default React.memo(CommentModal);
