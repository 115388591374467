import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  width: 100%;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2.5%;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const MissingTeamsInfo = styled("div")`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
  color: ${colors.darkGrey};
`;
