import ContrastTableCell from "../ContrastTableCell";
import React from "react";
const ContrastScoreCell = ({ keyName, score }) => {
  const answersLeft = score?.answersLeft ?? 4;
  if (!score?.hasEnoughEmployees) {
    return (
      <ContrastTableCell
        key={keyName}
        id={keyName}
        infoMessage="Da nicht mind. 3 Mitglieder dieses Kriterium erfüllen, kann kein Score angezeigt werden."
      />
    );
  }

  if (score?.score === null || score === undefined) {
    return (
      <ContrastTableCell
        key={keyName}
        id={keyName}
        type="loading"
        answersLeft={answersLeft}
      />
    );
  }

  return (
    <ContrastTableCell
      key={keyName}
      id={keyName}
      rating={score.score}
      type="score"
    >
      {score.score}
    </ContrastTableCell>
  );
};
export default ContrastScoreCell;
