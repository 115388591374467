import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";

import { dimensionOptions } from "../buttons";

const DropdownDimension = ({ selected, updateSelection, dimensions }) => {
  const defaultDimension =
    dimensionOptions.find((option) => dimensions.includes(option.id))?.id ||
    dimensionOptions[0].id;

  useEffect(() => {
    if (!selected && defaultDimension) {
      updateSelection(defaultDimension);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl sx={{ width: "250px" }}>
      <InputLabel>Dimension</InputLabel>
      <Select
        id="dimension-select"
        value={selected || defaultDimension}
        label="Dimension"
        onChange={(event) => {
          updateSelection(event.target.value);
        }}
        displayEmpty
        variant="outlined"
      >
        {dimensionOptions
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((button) => {
            const isDisabled = !dimensions.includes(button.id);
            return (
              <MenuItem
                key={button.key}
                value={button.id}
                disabled={isDisabled}
              >
                {button.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default DropdownDimension;
