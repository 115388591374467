import colors from "../common/colors";
import styled from "@emotion/styled";

export const TextContainer = styled("div")`
  text-align: center;
  width: 80%;
`;

export const CheckContainer = styled("img")`
  min-width: 150px;
  min-height: 150px;
  width: 40%;
  margin: -10% 0;
`;

export const StyledParagraph = styled("p")`
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: ${colors.midnight};
  margin: 25px;
`;

export const ThankYouMessage = styled("h2")`
  font-weight: 500;
  font-size: 22px;
  color: ${colors.midnight};
  margin: 10px 0 25px 0;
`;
