import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 900px;
  max-height: 600px;
  align-items: center;
`;

export const IconContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const CloseIcon = styled(CloseRoundedIcon)`
  cursor: pointer;
`;

export const QuestionContainer = styled("div")`
  height: 50px;
`;

export const Subheader = styled("h3")`
  font-family: Rubik;
  font-weight: 500;
`;
