import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ roles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  const hasAccess = roles.includes(auth?.role);

  if (!auth?.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!hasAccess) {
    return <Navigate to="/lost" />;
  }
  return <Outlet />;
};

export default RequireAuth;
