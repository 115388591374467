import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styled from "@emotion/styled";

export const ButtonContainer = styled("div")`
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CloseIcon = styled(CloseRoundedIcon)`
  right: 7px;
  top: 7px;
  position: absolute;
  cursor: pointer;
`;
