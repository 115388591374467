import { Button, Chip, CircularProgress } from "@mui/material";
import {
  ButtonContainer,
  ChipContainer,
  InfoText,
  LoadingContainer,
} from "./styles.js";
import React, { useEffect } from "react";

import OnboardingPageContainer from "../OnboardingPageContainer/index.js";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate.js";

const SurveeInvitaionStep = ({ props }) => {
  const [loading, setLoading] = React.useState(true);

  const { getEmployees, postEmployeesInvite } = useLightsApiPrivate();
  const [hasNewEmps, setHasNewEmps] = React.useState(false);

  const alert = useAlert();

  useEffect(() => {
    if (loading) {
      getEmployees().then((data) => {
        const empsWithMail = data.filter((emp) => emp.mail);
        const hasNewEmps = empsWithMail.some((emp) => emp.status === "created");
        setHasNewEmps(hasNewEmps);
        setLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonLabel = hasNewEmps ? "Später" : "Weiter";

  const inviteNewUsers = () => {
    try {
      postEmployeesInvite();
      setHasNewEmps(false);
      alert.success("Einladungen erfolgreich versendet");
    } catch (e) {
      alert.error("Es ist ein Fehler aufgetreten");
    }
  };

  return (
    <OnboardingPageContainer
      props={{
        ...props,
        headline: "Versende die Einladung zu den Pulse Checks.",
        buttonLabel,
      }}
    >
      {loading ? (
        <LoadingContainer>
          <CircularProgress color="primary" />
        </LoadingContainer>
      ) : (
        <>
          <InfoText>
            Damit die Mitarbeitenden teilnehmen können, müssen sie einen Account
            anlegen und die Teilnahmebedingungen akzeptieren.
          </InfoText>
          <InfoText>
            Du kannst die Einladungen entweder jetzt oder später versenden.
          </InfoText>
          <ButtonContainer>
            {hasNewEmps ? (
              <ChipContainer>
                <Button variant="outlined" onClick={() => inviteNewUsers()}>
                  Einladung senden
                </Button>
              </ChipContainer>
            ) : (
              <ChipContainer>
                <Chip label="Einladung gesendet" color="success" />
              </ChipContainer>
            )}
          </ButtonContainer>
        </>
      )}
    </OnboardingPageContainer>
  );
};

export default SurveeInvitaionStep;
