const getNextPulseCheckDay = (schedule) => {
  const today = new Date().getDay();

  const sortedSchedule = schedule.sort((a, b) => a.weekday - b.weekday);

  const thisWeek = sortedSchedule.filter((day) => day.weekday > today);
  const nextWeek = sortedSchedule.filter((day) => day.weekday <= today);

  const thisWeekNextDay = thisWeek.find((day) => day.hour);
  const nextWeekNextDay = nextWeek.find((day) => day.hour);

  if (!thisWeekNextDay && !nextWeekNextDay) {
    return "bald";
  }

  if (!thisWeekNextDay && today === nextWeekNextDay.weekday) {
    return `am nächsten ${weekdayNames[nextWeekNextDay.weekday]}`;
  }

  if (!thisWeekNextDay) {
    return `am ${weekdayNames[nextWeekNextDay.weekday]}`;
  }

  return `am ${weekdayNames[thisWeekNextDay.weekday]}`;
};

const weekdayNames = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

module.exports = getNextPulseCheckDay;
