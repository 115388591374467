import { ChatHintContainer, CommentHint, OpenHint } from "./styles";

import { Container } from "../styles";
import React from "react";
import { getCommentHintText } from "./getCommentHintText";

const ChatPlaceholder = ({ newCommentsAmount }) => {
  const commentHintText = getCommentHintText(newCommentsAmount);

  return (
    <Container>
      <ChatHintContainer>
        <CommentHint>{commentHintText}</CommentHint>
        <OpenHint>Wähle eine Nachricht aus, um den Chat zu starten.</OpenHint>
      </ChatHintContainer>
    </Container>
  );
};

export default ChatPlaceholder;
