import { AreaTitle, ItemContainer } from "./styles";

import AreaIcon from "../../../report/AreaIcon";
import React from "react";
import colors from "../../../common/colors";
import { useNavigate } from "react-router-dom";

const AreaMenuItems = ({ areaTitle, areaId, areaIdentifier, isCustom }) => {
  const navigate = useNavigate();

  return (
    <ItemContainer
      isCustom={isCustom}
      onClick={() => {
        navigate(`/areas/${areaId}/report`);
      }}
    >
      <AreaIcon type={areaIdentifier} stroke={colors.lavender} size={50} />
      <AreaTitle>{areaTitle}</AreaTitle>
    </ItemContainer>
  );
};

export default React.memo(AreaMenuItems);
