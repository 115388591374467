import { Dropdown } from "./styles";
import MenuItem from "../MenuItem";
import React from "react";
import getMenuItems from "../getMenuItems";
import { logout } from "../../../api/lightsApi";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useRole from "../../../hooks/useRole";

const MenuDropdown = ({ innerRef, closeMenu }) => {
  const { role: activeRole } = useRole();
  const { auth } = useAuth();
  const navigate = useNavigate();

  const isSurvee = auth?.role === "survee";

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  const onClick = (path) => {
    closeMenu();
    if (path === "login") {
      signOut();
    } else {
      navigate(path);
    }
  };

  const items = getMenuItems({ activeRole, isSurvee });

  return (
    <Dropdown ref={innerRef}>
      {items.map((item) => {
        return (
          <MenuItem
            key={item.title}
            title={item.title}
            subtitle={item.subtitle}
            onClick={() => onClick(item.navigateTo)}
            path={item.path}
          />
        );
      })}
    </Dropdown>
  );
};

export default MenuDropdown;
