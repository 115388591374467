import {
  BIRTHDAY_STEP_TITLE,
  DISABILITY_STEP_TITLE,
  ETHNICITY_STEP_TITLE,
  GENDER_STEP_TITLE,
  RELIGION_STEP_TITLE,
  SEXUALITY_STEP_TITLE,
  SOCIAL_BACKGROUND_STEP_TITLE,
} from "../../SurveeOnboarding/steps/stepTitles.js";
import { Container, DimensionContainer } from "./styles.js";
import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import OnboardingPageContainer from "../OnboardingPageContainer";
import Text from "../../../common/Text.js";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate.js";

const DimensionStep = ({ props }) => {
  const [loading, setLoading] = useState(true);
  const [dimensions, setDimensions] = useState([]);

  const { getAccountDimensions, setAccountDimensions } = useLightsApiPrivate();

  useEffect(() => {
    if (loading) {
      getAccountDimensions().then((data) => {
        setDimensions(data);
        setLoading(false);
      });
    }
  });

  const updateSelectedDimensions = async (dimension) => {
    const newDimensions = dimensions.includes(dimension)
      ? dimensions.filter((d) => d !== dimension)
      : [...dimensions, dimension];

    const index = newDimensions.indexOf(BIRTHDAY_STEP_TITLE);
    if (index > -1) {
      newDimensions.splice(index, 1);
      newDimensions.push(BIRTHDAY_STEP_TITLE);
    }

    setDimensions(newDimensions);

    await setAccountDimensions({ dimensions: newDimensions });
  };

  return (
    <OnboardingPageContainer
      props={{
        ...props,
        headline: "Welche Dimensionen willst du messen?",
        buttonLabel: "Weiter",
      }}
    >
      <Container>
        <Text>
          Die ausgewählten Dimensionen werden beim Onboarding der
          Pulse-Check-Teilnehmer*innen abgefragt.
        </Text>
        <DimensionContainer>
          {DIMENSIONS.map((dimension) => {
            const selected = dimensions.includes(dimension.id);
            const variant = selected ? "contained" : "outlined";
            return (
              <Button
                variant={variant}
                onClick={() => updateSelectedDimensions(dimension.id)}
              >
                {dimension.title}
              </Button>
            );
          })}
        </DimensionContainer>
      </Container>
    </OnboardingPageContainer>
  );
};

const DIMENSIONS = [
  { id: BIRTHDAY_STEP_TITLE, title: "Alter" },
  { id: DISABILITY_STEP_TITLE, title: "Behinderung" },
  { id: ETHNICITY_STEP_TITLE, title: "Ethnizität" },
  { id: GENDER_STEP_TITLE, title: "Geschlecht" },
  { id: RELIGION_STEP_TITLE, title: "Religion" },
  { id: SOCIAL_BACKGROUND_STEP_TITLE, title: "Soziale Herkunft" },
  { id: SEXUALITY_STEP_TITLE, title: "Sexuelle Orientierung" },
];

export default DimensionStep;
