import colors from "../../../common/colors.js";
import styled from "@emotion/styled";

export const ScheduleBody = styled("div")`
  display: grid;
  width: 80%;
  grid-template-columns: auto auto auto;
  align-items: center;
  row-gap: 10px;
  margin: 30px;
  justify-content: space-between;
`;

export const TextContainer = styled("div")`
  text-align: center;
  width: 50%;
`;

export const DropdownContainer = styled("div")`
  z-index: ${(props) => props.zIndex};
  position: relative;
  justify-content: flex-start;
  height: 55px;
`;

export const Text = styled("p")`
  color: ${colors.midnight};
`;
