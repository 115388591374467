import * as React from "react";

import { Container, Footer, StyledDataGrid } from "./styles";

import { Button } from "@mui/material";
import Heading1 from "../../../../../common/Heading1";
import { columns } from "./columns";

const ErrorPreviewTable = ({ previewData, setPreviewStep, closeModal }) => {
  const [data] = React.useState(previewData);

  const rows = data.map((row) => {
    return {
      id: row.csvIndex,
      lastName: row.lastName,
      firstName: row.firstName,
      mail: row.mail,
      teams: row.teams,
      error: row.error,
    };
  });

  if (rows.length === 0) {
    setPreviewStep("upload");
  }

  return (
    <Container>
      <Heading1>CSV-Vorschau</Heading1>
      <p>
        Deine CSV-Datei enthält Formatierungsfehler. Du kannst den Upload
        abbrechen und zu einem späteren Zeitpunkt durchführen oder die
        Formatierungsfehler in einer CSV exportieren und die restlichen Einträge
        hochladen.
      </p>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
      />
      <Footer>
        <Button onClick={closeModal} variant="warning">
          Abbrechen
        </Button>
        <Button onClick={() => setPreviewStep("export")} variant="contained">
          Export & Weiter
        </Button>
      </Footer>
    </Container>
  );
};

export default ErrorPreviewTable;
