import { Route, Routes } from "react-router-dom";

import AllRoutes from "./AllRoutes";
import React from "react";

function AppRouter() {
  return (
    <Routes>
      <Route path="/*" element={<AllRoutes />} />
    </Routes>
  );
}

export default AppRouter;
