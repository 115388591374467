import styled from "@emotion/styled";

export const FieldContainer = styled("div")`
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
  align-self: flex-start;
  margin-left: -30px;
`;

export const FieldColor = styled("div")`
  width: 5px;
  height: 10px;
  background-color: ${(props) => props.color};
`;

export const FieldName = styled("div")`
  display: flex;
  font-size: 11px;
  margin-left: 5px;
  text-align: left;
  width: 100px;
  word-wrap: break-word;
`;

export const FieldPercent = styled("i")`
  font-size: 12px;
  width: 60px;
  text-align: right;
`;
