import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const UserImportContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const UISection = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  &.m {
    width: 100%;
  }
`;

export const ImageSection = styled("div")`
  display: flex;
  width: 50%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: right;
  height: 100%;

  &.m {
    width: 0%;
  }
  &.s {
    width: 0%;
  }
`;

export const HeaderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 23%;
  width: 95%;
  align-items: center;
  padding: 20px 20px 0px 20px;
`;

export const ContentContainer = styled("div")`
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled("div")`
  display: flex;
  width: 90%;
  justify-content: space-between;
`;

export const HeadingContainer = styled("div")`
  display: flex;
  width: 50%;
  justify-content: center;
  text-align: center;
`;

export const ButtonContainer = styled("div")`
  margin-top: 40px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ContactLink = styled("a")`
  text-decoration: none;
  margin-bottom: 10px;
  color: ${colors.midnight};
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
`;

export const TextContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
