import { Container, OuterContainer } from "./styles";

import Heading1 from "../../common/Heading1";
import React from "react";
import colors from "../../common/colors";
import usePortalSize from "../../../hooks/usePortalSize";

const PulseCheckContainer = ({ children, showHeading = true }) => {
  const windowSize = usePortalSize();

  return (
    <OuterContainer className={windowSize}>
      {showHeading && (
        <Heading1 fontColor={colors.natural}>Pulse Check</Heading1>
      )}
      <Container>{children}</Container>
    </OuterContainer>
  );
};

export default PulseCheckContainer;
