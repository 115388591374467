import {
  EmptyTableCell,
  StyledAnswersLeftTableCell,
  StyledDivider,
  StyledScoreTableCell,
} from "./styles";

import LoadingSection from "../../../scores/ScoreCard/LoadingSection";
import { TableCell } from "@mui/material";
import Tooltip from "../../../common/Tooltip";
import colors from "../../../common/colors";

const ContrastTableCell = ({ children, ...props }) => {
  const { id, infoMessage, type, answersLeft, isVariance } = props;
  const sanitizedId = id.replace(/\n/g, " ").trim();

  if (infoMessage) {
    return (
      <EmptyTableCell data-tip data-for={sanitizedId}>
        <Tooltip id={sanitizedId} padding="400px">
          {infoMessage}
        </Tooltip>
        <StyledDivider color={colors.darkGrey} />
      </EmptyTableCell>
    );
  }

  switch (type) {
    case "score":
      return (
        <StyledScoreTableCell
          {...props}
          data-tip
          data-for={sanitizedId + "data"}
          isVariance={isVariance}
        >
          {children}
        </StyledScoreTableCell>
      );
    case "loading":
      return (
        <StyledAnswersLeftTableCell
          {...props}
          data-tip
          data-for={sanitizedId + "data"}
        >
          <LoadingSection
            width={20}
            stroke={colors.darkGrey}
            answersLeft={answersLeft}
            id={id}
            showQuestionMark={false}
          />
        </StyledAnswersLeftTableCell>
      );
    default:
      return <TableCell {...props}>{children}</TableCell>;
  }
};

export default ContrastTableCell;
