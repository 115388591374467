import {
  AdminInfoText,
  ButtonContainer,
  Container,
  InfoHeader,
  LinkText,
  Subheader,
} from "./styles";

import Button from "@mui/material/Button";
import Modal from "react-modal";
import React from "react";
import { getModalStyle } from "../../../common/modalStyle";
import usePortalSize from "../../../../hooks/usePortalSize";

const RedirectModal = ({ isOpen, onRequestClose, link, infoHeader }) => {
  const handleContinue = () => {
    window.open(link, "_blank");
    onRequestClose(false);
  };

  const size = usePortalSize();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={getModalStyle(size)}
      contentLabel="Redirect Modal"
    >
      <Container className={size}>
        {infoHeader && <InfoHeader>{infoHeader}</InfoHeader>}
        <Subheader>Du verlässt die Diverlyze App.</Subheader>
        <AdminInfoText>Der folgende Link wird geöffnet:</AdminInfoText>
        <LinkText>{link}</LinkText>
        <ButtonContainer>
          <Button variant="outlined" onClick={onRequestClose}>
            Abbrechen
          </Button>
          <Button variant="confirm" onClick={handleContinue}>
            Weiter
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default RedirectModal;
