import { Container, ImageContainer } from "./styles";

import React from "react";
import usePortalSize from "../../../hooks/usePortalSize";

function LoginPageContainer({ children }) {
  const size = usePortalSize();

  return (
    <Container>
      <ImageContainer className={size} />
      {children}
    </Container>
  );
}

export default React.memo(LoginPageContainer);
