import React from "react";
import colors from "./colors";
import styled from "@emotion/styled";

const Text = styled("p")`
  font-size: ${({ fontSize }) => fontSize || "14px"};
  color: ${colors.midnight};
  &.s {
    text-align: ${({ align }) => align || "center"};
  }
`;

export default React.memo(Text);
