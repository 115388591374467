import Dropdown from "../../../../../common/Dropdown";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import { genderOptions } from "../../../diversityOptions";

const GenderInput = ({ props }) => {
  const { employee, setEmployee, dynamicSize } = props;
  return (
    <FieldContainer>
      <Dropdown
        size={dynamicSize}
        value={employee.gender}
        label="Geschlecht"
        menuItems={genderOptions}
        onChange={({ target }) =>
          setEmployee({ ...employee, gender: target.value })
        }
      />
    </FieldContainer>
  );
};

export default GenderInput;
