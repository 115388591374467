import * as Components from "./styles";

import { ReactComponent as ArrowDownIcon } from "../../../../icons/trend_arrow_down_right.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../icons/trend_arrow_up_right.svg";
import { Flex } from "@react-spectrum/layout";
import React from "react";
import { computeTrendColor } from "./styles";

const ScoreSection = ({
  activity,
  mainScore,
  amountComments,
  avgRating,
  trendRatingCombined,
  isCustom,
  amountQuestions,
}) => {
  const commentText = amountComments === 1 ? "Kommentar" : "Kommentare";

  return (
    <Flex direction="column">
      <Components.Ratings>
        <Flex>
          <Flex direction="column" justifyContent="space-around">
            <Components.DiscrepancyHeader>
              Dein Ergebnis
            </Components.DiscrepancyHeader>
            <Components.Rating score={mainScore}>{mainScore}</Components.Rating>
          </Flex>
          {!isCustom && (
            <Flex direction="column">
              <Components.DiscrepancyHeader>
                Marktdurchschnitt
              </Components.DiscrepancyHeader>
              <Components.Rating>{avgRating}</Components.Rating>
            </Flex>
          )}
        </Flex>
        <Flex>
          <Components.DiscrepancyHeader>Trend</Components.DiscrepancyHeader>
          <Components.DiscrepancyHeader>
            {commentText}
          </Components.DiscrepancyHeader>
        </Flex>
        <Flex>
          <Components.FemTrendContainer>
            <Components.Trend trend={trendRatingCombined}>
              {trendRatingCombined}
            </Components.Trend>
            {trendRatingCombined !== "0.0" &&
              (trendRatingCombined > 0 ? (
                <ArrowUpIcon
                  width={13}
                  stroke={computeTrendColor(trendRatingCombined)}
                />
              ) : (
                <ArrowDownIcon
                  width={13}
                  stroke={computeTrendColor(trendRatingCombined)}
                />
              ))}
          </Components.FemTrendContainer>
          <Components.FemTrendContainer>
            {amountComments}
          </Components.FemTrendContainer>
        </Flex>
        <Flex>
          <Components.DiscrepancyHeader>
            Gestellte Fragen
          </Components.DiscrepancyHeader>
          <Components.DiscrepancyHeader>
            Antwortrate
          </Components.DiscrepancyHeader>
        </Flex>
        <Flex>
          <Components.Activity>{amountQuestions}</Components.Activity>
          <Components.Activity>{activity} %</Components.Activity>
        </Flex>
      </Components.Ratings>
    </Flex>
  );
};

export default React.memo(ScoreSection);
