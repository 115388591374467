import { Autocomplete, TextField } from "@mui/material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 590px;
  align-items: center;
`;

export const IconContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const CloseIcon = styled(CloseRoundedIcon)`
  cursor: pointer;
`;

export const TitleContainer = styled("div")`
  width: 90%;
`;

export const NameContainer = styled("div")`
  width: 90%;
`;

export const TeamLeadContainer = styled("div")`
  width: 90%;
`;

export const MemberContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

export const SaveContainer = styled("div")`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const StyledAutoComplete = styled(Autocomplete)`
  width: 300px;
`;

export const StyledTextField = styled(TextField)`
  width: 300px;
`;

export const AdminInfoText = styled("i")`
  margin-top: 10px;
  font-size: 12px;
`;

export const Title = styled("h3")`
  font-weight: 500;
`;

export const Subheader = styled("h4")`
  font-weight: 500;
`;
