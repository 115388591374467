import Dropdown from "../../../common/Dropdown";
import { ETHNICITY_STEP_TITLE } from "./stepTitles";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { ethnicityOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const EthnicityStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [ethnicities, setEthnicities] = React.useState(
    employee.ethnicities[0] === undefined
      ? ["not_applicable"]
      : employee.ethnicities
  );

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, ethnicities });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, ethnicities });
    handleNextStep();
  };

  const updateEthnicities = (ethnicities) => {
    if (ethnicities[0] === "not_applicable") {
      setEthnicities(ethnicities.slice(1));
    } else if (ethnicities.includes("not_applicable")) {
      setEthnicities(["not_applicable"]);
    } else {
      setEthnicities(ethnicities);
    }
  };

  props = {
    ...props,
    selected: ethnicities,
    step,
    saveAndNext,
    dimension: ETHNICITY_STEP_TITLE,
  };

  return (
    <OptionStepContainer props={props}>
      <Dropdown
        value={ethnicities ? ethnicities : ["not_applicable"]}
        label="Ethnische Herkunft"
        menuItems={ethnicityOptions}
        onChange={({ target }) => updateEthnicities(target.value)}
        multiple
        size="small"
      />
    </OptionStepContainer>
  );
};

export default EthnicityStep;
