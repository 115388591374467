import colors from "../colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  width: 85%;
  border: 1px solid ${colors.darkGrey};
  border-radius: 10px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: "translate(-50%, -50%)";
  text-align: center;
  top: 40%;
  position: absolute;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
`;
