import {
  ActionCardContainer,
  ButtonDiv,
  Description,
  DropdownIcon,
  Header,
  InactiveButton,
  Objective,
  StyledButton,
  Title,
  TitleContainer,
} from "./styles";

import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import ActionModal from "./ActionModal";
import React from "react";
import Tooltip from "../../../../common/Tooltip";

const ActionCard = ({ action }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isSupportRequested, setIsSupportRequested] = React.useState(
    action._doc.isSupportRequested
  );

  const closeModal = () => {
    setModalOpen(false);
  };

  const { title, description, objective, _doc: doc } = action;
  const { _id } = doc;

  const tooltipText = "Supportanfrage wurde bereits gesendet";

  return (
    <ActionCardContainer>
      <ActionModal
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        actionId={_id}
        setIsSupportRequested={setIsSupportRequested}
      />
      <Header>
        <TitleContainer>
          <Title>{title}</Title>
          <Objective>Ziel: {objective}</Objective>
        </TitleContainer>
        {isSupportRequested ? (
          <ButtonDiv data-tip data-for={_id}>
            <InactiveButton variant="contained" disabled>
              <AccessTimeFilledRoundedIcon />
            </InactiveButton>
            <Tooltip id={_id}>{tooltipText}</Tooltip>
          </ButtonDiv>
        ) : (
          <StyledButton variant="contained" onClick={() => setModalOpen(true)}>
            <DropdownIcon />
          </StyledButton>
        )}
      </Header>
      <Description>{description}</Description>
    </ActionCardContainer>
  );
};

export default ActionCard;
