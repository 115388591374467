import styled from "@emotion/styled";

const Heading = styled("h1")`
  left: 20px;
  font-weight: 500;
  font-size: 26px;
  color: ${(props) => props.fontColor};
`;

export default Heading;
