import { ButtonContainer, Text } from "./styles";

import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const LoginLink = ({ unansweredAmount }) => {
  const hasUnanswered = unansweredAmount > 0;

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login", {
      state: { redirectToPulse: hasUnanswered },
    });
  };

  if (!hasUnanswered) {
    return (
      <>
        <Text fontSize="16px">Es gibt keine ausstehenden Fragen.</Text>
        <Text fontSize="16px">
          Du kannst Dich aber einloggen, wenn Du Dein Profil bearbeiten
          möchtest.
        </Text>
        <ButtonContainer>
          <Button variant="confirm" onClick={handleLoginClick}>
            Zum Login
          </Button>
        </ButtonContainer>
      </>
    );
  }
  const amountText =
    unansweredAmount === 1 ? "unbeantwortete Frage" : `unbeantwortete Fragen`;

  const amountNumber = unansweredAmount === 1 ? "eine" : `${unansweredAmount}`;

  return (
    <>
      <Text>
        Du hast noch <b>{amountNumber}</b> {amountText}.
      </Text>
      <Text>Logge Dich ein, um sie zu beantworten.</Text>
      <ButtonContainer>
        <Button variant="confirm" onClick={handleLoginClick}>
          Zum Login
        </Button>
      </ButtonContainer>
    </>
  );
};

export default LoginLink;
