import NumberButton from "./NumberButton";
import { computeRatingColor } from "../../../styles/computeScoreColors";

export const RatingButtons = ({ rating, updateRating }) => {
  return Array.from({ length: 10 }, (_, k) => {
    const currentRating = k + 1;
    const active = `${rating}` === `${currentRating}`;
    const color = active
      ? computeRatingColor(currentRating)
      : rating
      ? "#000000"
      : computeRatingColor(currentRating);

    return (
      <NumberButton
        key={rating + k}
        rating={++k}
        active={active}
        setColor={color}
        onClick={() => {
          if (active) {
            updateRating(null);
          } else {
            updateRating(currentRating);
          }
        }}
      />
    );
  });
};
