import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import NavIcon from "../NavIcon";
import areaItems from "../areaItems";
import colors from "../../common/colors";
import getMenuItems from "../getMenuItems";
import { logout } from "../../../api/lightsApi";
import useAuth from "../../../hooks/useAuth";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import useRole from "../../../hooks/useRole";

const MenuDropdownMobile = ({ innerRef }) => {
  const { role: activeRole } = useRole();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSurvee = auth?.role === "survee";

  const [open, setOpen] = useState(false);
  const [lockDialog, setLockDialog] = useState(true);
  const { getAccount } = useLightsApiPrivate();

  const menuItems = getMenuItems({ activeRole, isSurvee });
  const areaItems = getAreaItems({ activeRole, lockDialog });

  useEffect(() => {
    getAccount().then((data) => {
      if (data.isDialogActive) setLockDialog(false);
    });
  }, [getAccount]);

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  const onClick = (path) => {
    if (path === "login") {
      signOut();
    } else {
      navigate(path);
    }
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {areaItems.map((item) => (
          <ListItem
            key={item.title}
            disablePadding
            onClick={() => onClick(item.navigateTo)}
          >
            <ListItemButton selected={pathname === item.path}>
              <ListItemIcon>
                <NavIcon path={item.navigateTo} />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem
            key={item.title}
            disablePadding
            onClick={() => onClick(item.navigateTo)}
          >
            <ListItemButton selected={item.navigateTo === pathname}>
              <ListItemText primary={item.title} secondary={item.subtitle} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        sx={{
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <MenuIcon
          fontSize="large"
          sx={{
            padding: "8px 10px",
            color: colors.midnight,
          }}
        />
      </IconButton>
      <Drawer
        ref={innerRef}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
};

function getAreaItems({ activeRole, lockDialog }) {
  if (activeRole === "survee") {
    if (lockDialog) {
      return [areaItems.survee[0]];
    }
    return areaItems.survee;
  }
  return areaItems.admin;
}

export default MenuDropdownMobile;
