import {
  AreasTitleCustom,
  AreasTitleDiverlyze,
  LeftScoreCard,
  RightScoreCard,
  ScoreGrid,
  ScoreGridContainer,
} from "./styles";

import ScoreCard from "./ScoreCard";

export const ScoreList = ({ scoreList }) => {
  const customScores = scoreList.filter((score) => score.isCustom);
  const regularScores = scoreList.filter((score) => !score.isCustom);

  return (
    <ScoreGridContainer>
      {customScores.length > 0 && (
        <AreasTitleDiverlyze>Diverlyze Bereiche</AreasTitleDiverlyze>
      )}
      <ScoreGrid>
        {regularScores.map((score, index) => {
          if (index % 2 === 0) {
            return (
              <LeftScoreCard>
                <ScoreCard key={index} score={score} />
              </LeftScoreCard>
            );
          } else {
            return (
              <RightScoreCard>
                <ScoreCard key={index} score={score} />
              </RightScoreCard>
            );
          }
        })}
      </ScoreGrid>
      {customScores.length > 0 && (
        <AreasTitleCustom>Custom Bereiche</AreasTitleCustom>
      )}
      <ScoreGrid>
        {customScores.map((score, index) =>
          index % 2 === 0 ? (
            <LeftScoreCard key={index}>
              <ScoreCard score={score} />
            </LeftScoreCard>
          ) : (
            <RightScoreCard key={index}>
              <ScoreCard score={score} />
            </RightScoreCard>
          )
        )}
      </ScoreGrid>
    </ScoreGridContainer>
  );
};
