import React, { useEffect, useState } from "react";

import ActionArea from "./ActionArea";
import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import EmployeesTable from "./EmployeesTable";
import Heading1 from "../../common/Heading1";
import LoadingAnimation from "../../common/LoadingAnimation";
import colors from "../../common/colors";
import compare from "../../../utils/sort";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";

const UsersPage = () => {
  const { getEmployees } = useLightsApiPrivate();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (loading) {
      getEmployees().then((data) => {
        const preparedEmployees = prepareEmployeeData(data);
        setEmployees(preparedEmployees);
        setLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareEmployeeData = (e) => {
    const newList = e.map((employee) => {
      return {
        id: employee._id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        mail: employee.mail,
        role: employee.role,
        department: employee.department,
        activity: employee.isActive,
        status: employee.status,
      };
    });
    return newList.sort(compare);
  };

  const updateEmployeeList = async () => {
    const data = await getEmployees();
    const preparedEmployees = prepareEmployeeData(data);
    setEmployees(preparedEmployees);
  };

  const filteredEmployees = employees.filter((emp) =>
    `${emp.firstName} ${emp.lastName} ${emp.mail} `
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handleSelectAllClick = (event) => {
    const currentPageRows = filteredEmployees.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );

    if (event.target.checked) {
      const newSelecteds = currentPageRows.map((row) => row.id);
      const updatedSelected = [
        ...selected,
        ...newSelecteds.filter((id) => !selected.includes(id)),
      ];
      setSelected(updatedSelected);
    } else {
      const updatedSelected = selected.filter(
        (id) => !currentPageRows.some((row) => row.id === id)
      );
      setSelected(updatedSelected);
    }
  };

  const currentPageRows = filteredEmployees.slice(
    paginationModel.page * paginationModel.pageSize,
    (paginationModel.page + 1) * paginationModel.pageSize
  );

  const allChecked =
    currentPageRows.length > 0 &&
    currentPageRows.every((row) => selected.includes(row.id));
  const someChecked =
    currentPageRows.some((row) => selected.includes(row.id)) && !allChecked;

  const handleSelectClick = (event, id) => {
    const isSelected = selected.includes(id);
    const updatedSelected = isSelected
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(updatedSelected);
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Mitglieder</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.TeamsSection}>
        <ActionArea
          employees={employees}
          setSelected={setSelected}
          updateEmployeeList={updateEmployeeList}
          setEmployees={setEmployees}
          setSearchQuery={setSearchQuery}
          selected={selected}
          handleSelectAllClick={handleSelectAllClick}
          handleSelectClick={handleSelectClick}
          someChecked={someChecked}
          allChecked={allChecked}
        />
        <EmployeesTable
          employees={filteredEmployees}
          selected={selected}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          handleSelectedAllClick={handleSelectAllClick}
          handleSelectClick={handleSelectClick}
        />
      </AreaSection>
    </>
  );
};

export default UsersPage;

const sectionContent = {
  TeamsSection: {
    id: "teams",
    title: "Deine Mitglieder",
    infoText:
      "Hier kannst Du alle Teammitglieder und deren Rechte im Detail anschauen und verwalten.",
    hoverText:
      "Die Teammitglieder wurden beim Onboarding durch das Hochladen der CSV importiert. Du kannst sie hier bearbeiten, löschen oder neue hinzufügen.",
  },
};
