import styled from "@emotion/styled";

export const QuestionGrid = styled("div")`
  display: grid;
  width: 90%;
  row-gap: 20px;
  grid-template-columns: auto auto;
  column-gap: max;
`;

export const QuestionGridContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LeftQuestionCard = styled("div")`
  justify-self: start;
`;

export const RightQuestionCard = styled("div")`
  justify-self: end;
`;
