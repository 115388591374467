import { getRefresh } from "../api/lightsApi";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const { accessToken, role, id, userId } = await getRefresh();
    setAuth((prev) => {
      return { ...prev, accessToken, role, id, userId };
    });
    return accessToken;
  };

  return refresh;
};

export default useRefreshToken;
