import { Button } from "@mui/material";
import colors from "../../../../common/colors";
import contact_mail from "../../../../../assets/images/contact_mail.png";
import styled from "@emotion/styled";

export const ActionCardContainer = styled("div")`
  display: flex;
  flex-direction: column;
  border: 2px solid ${colors.grey};
  border-radius: 10px;
  background-color: ${colors.lightGrey};
  padding: 20px;
  display: flex;
  width: 500px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled("h3")`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
`;

export const Objective = styled("i")`
  margin: 2px;
  font-size: 12px;
`;

export const DropdownIcon = styled("div")`
  background-image: url(${contact_mail});
  background-size: cover;
  width: 25px;
  height: 25px;
`;

export const StyledButton = styled(Button)`
  align-self: flex-start;
  width: 35px;
  height: 35px;
`;

export const InactiveButton = styled(Button)`
  align-self: flex-start;
  width: 35px;
  height: 35px;
  cursor: none;
`;

export const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-start;
`;

export const TitleContainer = styled("div")``;

export const Description = styled("p")`
  font-size: 13px;
  margin-bottom: 0px;
`;
