import { Container } from "./styles";
import MessageCard from "./MessageCard";
import React from "react";

const MessageSection = ({
  dialogs,
  openDialog,
  isChatLoading,
  selectedDialog,
}) => {
  dialogs.sort(function (a, b) {
    return (
      new Date(b.latestMessage.createdAt) - new Date(a.latestMessage.createdAt)
    );
  });

  return (
    <Container>
      {dialogs.map((dialog) => (
        <MessageCard
          key={dialog.id}
          dialog={dialog}
          isChatLoading={isChatLoading}
          openDialog={openDialog}
          selectedDialog={selectedDialog}
        ></MessageCard>
      ))}
    </Container>
  );
};

export default MessageSection;
