import LoadingAnimation from "../../common/LoadingAnimation";
import PulseQuestion from "./PulseQuestion";
import PulseSkip from "./PulseSkip";
import PulseThanks from "./PulseThanks";
import React from "react";

const PulseCheck = ({ askedQuestions, setAskedQuestions, fact }) => {
  const [view, setView] = React.useState("question");

  switch (view) {
    case "question":
      return (
        <PulseQuestion
          askedQuestions={askedQuestions}
          setView={setView}
          setAskedQuestions={setAskedQuestions}
        ></PulseQuestion>
      );
    case "skip":
      return (
        <PulseSkip
          askedQuestions={askedQuestions}
          setAskedQuestions={setAskedQuestions}
          setView={setView}
        ></PulseSkip>
      );
    case "thanks":
      return (
        <PulseThanks
          setView={setView}
          setAskedQuestions={setAskedQuestions}
          fact={fact}
        ></PulseThanks>
      );
    default:
      return <LoadingAnimation />;
  }
};

export default PulseCheck;
