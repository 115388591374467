import Button from "@mui/material/Button";
import colors from "../../common/colors";
import styled from "@emotion/styled";

export const Actions = styled("button")`
  background: #f4f4f4;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 0;
  text-decoration: underline dotted;
  font-size: 13px;
`;

export const Buttons = styled("div")`
  font-size: 14px;
`;

export const Card = styled("div")`
  align-items: center;
  background: ${({ isCustom }) =>
    isCustom ? colors.lavenderLight : colors.lightGrey};
  border: solid;
  border-color: ${({ isCustom }) =>
    isCustom ? colors.lavender : colors.darkGrey};
  border-radius: 10px;
  display: flex;
  height: 241px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  width: 511px;
`;

export const Data = styled("div")`
  margin-top: 10px;
`;

export const Container = styled("div")`
  font-size: 13px;
`;

export const SectionContainer = styled("div")`
  width: 50%;
  margin-left: 20px;
  font-size: 13px;
  margin-right: 5px;
  margin-top: ${({ loading }) => (loading ? "-6%" : "0")};
`;
export const LoadingContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
  width: 40%;
  z-index: 2;
  top: 20px;
  margin-left: ${({ isCustom }) => (isCustom ? "12%" : "2%")};
  margin-top: 1%;
  position: absolute;
`;

export const ActionHint = styled("p")`
  margin-top: 10px;
  font-size: 14px;
  text-decoration: ${({ isCustom }) =>
    isCustom ? "none" : "underline dotted"};
  cursor: pointer;
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  width: 205px;
  max-height: 28px;
  font-size: 13px;
  border-color: ${({ isCustom }) =>
    isCustom ? colors.lavender : colors.darkGrey};
`;
