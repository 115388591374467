import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import DownloadIcon from "@mui/icons-material/Download";
import colors from "../../../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDownloadIcon = styled(DownloadIcon)`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.2s;
  color: ${colors.lavender};
  &:hover {
    transform: scale(1.5);
  }
`;

export const StyledDownloadDoneIcon = styled(DownloadDoneIcon)`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  color: ${colors.lavender};
`;
