import {
  AnswerContainer,
  Comment,
  Container,
  DateContainer,
  HeaderContainer,
  NewMessageHint,
  NewMessageHintContainer,
  QuestionContainer,
  Rating,
} from "./styles";

import React from "react";
import { getGermanFormat } from "../../../../utils/date";

const MessageCard = ({ dialog, openDialog, isChatLoading, selectedDialog }) => {
  const { rating, question, latestMessage, hasNewMessage } = dialog;

  const { message, senderName, createdAt } = latestMessage;

  const createdAtDate = new Date(createdAt);
  const date = `${getGermanFormat(
    createdAtDate
  )} ${createdAtDate.getFullYear()}`;

  const isSelected = dialog?.id === selectedDialog?.id;

  return (
    <Container
      onClick={() => !isChatLoading && openDialog(dialog)}
      isSelected={isSelected}
    >
      <HeaderContainer>
        <QuestionContainer>{question}</QuestionContainer>
        <DateContainer bold={hasNewMessage}>{date}</DateContainer>
        <NewMessageHintContainer>
          {hasNewMessage && <NewMessageHint />}
        </NewMessageHintContainer>
      </HeaderContainer>
      <AnswerContainer>
        <Rating rating={rating}>{rating}</Rating>
        <span>{senderName}:</span>
        <Comment>&nbsp;"{message}</Comment>
        <span>"</span>
      </AnswerContainer>
    </Container>
  );
};

export default MessageCard;
