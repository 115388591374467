import colors from "../../common/colors";
import styled from "@emotion/styled";

export const LinkContainer = styled("div")`
  font-weight: 500;
  margin-top: 14px;
`;

export const SubHeader = styled("div")`
  color: ${colors.darkGrey};
  font-weight: 500;
`;
