import Dropdown from "../../../common/Dropdown";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import { SEXUALITY_STEP_TITLE } from "./stepTitles";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { sexualityOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const SexualityStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [sexuality, setSexuality] = React.useState(employee.sexuality);

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, sexuality });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, sexuality });
    handleNextStep();
  };

  props = {
    ...props,
    selected: sexuality,
    step,
    saveAndNext,
    dimension: SEXUALITY_STEP_TITLE,
  };

  return (
    <OptionStepContainer props={props}>
      <Dropdown
        value={sexuality ? sexuality : "not_applicable"}
        label="Sexuelle Orientierung"
        menuItems={sexualityOptions}
        onChange={({ target }) => setSexuality(target.value)}
        size="small"
      />
    </OptionStepContainer>
  );
};

export default SexualityStep;
