import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const computeRatingColor = (score) => {
  switch (true) {
    case parseFloat(score) > 7.5:
      return colors.green;
    case parseFloat(score) > 5.0:
      return colors.orange;
    default:
      return colors.red;
  }
};

export const computeTrendColor = (trend) => {
  switch (true) {
    case parseFloat(trend) < 0:
      return `${colors.red}`;
    case parseFloat(trend) > 0:
      return `${colors.green}`;
    default:
      return `${colors.midnight}`;
  }
};

export const Rating = styled("div")`
  color: ${({ score }) =>
    score ? computeRatingColor(score) : colors.darkGrey};
  font-size: 58px;
  font-weight: 500;
  width: 120px;
`;

export const Trend = styled("div")`
  color: ${({ trend }) => (trend ? computeTrendColor(trend) : colors.darkGrey)};
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
`;

export const DiscrepancyHeader = styled("div")`
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 120px;
  font-size: 13px;
`;

export const ActivityHeader = styled("div")`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 50%;
`;

export const Activity = styled("div")`
  font-weight: 500;
  font-size: 13px;
  width: 50%;
`;

export const Ratings = styled("div")`
  margin-bottom: 10px;
  font-size: 14px;
`;

export const Stats = styled("div")`
  margin-bottom: 10px;
  font-size: 14px;

  margin-top: 10px;
`;
export const FemTrendContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  height: 15px;
  width: 120px;
  align-items: center;
  font-weight: 500;
`;

export const MaleTrendContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  height: 15px;
  align-items: center;
`;
