import {
  LeftQuestionCard,
  MessageContainer,
  MissingActionsInfo,
  QuestionGrid,
  QuestionGridContainer,
  RightQuestionCard,
} from "./styles";

import ActionCard from "./ActionCard";
import React from "react";

export const ActionList = ({ actions, actionsRef }) => {
  if (actions.length === 0) {
    return (
      <MessageContainer>
        <MissingActionsInfo>
          Für diesen Bereich sind noch nicht genügend Antworten eingegangen, um
          Maßnahmen zu ermitteln.
        </MissingActionsInfo>
      </MessageContainer>
    );
  }

  return (
    <QuestionGridContainer ref={actionsRef}>
      <QuestionGrid>
        {actions.map((action, index) => {
          if (index % 2 === 0) {
            return (
              <LeftQuestionCard>
                <ActionCard action={action} />
              </LeftQuestionCard>
            );
          } else {
            return (
              <RightQuestionCard>
                <ActionCard action={action} />
              </RightQuestionCard>
            );
          }
        })}
      </QuestionGrid>
    </QuestionGridContainer>
  );
};

export default ActionList;
