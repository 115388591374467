import styled from "@emotion/styled";

export const DimensionContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 600px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  text-align: center;
`;
