import {
  ButtonContainer,
  ContactLink,
  ContentContainer,
  Header,
  HeaderContainer,
  HeadingContainer,
  ImageSection,
  TextContainer,
  UISection,
  UserImportContainer,
} from "./styles";

import BackButton from "../../../settings/BackButton";
import { Button } from "@mui/material";
import Heading1 from "../../../common/Heading1";
import React from "react";
import Text from "../../../common/Text";
import usePortalSize from "../../../../hooks/usePortalSize";

const OnboardingPageContainer = ({ props, children }) => {
  const {
    headline,
    step,
    handleNextStep,
    handlePrevStep,
    img,
    buttonLabel,
    buttonActive = true,
  } = props;

  const size = usePortalSize();
  const showHeaderNav = step !== 0;
  const showNextButton = step !== 0;

  const displayStep = step > 5 ? 5 : step;

  return (
    <UserImportContainer>
      <UISection className={size}>
        <HeaderContainer>
          <HeaderContainer>
            {showHeaderNav && (
              <Header>
                <BackButton
                  variant="outlined"
                  onClick={() => handlePrevStep()}
                />
                <TextContainer>
                  <Text>Schritt {displayStep} / 5</Text>
                </TextContainer>
              </Header>
            )}
          </HeaderContainer>
          <HeadingContainer>
            <Heading1>{headline}</Heading1>
          </HeadingContainer>
        </HeaderContainer>
        <ContentContainer>
          {children}
          {showNextButton && (
            <ButtonContainer>
              <Button
                variant={buttonLabel === "Weiter" ? "outlined" : "neutral"}
                onClick={handleNextStep}
                disabled={!buttonActive}
              >
                {buttonLabel}
              </Button>
            </ButtonContainer>
          )}
        </ContentContainer>
        <ContactLink href="mailto:info@diverlyze.com">
          Bei Problemen oder Fragen wende Dich bitte an support@diverlyze.com
        </ContactLink>
      </UISection>
      <ImageSection className={size} img={img}></ImageSection>
    </UserImportContainer>
  );
};

export default OnboardingPageContainer;
