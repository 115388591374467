import styled from "@emotion/styled";

export const Button = styled("div")`
  margin: 2px 4px;
  border: 2px solid ${(props) => props.color};
  border-radius: 5px;
  width: 25px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;

  &.m {
    margin: 2px 4px;
  }
  &.s {
    margin: 2px 2px;
  }
`;

export const ButtonText = styled("div")`
  color: "black";
`;
