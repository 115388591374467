import { Checkcontainer, Container, FactContainer } from "./styles";

import ConfirmationMessage from "./ConfirmationMessage";
import FactAlert from "../../../PulseCheckPage/FactAlert";
import LoginLink from "./LoginLink";
import React from "react";
import check_gif from "../../../../assets/animations/check.gif";

const MailPulseThanks = ({ unansweredAmount, isAlreadyAnswered, fact }) => {
  const notCachedGif = check_gif + "?" + performance.now();

  return (
    <Container>
      <ConfirmationMessage isAlreadyAnswered={isAlreadyAnswered} />
      <Checkcontainer src={notCachedGif}></Checkcontainer>
      <LoginLink unansweredAmount={unansweredAmount} />
      <FactContainer>
        <FactAlert fact={fact} />
      </FactContainer>
    </Container>
  );
};

export default MailPulseThanks;
