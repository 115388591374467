import * as React from "react";

import { Container, Footer, StyledDataGrid } from "./styles";

import { Button } from "@mui/material";
import Heading1 from "../../../../../common/Heading1";
import { columns } from "./columns";

const PreviewTable = ({ previewData, confirmUpload, closeModal }) => {
  const rows = previewData.map((row, index) => {
    return {
      id: index,
      lastName: row.lastName,
      firstName: row.firstName,
      mail: row.mail,
      teams: row.teams,
    };
  });

  const isDataEmpty = previewData.length === 0;

  const buttonText = isDataEmpty ? "Schließen" : "Hochladen";
  const subHeader = isDataEmpty
    ? "Es gibt keine Einträge die hochgeladen werden können."
    : "Die folgenden Einträge werden in das Tool importiert:";

  return (
    <Container>
      <Heading1>CSV-Vorschau</Heading1>
      <p>{subHeader}</p>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
      />
      <Footer>
        {isDataEmpty ? (
          <Button onClick={closeModal} variant="confirm">
            {buttonText}
          </Button>
        ) : (
          <Button onClick={confirmUpload} variant="confirm">
            {buttonText}
          </Button>
        )}
      </Footer>
    </Container>
  );
};

export default PreviewTable;
