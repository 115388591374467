import React from "react";
import { SearchUserInput } from "./styles";

const SearchUser = ({ setSearchQuery }) => {
  return (
    <SearchUserInput
      label="Suche"
      id="standard-basic"
      onChange={(e) => setSearchQuery(e.target.value)}
      variant="standard"
    />
  );
};

export default SearchUser;
