import * as Sentry from "@sentry/react";

import {
  ButtonContainer,
  FormContainer,
  HintContainer,
  InputContainer,
  LoginContainer,
  Logo,
  StyledButton,
  StyledTextField,
  SubHeader,
} from "./styles";
import React, { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Heading1 from "../../common/Heading1";
import Link from "../../common/Link";
import LoginPageContainer from "../LoginPageContainer";
import colors from "../../common/colors";
import { login } from "../../../api/lightsApi";
import logo from "../../../assets/images/diverlyze_logo.png";
import useAuth from "../../../hooks/useAuth";
import usePortalSize from "../../../hooks/usePortalSize";
import useRole from "../../../hooks/useRole";
import { validateLogin } from "../formValidation";

const LoginPage = () => {
  const size = usePortalSize();
  const { setAuth } = useAuth();
  const { setRole } = useRole();

  const navigate = useNavigate();
  const location = useLocation();
  const errRef = useRef();

  const [userName, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(true);

  useEffect(() => {
    setErrMsg("");
  }, [userName, pwd]);

  const selectAll = (e) => {
    e.target.select();
  };

  const handleSubmit = async (e) => {
    setIsButtonActive(false);
    e.preventDefault();
    // TODO validate form

    const { error } = validateLogin({ userName, password: pwd });
    if (error) {
      setErrMsg(error.details[0].message);
      setIsButtonActive(true);

      return;
    }

    try {
      const response = await login({ userName, pwd });

      if (response.accessToken) {
        const accessToken = response?.accessToken;
        const role = response?.role;
        const id = response?.id;
        const userId = response?.userId;

        setAuth({ role, accessToken, id, userId });
        setRole(role);
        setIsButtonActive(true);

        navigate("/settings", { state: { ...location.state } });
      } else {
        setAuth({ userId: userName });
        setUsername("");
        setPwd("");

        navigate("/validate_2fa", { state: { ...location.state } });
      }
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        setErrMsg("Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        setErrMsg("Bitte fülle alle Felder aus.");
      } else if (err.response?.status === 401) {
        setErrMsg("Falsches Passwort.");
      } else if (err.response?.status === 404) {
        setErrMsg("Diese Mail ist noch nicht registriert.");
      } else if (err.response?.status === 403) {
        setErrMsg("Dein Account wurde deaktiviert.");
      } else {
        setErrMsg(
          "Login fehlgeschlagen. Versuche es zu einem späteren Zeitpunkt erneut."
        );
      }
      setIsButtonActive(true);
    }
  };

  return (
    <LoginPageContainer>
      <LoginContainer className={size}>
        <Logo className={size} src={logo} />
        <FormContainer className={size}>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <Heading1>Login </Heading1>
          <SubHeader>Gib Deine Daten unten ein.</SubHeader>
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <StyledTextField
                size="small"
                label="E-Mail | Benutzername"
                type="text"
                id="mail"
                autoComplete="username"
                onChange={({ target }) => setUsername(target.value)}
                value={userName}
                InputLabelProps={{ required: false, shrink: true }}
                required
              ></StyledTextField>
              <StyledTextField
                onClick={selectAll}
                size="small"
                label="Passwort"
                type="password"
                autoComplete="current-password"
                InputLabelProps={{ required: false, shrink: true }}
                id="password"
                onChange={({ target }) => setPwd(target.value)}
                value={pwd}
                required
              ></StyledTextField>
            </InputContainer>
            <ButtonContainer>
              <StyledButton
                variant="contained"
                type="submit"
                disabled={!isButtonActive}
              >
                Einloggen
              </StyledButton>
            </ButtonContainer>
            <HintContainer>
              <Link color={`${colors.midnight}`} href={`/forgot-password`}>
                Passwort vergessen
              </Link>
            </HintContainer>
          </form>
        </FormContainer>
      </LoginContainer>
    </LoginPageContainer>
  );
};

export default memo(LoginPage);
