import styled from "@emotion/styled";

export const Container = styled("div")`
  width: 98%;
`;

export const ActionContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
`;

export const ActionAlertDropdown = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: space-between;
  margin-bottom: 10px;
  max-height: 50px;
  width: 45%;
  min-width: 570px;
`;
