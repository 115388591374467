import styled from "@emotion/styled";

export const FactText = styled("div")`
  display: flex;
  height: 60%;
  text-align: left;
  justify-content: flex-start;
  align-items: start;
  margin: 20px 20px 0px 20px;
  font-size: 18px;

  &.s {
    margin: 20px;
  }
`;

export const FactSourceLink = styled("div")`
  display: flex;
  height: 30%;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;

  &.s {
    margin-bottom: 10px;
  }
`;
