import { LoadingContainer, ModalButtonContainer } from "./styles";

import { Button } from "@mui/material";
import Heading2 from "../../../../../common/Heading2";
import LoadingAnimation from "../../../../../common/LoadingAnimation";
import Modal from "react-modal";
import React from "react";
import { getModalStyle } from "../../../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../../hooks/useLightsApiPrivate";

const DeleteModal = ({
  selected,
  setSelected,
  isModalOpen,
  closeModal,
  updateEmployeeList,
}) => {
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const { deleteEmployees } = useLightsApiPrivate();
  const alert = useAlert();

  const deleteSelectedEmployees = async () => {
    setDeleteLoading(true);
    try {
      await deleteEmployees(selected);
      const alertText =
        selected.length > 1 ? "Mitglieder gelöscht" : "Mitglied gelöscht";
      alert.success(alertText);
      setSelected([]);
      setDeleteLoading(false);
    } catch (e) {
      alert.error("Es ist ein Fehler aufgetreten");
      setDeleteLoading(false);
    }
  };

  const confirmModal = async () => {
    await deleteSelectedEmployees();
    updateEmployeeList();
    closeModal();
  };

  const displayedText =
    selected.length > 1
      ? `${selected.length} ausgewählte Mitglieder`
      : `das ausgewählte Mitglied`;

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={getModalStyle()}
      contentLabel="Deletion"
    >
      {deleteLoading && (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      )}
      <Heading2>Mitglieder löschen</Heading2>
      <p>
        Bist Du sicher, dass Du {displayedText} unwiderruflich löschen möchtest?{" "}
      </p>
      <ModalButtonContainer>
        <Button onClick={closeModal} variant="outlined">
          Abbrechen
        </Button>
        <Button onClick={confirmModal} variant="warning">
          Löschen
        </Button>
      </ModalButtonContainer>
    </Modal>
  );
};

export default DeleteModal;
