import styled from "@emotion/styled";

export const ChatHintContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
`;

export const OpenHint = styled("p")`
  font-weight: 500;
  font-style: italic;
`;

export const CommentHint = styled("p")`
  font-style: italic;
`;
