import { ContentContainer, Logo, LogoContainer } from "./styles";
import React, { useEffect, useState } from "react";
import {
  getEngagementFact,
  getQuestionByAskedQuestion,
  getUnansweredQuestionsAmount,
} from "../../api/lightsApi";
import { useNavigate, useParams } from "react-router-dom";

import LoadingAnimation from "../common/LoadingAnimation";
import MailPulseCheck from "./MailPulseCheck";
import PulseCheckContainer from "../PulseCheckPage/PulseCheckContainer";
import backUpFact from "../PulseCheckPage/backUpFact";
import logo from "../../assets/images/diverlyze_logo.png";
import usePortalSize from "../../hooks/usePortalSize";

const MailPulseCheckPage = () => {
  const [question, setQuestion] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(null);
  const [unansweredAmount, setUnansweredAmount] = useState(0);
  const [fact, setFact] = useState(null);

  const navigate = useNavigate();

  const { id: askedQuestionId } = useParams("id");
  const windowSize = usePortalSize();

  useEffect(() => {
    getQuestionByAskedQuestion(askedQuestionId)
      .then((res) => {
        setQuestion(res);
      })
      .catch((err) => {
        console.error(err);
        navigate("/404");
      });

    getUnansweredQuestionsAmount(askedQuestionId)
      .then((res) => {
        setUnansweredAmount(res.amount);
        setHasLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        navigate("/404");
      });

    getEngagementFact()
      .then((data) => {
        setFact(data.fact);
      })
      .catch((err) => {
        console.error("EError fetching engagement fact:", err);
        setFact(backUpFact);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasLoaded) {
    return (
      <ContentContainer>
        <LoadingAnimation />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <LogoContainer>
        <Logo src={logo} className={windowSize} />
      </LogoContainer>
      <PulseCheckContainer showHeading={false}>
        <MailPulseCheck
          fact={fact}
          question={question}
          isAlreadyAnswered={question.isAlreadyAnswered}
          askedQuestionId={askedQuestionId}
          unansweredAmount={unansweredAmount}
          setUnansweredAmount={setUnansweredAmount}
        />
      </PulseCheckContainer>
    </ContentContainer>
  );
};

export default MailPulseCheckPage;
