export const timeStrings = [
  "kein Survey",
  "1:00 Uhr",
  "2:00 Uhr",
  "3:00 Uhr",
  "4:00 Uhr",
  "5:00 Uhr",
  "6:00 Uhr",
  "7:00 Uhr",
  "8:00 Uhr",
  "9:00 Uhr",
  "10:00 Uhr",
  "11:00 Uhr",
  "12:00 Uhr",
  "13:00 Uhr",
  "14:00 Uhr",
  "15:00 Uhr",
  "16:00 Uhr",
  "17:00 Uhr",
  "18:00 Uhr",
  "19:00 Uhr",
  "20:00 Uhr",
  "21:00 Uhr",
  "22:00 Uhr",
  "23:00 Uhr",
];

export const dayTimes = [
  null,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];
