import {
  Container,
  StyledDownloadDoneIcon,
  StyledDownloadIcon,
} from "./styles";

import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import Heading1 from "../../../../../common/Heading1";
import React from "react";
import { csvHeaders } from "./csvHeaders";

const CsvExport = ({ errorPreviewData, setPreviewStep }) => {
  const [hasDownloaded, setHasDownloaded] = React.useState(false);

  const csvLink = React.useRef();
  const csvData = errorPreviewData.map((employee) => ({
    firstName: employee.firstName,
    lastName: employee.lastName,
    mail: employee.mail,
    teams: employee.teams,
    error: employee.error,
    csvIndex: employee.csvIndex,
  }));

  const initiateDownload = () => {
    setHasDownloaded(true);
    csvLink.current.link.click();
  };

  return (
    <Container>
      <Heading1>Exportiere die Formatierungsfehler als CSV-Datei.</Heading1>
      {hasDownloaded ? (
        <StyledDownloadDoneIcon />
      ) : (
        <StyledDownloadIcon onClick={initiateDownload} />
      )}
      <CSVLink
        data={csvData}
        headers={csvHeaders}
        filename={"Diverlyze_Formatierungsfehler_CSV_Upload.csv"}
        className="hidden"
        target="_blank"
        ref={csvLink}
      ></CSVLink>
      <Button
        onClick={() => setPreviewStep("upload")}
        variant="contained"
        disabled={!hasDownloaded}
      >
        Zum Upload
      </Button>
    </Container>
  );
};

export default CsvExport;
