import styled from "@emotion/styled";

//TODO: find better way to prevent shifting of active divs. letter-spacing hack underneath
export const ActiveMenuItem = styled("div")`
  font-weight: 500;
  letter-spacing: -0.55px;
`;

export const InactiveMenuItem = styled("div")``;

export const Container = styled("div")`
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
`;

export const IconContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
  align-self: center;
`;
