import { Divider, TableCell } from "@mui/material";
import {
  computeContrastRatingColor,
  computeVarianceColor,
} from "../../../../styles/computeScoreColors";

import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const StyledScoreTableCell = styled(TableCell)`
  font-size: 16px;
  text-align: center;
  color: ${colors.midnight};
  background-color: ${({ rating, isVariance }) =>
    isVariance
      ? computeVarianceColor(rating)
      : computeContrastRatingColor(rating)};
  font-weight: ${({ isVariance }) => (isVariance ? "500" : "normal")};
  border-right: ${({ isVariance }) =>
    isVariance ? `1px solid ${colors.lightGrey}` : "0"};
  font-family: Rubik;
  padding: 10px;
`;

export const EmptyTableCell = styled(TableCell)`
  font-size: 16px;
  text-align: center;
  color: ${colors.midnight};
  background-color: ${colors.lightGrey};
  font-weight: ${({ bold }) => (bold ? "500" : "normal")};
`;

export const StyledAnswersLeftTableCell = styled(TableCell)`
  font-size: 16px;
  text-align: center;
  background-color: ${colors.lightGrey};
  color: ${colors.darkGrey};
`;

export const StyledDivider = styled(Divider)`
  align-self: center;
  transform: rotate(-45deg);
  width: 45px;
  margin: auto;
`;
