import colors from "../../../common/colors";
import landingpage_background from "../../../../assets/images/thankyoupage/landingpage_background.png";
import styled from "@emotion/styled";

const ContentContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${landingpage_background});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${colors.natural};

  &.s {
    background-position: -250px 50px;
    height: 100vh;
  }
`;

export const Logo = styled("img")`
  position: fixed;
  top: 3vh;
  height: 60px;
  max-width: auto;

  &.s {
    position: absolute;
  }
`;

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;

  &.s {
    position: relative;
    bottom: 4%;
    justify-content: center;
    height: 80%;
    max-width: 100%;
  }
`;

export default ContentContainer;
