import LoadingAnimation from "../../common/LoadingAnimation";
import MailPulseQuestion from "./MailPulseQuestion";
import MailPulseSkip from "./MailPulseSkip";
import MailPulseThanks from "./MailPulseThanks";
import React from "react";

const MailPulseCheck = ({
  question,
  isAlreadyAnswered,
  askedQuestionId,
  unansweredAmount,
  fact,
  setUnansweredAmount,
}) => {
  const [view, setView] = React.useState(
    isAlreadyAnswered ? "thanks" : "question"
  );
  switch (view) {
    case "question":
      return (
        <MailPulseQuestion
          question={question}
          setView={setView}
          askedQuestionId={askedQuestionId}
          setUnansweredAmount={setUnansweredAmount}
          unansweredAmount={unansweredAmount}
        ></MailPulseQuestion>
      );
    case "skip":
      return (
        <MailPulseSkip
          question={question}
          setView={setView}
          setUnansweredAmount={setUnansweredAmount}
          unansweredAmount={unansweredAmount}
          askedQuestionId={askedQuestionId}
        ></MailPulseSkip>
      );
    case "thanks":
      return (
        <MailPulseThanks
          isAlreadyAnswered={isAlreadyAnswered}
          unansweredAmount={unansweredAmount}
          fact={fact}
        />
      );
    default:
      return <LoadingAnimation />;
  }
};

export default MailPulseCheck;
