import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  position: relative;
  width: 94%;
  margin-left: 44px;
  margin-right: 44px;
  margin-top: -20px;
  margin-bottom: 5px;
`;

export const ListItem = styled("li")`
  font-size: 16px;
  margin-bottom: 5px;
`;

export const StyledUl = styled("ul")`
  font-size: 16px;
  &.s {
    padding-left: 20px;
  }
`;

export const StyledDetails = styled("details")`
  &.s {
    display: inline !important;
    &[open] summary {
      text-align: center;
      position: absolute;
      top: 2em;
      font-size: 16px;
      left: 1em;
      right: 1em;
    }
    &[open] summary:before {
      content: "Weniger anzeigen";
    }
  }
`;

export const StyledSummary = styled("summary")`
  display: none;
  &.s {
    display: block;
    :before {
      content: "Mehr anzeigen";
      color: ${colors.lavender};
      font-weight: 500;
    }

    ::-webkit-details-marker {
      display: none;
    }
  }
`;

export const StyledSpan = styled("span")`
  position: relative;
  text-align: start;
`;

export const StyledText = styled("p")`
  font-size: 16px;
  color: ${colors.midnight};

  &#detailsPadding {
    padding-bottom: 20px;
  }
`;
