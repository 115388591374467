import {
  downloadCsv,
  downloadCsvInstructions,
} from "../../../../../api/lightsApi";

export const initiateInstructionDownload = async () => {
  const fileName = "Diverlyze_Anleitung_Import.pdf";
  const data = await downloadCsvInstructions();
  initiateDownload(data, fileName);
};

export const initiateCsvDownload = async () => {
  const fileName = "Diverlyze_CSV_Beispiel.csv";
  const data = await downloadCsv();
  initiateDownload(data, fileName);
};

const initiateDownload = async (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
