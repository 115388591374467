export const getModalStyle = (size = "large") => {
  console.log(size);
  if (size === "s") {
    return {
      overlay: {
        zIndex: 10,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      content: {
        top: "50%",
        left: "50%",
        right: "20px",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
  } else {
    return {
      overlay: {
        zIndex: 10,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
  }
};
