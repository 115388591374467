import styled from "@emotion/styled";

export const AreaName = styled("div")`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
  height: 20px;
  margin-top: 5px;
`;

export const AreaIconContaioner = styled("div")`
  margin-bottom: 5px;
  margin-top: 15px;
  height: 50px;
`;

export const AreaInfoText = styled("div")`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  width: 100%;
`;
