import { FieldColor, FieldContainer, FieldName, FieldPercent } from "./styles";

import React from "react";
import { getOptionById } from "../../../../../settings/profile/diversityOptions";

const LegendFields = ({ payload }) => {
  const findLabel = (value) => {
    if (
      value === "niedrig" ||
      value === "mittel" ||
      value === "gehoben" ||
      value === "hoch"
    ) {
      const option = getOptionById(value);
      return option?.desc || "Keine Angaben";
    }

    const option = getOptionById(value);
    return option?.label || "Keine Angaben";
  };

  return (
    <div>
      {payload.map((field) => {
        return (
          <FieldContainer>
            <FieldColor color={field.color} />
            <FieldName>{findLabel(field.value)}</FieldName>
            <FieldPercent>
              {calcPercent(field.payload.percent).toFixed(1)} %
            </FieldPercent>
          </FieldContainer>
        );
      })}
    </div>
  );
};

const calcPercent = (x) => {
  return x * 100;
};

export default LegendFields;
