import colors from "../../../../common/colors";
import { computeRatingColor } from "../../../../../styles/computeScoreColors";
import { computeTrendColor } from "./index";
import styled from "@emotion/styled";

export const QuestionCardContainer = styled("div")`
  border: 2px solid ${colors.grey};
  border-radius: 10px;
  background-color: ${colors.lightGrey};
  padding: 20px;
  display: flex;
  height: 180px;
  width: 500px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ContentContainer = styled("div")`
  height: 100%;
  align-items: flex-start;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Content = styled("p")`
  font-size: 13px;
  width: 90%;
  margin: 0px;
`;

export const AreaTitle = styled("p")`
  font-weight: 500;
`;

export const ScoreContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const DataContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 30%;
`;
export const Score = styled("p")`
  margin: 0px;
  color: ${(props) => computeRatingColor(props.score)};
  font-size: 58px;
  font-weight: 500;
  width: 120px;
`;

export const LoadingContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Activity = styled("p")`
  margin: 0px;
  font-size: 14px;
`;

export const QuestionStats = styled("p")`
  margin: 0px;
  font-size: 14px;
  margin-left: 60px;
`;

export const Trend = styled("p")`
  margin: 0px;
  color: ${(props) => computeTrendColor(props.trend)};
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
`;

export const TrendContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;

export const QuestionContainer = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CommentaryContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0px;
  height: 100%;
`;

export const CommentHint = styled("p")`
  margin: 0px;
  font-size: 14px;
  text-decoration: underline dotted;
  font-weight: ${(props) => (props.newComments ? "500" : "default")};
  cursor: ${(props) => (props.hasComment ? "pointer" : "default")};
`;
