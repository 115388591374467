import React from "react";
import StyledLink from "./styles";

function Link(props) {
  const { color, fontWeight, href, children } = props;

  return (
    <StyledLink
      color={color}
      fontWeight={fontWeight}
      href={href}
      target="_self"
      rel="noopener noreferrer"
    >
      {children}
    </StyledLink>
  );
}

export default React.memo(Link);
