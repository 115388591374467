import Dropdown from "../../../../../common/Dropdown";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import { socialBackgroundOptions } from "../../../diversityOptions";

const SocialBackgroundInput = ({ props }) => {
  const { employee, setEmployee, dynamicSize } = props;
  return (
    <FieldContainer>
      <Dropdown
        size={dynamicSize}
        value={employee.social_background}
        label="Soziale Familienherkunft"
        menuItems={socialBackgroundOptions}
        onChange={({ target }) =>
          setEmployee({ ...employee, social_background: target.value })
        }
      />
    </FieldContainer>
  );
};

export default SocialBackgroundInput;
