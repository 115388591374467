import styled from "@emotion/styled";

const Heading = styled("h2")`
  left: 20px;
  font-weight: 500;
  font-size: 20px;
  color: ${(props) => props.fontColor};
`;

export default Heading;
