import styled from "@emotion/styled";

export const GridContainer = styled("div")`
  display: grid;
  width: 90%;
  grid-template-columns: repeat(4, 1fr);
  padding: 10px;
  align-items: center;
  row-gap: 20px;
  justify-content: space-between;
  justify-items: center;
`;

export const AreasTitle = styled("p")`
  font-weight: 500;
  font-size: 16px;
  justify-self: start;
  grid-column-start: 1;
  grid-column-end: 5;
  margin: 16px 0 10px 25px;
`;
