import React from "react";
import colors from "../../common/colors";
import { darken } from "polished";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

const ItemContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  border-radius: 0px 0px 0px 11px;
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 22px;
  cursor: pointer;
  &:hover {
    background-color: ${() => darken(0.1, colors.white)};
  }
  background-color: ${(props) => (props.active ? colors.grey : colors.white)};
`;

const MenuTitle = styled("h4")`
  margin: 0;
  font-weight: 500;
`;

const MenuSubtitle = styled("p")`
  margin: 0;
  font-size: 12px;
`;

const MenuItem = ({ title, subtitle, onClick, path }) => {
  const { pathname } = useLocation();
  const pathlocation = pathname.split("/")[2];
  const active = pathlocation === path;

  return (
    <ItemContainer active={active} onClick={() => onClick()}>
      <MenuTitle>{title}</MenuTitle>
      <MenuSubtitle>{subtitle}</MenuSubtitle>
    </ItemContainer>
  );
};

export default MenuItem;
