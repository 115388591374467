import {
  ActiveMenuItem,
  Container,
  IconContainer,
  InactiveMenuItem,
} from "./styles";

import { Flex } from "@react-spectrum/layout";
import NavIcon from "../NavIcon";
import React from "react";
import { useNavigate } from "react-router-dom";

function NavItem({ active = false, title, path }) {
  const navigate = useNavigate();

  return (
    <Container
      onClick={() => {
        navigate(`/${path}`);
      }}
    >
      <Flex alignItems="center">
        <IconContainer>
          <NavIcon path={path} active={active} />
        </IconContainer>
        {active ? (
          <ActiveMenuItem>{title}</ActiveMenuItem>
        ) : (
          <InactiveMenuItem>{title}</InactiveMenuItem>
        )}
      </Flex>
    </Container>
  );
}

export default NavItem;
