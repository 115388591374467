import colors from "../../../common/colors";
import { computeRatingColor } from "../../../../styles/computeScoreColors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  cursor: pointer;
  height: 80px;
  width: 90%;
  border: 1px solid ${colors.darkGrey};
  border-radius: 5px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 14px;

  background-color: ${({ isSelected }) =>
    isSelected ? colors.lightGrey : colors.white};)}
`;

export const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const QuestionContainer = styled("div")`
  font-size: 14px;
  font-style: italic;
  width: 76%;
`;

export const DateContainer = styled("div")`
  font-size: 14px;
  width: 120px;
  text-align: right;
  font-weight: ${({ bold }) => (bold ? 500 : 300)};
`;

export const AnswerContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export const Rating = styled("div")`
  margin-right: 14px;
  width: 10px;
  text-align: center;
  font-weight: 500;
  color: ${({ rating }) => computeRatingColor(rating)};
`;

export const Comment = styled("div")`
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const NewMessageHint = styled("div")`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${colors.lavender};
`;

export const NewMessageHintContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
`;
