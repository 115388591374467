import clsx from "clsx";

export const columns = [
  {
    field: "rating",
    headerName: "Rating",
    width: 100,
    cellClassName: (params) => {
      return clsx("rating", {
        good: params.value > 7.5,
        moderate: params.value <= 7.5 && params.value > 5.0,
        bad: params.value <= 5.0,
      });
    },
  },
  {
    field: "text",
    headerName: "Kommentar",
    width: 550,
  },
  {
    field: "createdAt",
    headerName: "Datum",
    width: 160,
  },
];
