import {
  BIRTHDAY_STEP_TITLE,
  DISABILITY_STEP_TITLE,
  ETHNICITY_STEP_TITLE,
  GENDER_STEP_TITLE,
  RELIGION_STEP_TITLE,
  SEXUALITY_STEP_TITLE,
  SOCIAL_BACKGROUND_STEP_TITLE,
} from "../onboarding/SurveeOnboarding/steps/stepTitles";

const dimensionOptions = [
  { key: 1, label: "Geschlecht", id: GENDER_STEP_TITLE },
  { key: 2, label: "Alter", id: BIRTHDAY_STEP_TITLE },
  { key: 3, label: "Sexualität", id: SEXUALITY_STEP_TITLE },
  { key: 4, label: "Ethnizität", id: ETHNICITY_STEP_TITLE },
  { key: 6, label: "Religion", id: RELIGION_STEP_TITLE },
  { key: 7, label: "Behinderung", id: DISABILITY_STEP_TITLE },
  { key: 8, label: "Soziale Herkunft", id: SOCIAL_BACKGROUND_STEP_TITLE },
  //{ key: 9, label: "Teams", id: "teamIds" },
];

export { dimensionOptions };
