import * as Components from "./styles";

import AreaIcon from "../../../report/AreaIcon";
import { Flex } from "@react-spectrum/layout";
import React from "react";

const DetailsSection = ({ areaName, areaInfoText, areaIdentifier }) => {
  return (
    <Flex direction="column">
      <Components.AreaIconContaioner>
        <AreaIcon type={areaIdentifier} />
      </Components.AreaIconContaioner>
      <Components.AreaName>{areaName}</Components.AreaName>
      <Components.AreaInfoText>{areaInfoText}</Components.AreaInfoText>
    </Flex>
  );
};

export default React.memo(DetailsSection);
