import { FormControl, MenuItem } from "@mui/material";

import styled from "@emotion/styled";

export const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

export const StyledMenuItem = styled(MenuItem)`
  white-space: normal;
`;
