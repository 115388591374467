import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import AreaSelection from "./AreaSelection";
import Heading1 from "../../common/Heading1";
import React from "react";
import colors from "../../common/colors";

const AreaNavigation = () => {
  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>{`Areas`}</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.AreaNavigationSection}>
        <AreaSelection></AreaSelection>
      </AreaSection>
    </>
  );
};

const sectionContent = {
  AreaNavigationSection: {
    id: "timeline",
    title: `Alle Bereiche`,
    infoText: `Welche Area möchtest Du im Detail sehen?`,
    hoverText:
      "Die 12 Bereiche resultieren aus einer Marktanalyse mit über 100 Arbeitgeber*innen und Arbeitnehmer*innen.",
  },
};

// TODO add React.memo everywhere
export default React.memo(AreaNavigation);
