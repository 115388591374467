import ContrastTableCell from "../../ContrastTableCell";
import React from "react";
const ContrastVarianceScoreCell = ({ keyName, score, isVariance }) => {
  if (score.variance === null || score.variance === undefined) {
    return (
      <ContrastTableCell
        key={keyName}
        id={keyName}
        infoMessage="Zur Berechnung der Varianz sind mindestens 2 Scores erforderlich."
        isVariance={isVariance}
      />
    );
  }

  return (
    <ContrastTableCell
      key={keyName}
      id={keyName}
      rating={score.variance}
      type="score"
      isVariance={isVariance}
    >
      {score.variance}
    </ContrastTableCell>
  );
};
export default ContrastVarianceScoreCell;
