import { Box, Stack } from "@mui/system";
import { ContentBox, StackContainer, StyledBox, SubHeader } from "./styles";

import DropdownArea from "./DropdownArea";
import DropdownDimension from "./DropdownDimension";
import React from "react";
import { Typography } from "@mui/material";

//const hoverText = "Du erhebst zu diesen Dimensionen keine Daten.";

const DropdownContext = ({
  selected,
  updateSelection,
  dimensions,
  areas,
  selectedArea,
  setSelectedArea,
}) => {
  return (
    <StackContainer
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <StyledBox>
        <ContentBox>
          <Box sx={{ my: 1, mx: 2 }}>
            <Stack container alignItems="flex-start">
              <Stack item="true">
                <SubHeader
                  gutterBottom
                  variant="h6"
                  component="div"
                  fontWeight="500"
                >
                  Ergebnisse der Diversitätsdimensionen
                </SubHeader>
              </Stack>
            </Stack>
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{ marginBottom: "30px", width: "80%" }}
            >
              Wähle eine Dimension und einen Bereich aus, um die Ergebnisse der
              einzelnen Gruppen und Fragen zu vergleichen.
            </Typography>
            <Stack direction="row" spacing={2}>
              <DropdownArea
                selectedArea={selectedArea}
                updateSelection={setSelectedArea}
                areas={areas}
              />
              <DropdownDimension
                selected={selected}
                updateSelection={updateSelection}
                dimensions={dimensions}
              />
            </Stack>
          </Box>
        </ContentBox>
      </StyledBox>
    </StackContainer>
  );
};

export default DropdownContext;
