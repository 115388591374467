import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const DimensionContainer = styled("div")`
  display: flex;
  width: 830px;
  height: 250px;

  &.s {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    min-height: 200px;
    justify-content: center;
  }
`;

export const FactContainer = styled("div")`
  margin-top: 20px;
  width: 664px;
  height: 120px;
  background-color: ${colors.lavenderLight};
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  &.s {
    margin-bottom: 20px;
    width: 90%;
    height: auto;
  }
`;

export const QuestionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 80%;
  background-color: ${colors.white};
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  &.s {
    width: 90%;
    min-height: 240px;
  }
`;

export const SideContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 10%;
  height: 100%;

  &.s {
    display: none;
  }
`;

export const OuterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.s {
    flex-direction: column-reverse;
    max-width: 100%;
  }
`;

/*Attributes for 'return'-Button*/
export const BackButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 70px;
  width: 70px;
  cursor: pointer;

  &.s {
    display: none;
  }
`;

export const DimensionCheck = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 65%;

  &.s {
    justify-content: space-between;
    height: 100%;
  }
`;

export const ButtonSection = styled("div")`
  display: flex;
  justify-content: center;
  height: 30%;
`;

export const QuestionText = styled("p")`
  font-size: 20px;
  font-weight: 500;

  &.s {
    font-size: 24px;
  }
`;

export const QuestionLink = styled("span")`
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  color: ${colors.lavender};

  &.s {
    font-size: 24px;
    word-wrap: break-word;
  }
`;

export const QuestionTextContainer = styled("div")`
  display: flex;
  height: 62%;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  padding-left: 40px;
  padding-right: 60px;

  &.s {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const CheckContainer = styled("div")`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  padding-right: 60px;

  &.s {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
`;

export const InfoIconContainer = styled("div")`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-left: 10px;
`;

export const DropdownContainer = styled("div")`
  display: flex;
  width: ${({ size }) => size === "large" && "100%"};
  text-align: start;
  justify-content: space-around;
  max-width: 90%;

  &.s {
    width: ${({ size }) => size === "large" && "90%"};
  }
`;

export const MobileOuterContainer = styled("div")`
  display: flex;
  width: 90%;
  height: 30px;
  padding-top: 10px;
  justify-content: space-between;
  align-items: flex-end;
`;
