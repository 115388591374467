import { CircularProgress } from "@mui/material";
import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  width: 65%;
  height: 250px;
  width: 550px;
  text-align: center;
  overflow-y: auto;
  max-height: 85%;
  padding: 20px 15px;
  background-color: ${colors.white};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  &.l {
    width: 60%;
  }
  &.m {
    width: 70%;
  }

  &.s {
    height: auto;
    width: 85%;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const TextContainer = styled("div")`
  width: 78%;
  margin-bottom: 20px;

  &.s {
    margin: 0px;
    width: 85%;
  }
`;

export const Header = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.s {
    height: auto;
  }
`;

export const SideContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 5%;
  height: 100%;
`;

/*Attributes for 'return'-Button*/
export const BackButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 70px;
  width: 70px;
  cursor: pointer;

  &.s {
    display: none;
  }
`;

export const OuterContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

export const MobileOuterContainer = styled("div")`
  display: flex;
  width: 90%;
  height: 300px;
  margin-top: 20px;
  flex-direction: ${({ reverseDirection }) =>
    reverseDirection && "row-reverse"};
  justify-content: space-between;
  align-items: flex-start;
`;

export const ProgressPositioner = styled("div")`
  margin-top: -20px;

  &.s {
    margin-top: -20px;
  }
`;

export const ProgressContainer = styled("div")`
  position: relative;
  top: 20px;
  height: 40px;
  width: 60px;

  &.s {
    margin-left: 0px;
    height: 20px;
    width: 20px;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  color: ${colors.lavender};

  &.s {
    width: 25px !important;
  }
`;
