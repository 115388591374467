import styled from "@emotion/styled";

export const Container = styled("div")`
  width: 400px;
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled("div")`
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ cursor }) => cursor};
`;

export const Logo = styled("img")`
  height: 40px;
`;
