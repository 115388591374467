import { Button } from "@mui/material";
import { ButtonContainer } from "./styles";

export const CreateEmployee = ({ onOpenCreateModal }) => {
  return (
    <ButtonContainer>
      <Button variant="contained" onClick={onOpenCreateModal}>
        Mitglied erstellen
      </Button>
    </ButtonContainer>
  );
};
