import { Button } from "@mui/material";
import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const LoginContainer = styled("div")`
  &.m {
    width: 100%;
  }
  &.s {
    width: 100%;
  }
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled("div")`
  &.m {
    max-width: 80%;
  }
  &.s {
    min-width: 80%;
  }
  width: 35%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledDiv = styled("div")`
  color: ${colors.darkGrey};
  font-weight: 500;
  margin-top: -3px;
  font-size: 16px;
  margin-bottom: 17px;
`;
