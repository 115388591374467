import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import colors from "../../common/colors";
import styled from "@emotion/styled";

export const StackContainer = styled(Stack)`
  height: 300px;
  display: flex;
  margin-left: 60px;
  align-self: flex-start;
  width: 600px;
`;

export const StyledBox = styled(Box)`
  background-color: ${colors.lightGrey};
  border: 1px solid ${colors.darkGrey};
  border-radius: 5px;
  flex-direction: column;
  height: 230px;
`;
export const ContentBox = styled(Box)`
  height: 100px;
`;

export const SubHeader = styled("h4")`
  font-weight: 500;
  margin-top: 20px;
`;
