import { styled } from "@mui/material";

export const ButtonContainer = styled("div")`
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const Text = styled("span")`
  margin: 2px 0;
  font-size: 16px
  font-weight: 500;
  text-align: center;
  font-size: 14px;

  b {
    font-weight: 500;
  }
`;
