import React from "react";
import colors from "./colors";
import styled from "@emotion/styled";

const DividerHorizontal = styled("div")`
  width: 95%;
  border: 3px solid ${colors.lightGrey};
  border-radius: 5px;
  align-self: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default React.memo(DividerHorizontal);
