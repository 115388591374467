import React from "react";
import ReactTooltip from "react-tooltip";

const Tooltip = ({ children, position, id }) => {
  return (
    <ReactTooltip id={id} place={position} effect="solid">
      {children}
    </ReactTooltip>
  );
};

Tooltip.defaultProps = {
  position: "bottom",
};

export default Tooltip;
