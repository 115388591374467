import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const ImportForm = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  text-align: center;
`;

export const ImportSection = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DragDrop = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  border: 3px dashed ${colors.darkGrey};
  margin: 20px;
  cursor: pointer;
`;

export const DragDropInput = styled("input")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
`;

export const DragText = styled("p")`
  font-size: 1.3rem;
  color: ${colors.darkGrey};
  font-weight: 500;
  margin: 20px;
  text-align: center;
`;

export const ConfirmationContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  border: 3px dashed ${colors.lightGrey};
  margin: 20px;
`;

export const UploadConfirmation = styled("h1")`
  font-size: 1.3rem;
  font-weight: 500;
  color: ${colors.green};
`;