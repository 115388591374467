import { TextField } from "@mui/material";
import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  text-align: center;
  width: 50%;
`;

export const InputContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
  height: 100px;
`;

export const StyledTextField = styled(TextField)`
  width: 50%;
`;

export const Text = styled("p")`
  color: ${colors.midnight};
`;
