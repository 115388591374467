import { Container, LabelText } from "./styles";

import React from "react";
import usePortalSize from "../../../../../hooks/usePortalSize";

const Labels = () => {
  const windowSize = usePortalSize();

  return (
    <Container>
      <LabelText className={windowSize}>Trifft überhaupt nicht zu</LabelText>
      <LabelText className={windowSize}>Trifft voll und ganz zu</LabelText>
    </Container>
  );
};

export default Labels;
