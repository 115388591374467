import { ReactComponent as QuestionIcon } from "../../../icons/question_mark.svg";
import React from "react";
import Tooltip from "../Tooltip";
import TooltipContainer from "./styles";

function TextWithTooltip({ children, text, id }) {
  return (
    <TooltipContainer styleName="text-tooltip">
      {children}
      <QuestionIcon data-tip data-for={id} />
      <Tooltip id={id}>{text}</Tooltip>
    </TooltipContainer>
  );
}

export default TextWithTooltip;
