import { FormControl, TextField } from "@mui/material";

import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("form")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  width: 95%;
  margin-left: 44px;
  margin-right: 44px;
  margin-top: -20px;
`;

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.s {
    flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  }
`;

export const CheckContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 70%;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const FieldContainer = styled("div")`
  width: 47%;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  &.s {
    margin: 10px 5px;
    min-width: ${({ minWidth }) => minWidth || "47%"};
    text-align: start;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  width: 300px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const TitleContainer = styled("div")`
  align-self: flex-start;
  text-align: start;

  &.s {
    align-self: end;
    width: 98%;
  }
`;

export const SubHeader = styled("h4")`
  font-weight: 500;
`;

export const ButtonContainer = styled("div")`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &.s {
    flex-direction: column;
  }
`;

export const InfoText = styled("div")`
  font-size: 14px;
`;

export const StyledText = styled("p")`
  font-size: 16px;
  color: ${colors.midnight};
  &.s {
    text-align: ${({ align }) => align || "center"};
  }
`;
