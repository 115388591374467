import { BackButtonContainer, BackIconContainer } from "./styles";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import React from "react";

const BackButton = ({ onClick }) => {
  return (
    <BackButtonContainer>
      <BackIconContainer variant="contained" onClick={onClick}>
        <ArrowBackRoundedIcon />
      </BackIconContainer>
    </BackButtonContainer>
  );
};

export default BackButton;
