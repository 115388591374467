import {
  AdminInfoText,
  CloseIcon,
  Container,
  IconContainer,
  MemberContainer,
  NameContainer,
  SaveContainer,
  StyledAutoComplete,
  StyledTextField,
  Subheader,
  TeamLeadContainer,
  Title,
  TitleContainer,
} from "./styles";
import { Chip, TextField } from "@mui/material";

import Button from "@mui/material/Button";
import React from "react";
import { captureException } from "@sentry/react";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";

const CreationModal = ({ employees, closeModal, updateTeamList }) => {
  const [teamName, setTeamName] = React.useState("");
  const [teamLeads, setTeamLeads] = React.useState([]);
  const [teamMembers, setTeamMembers] = React.useState([]);

  const { createTeam } = useLightsApiPrivate();
  const alert = useAlert();

  const handleSubmit = async () => {
    const teamMemberIds = teamMembers.map((employee) => employee._id);
    const teamLeadIds = teamLeads.map((employee) => employee._id);

    const team = {
      name: teamName,
      teamLeadIds,
      teamMemberIds,
    };

    try {
      alert.removeAll();
      await createTeam(team);
      await updateTeamList();
      alert.success("Team erfolgreich hinzugefügt");
      closeModal();
    } catch (err) {
      captureException(err);
      if (!err?.response) {
        alert.error("Der Server ist nicht erreichbar");
      } else if (err.response?.status === 400) {
        alert.error("Bitte fülle alle Felder aus");
      } else if (err.response?.status === 404) {
        alert.error("Mitarbeiter nicht gefunden");
      } else if (err.response?.status === 409) {
        alert.error("ES EXISTIERT SCHON EIN TEAM MIT DIESEM NAMEN");
      } else {
        alert.error("Es ist ein Fehler aufgetreten");
      }
    }
    await createTeam(team);

    closeModal();
  };

  const handleTeamLeadChange = (newTeamLeads) => {
    setTeamLeads(newTeamLeads);

    setTeamMembers((prevTeamMembers) => {
      const membersWithoutLeads = prevTeamMembers.filter(
        (member) => !newTeamLeads.find((mem) => mem._id === member._id)
      );

      return [...membersWithoutLeads, ...newTeamLeads];
    });
  };

  const fixedOption = teamLeads;

  const getEmployeeOptions = (employees) => {
    const empWithDepartment = employees.filter((emp) => emp.department);
    if (empWithDepartment.length < 2) return employees;
    return employees.sort((a, b) => -b.department.localeCompare(a.department));
  };

  return (
    <Container>
      <IconContainer>
        <CloseIcon onClick={closeModal} />
      </IconContainer>
      <TitleContainer>
        <Title>Erstelle ein neues Team</Title>
      </TitleContainer>
      <NameContainer>
        <Subheader>Team Name</Subheader>
        <StyledTextField
          autoComplete="false"
          onChange={(event) => setTeamName(event.target.value)}
          id="outlined-basic"
          variant="outlined"
          label="Name"
        />
      </NameContainer>
      <TeamLeadContainer>
        <Subheader>Führungskräfte</Subheader>
        <StyledAutoComplete
          isOptionEqualToValue={(option, value) => option._id === value._id}
          onChange={(event, value) => handleTeamLeadChange(value)}
          id="combo-box-demo"
          options={employees}
          multiple
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          groupBy={(option) => option.department}
          renderInput={(params) => (
            <TextField label="Führungskräfte" {...params} />
          )}
        />
      </TeamLeadContainer>
      <MemberContainer>
        <Subheader>Mitglieder</Subheader>
        <StyledAutoComplete
          value={teamMembers}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          onChange={(event, newValue) => {
            setTeamMembers([
              ...fixedOption,
              ...newValue.filter(
                (option) =>
                  !teamLeads.find((member) => member._id === option._id)
              ),
            ]);
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.firstName + " " + option.lastName}
                {...getTagProps({ index })}
                disabled={teamLeads.find((member) => member._id === option._id)}
              />
            ))
          }
          disableCloseOnSelect
          limitTags={2}
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          multiple
          groupBy={(option) => option.department}
          id="multiple-limit-tags"
          options={getEmployeeOptions(employees)}
          renderInput={(params) => (
            <TextField {...params} placeholder="Mitglieder" />
          )}
        />
        <AdminInfoText>
          Um Scores berechnen zu können, muss es im Team mindestens 4 Mitglieder
          geben.
        </AdminInfoText>
      </MemberContainer>
      <SaveContainer>
        <Button variant="neutral" onClick={() => handleSubmit()}>
          Hinzufügen
        </Button>
      </SaveContainer>
    </Container>
  );
};

export default React.memo(CreationModal);
