import styled from "@emotion/styled";
import React from "react";

const DividerVertical = styled("div")`
  height: 30px;
  border-left: 1px solid black;
  margin-left: 2px;
  margin-right: 2px;
`;

export default React.memo(DividerVertical);
