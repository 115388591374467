import colors from "../../../../../common/colors";
import styled from "@emotion/styled";

export const LoadingContainer = styled("div")`
  position: fixed;
  background-color: ${colors.transparent};
  width: 95%;
  height: 85%;
  z-index: 10;
`;

export const ModalButtonContainer = styled("div")`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
