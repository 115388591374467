import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: row;

  &.s {
    width: 100%;
    flex-direction: column;
  }
`;

export const SideContainer = styled("div")`
  display: flex;
  width: 50px;
  margin-left: 10px;
  flex-direction: row-reverse;

  &.s {
    display: none;
  }
`;

export const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 470px;

  &.s {
    width: 100%;
  }
`;

export const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 20%;
  width: 100%;
  margin-top: 10px;
`;

export const DescContainer = styled("div")`
  height: 60%;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
`;

export const Footer = styled("div")`
  height: 20%;
  width: 100%;

  &.s {
    width: 90%;
  }
`;

export const Title = styled("p")`
  font-size: 20px;
  margin: 0px;

  &.s {
    font-size: 26px;
  }
`;

export const Description = styled("p")`
  font-size: 16px;
  margin: 0px;
`;

export const Link = styled("p")`
  font-size: 16px;
  margin: 0px;
  color: ${colors.lavender};
  cursor: pointer;
  margin-bottom: 10px;

  &.s {
    word-break: break-all;
    margin-top: 10px;
  }
`;

export const Logo = styled("img")`
  width: 50px;
  height: 50px;
`;

/*Attributes for 'return'-Button*/
export const CloseIconContainer = styled("div")`
  display: flex;
  align-items: center;
  height: 25px;
  width: 25px;
  cursor: pointer;

  &.s {
    display: none;
  }
`;

export const MobileUpperContainer = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
