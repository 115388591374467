import React, { useEffect, useState } from "react";

import AreaSection from "../../../../common/AreaSection";
import { AreaSectionHeader } from "../../../../common/AreaSectionHeader";
import ConsentSection from "../ConsentSection";
import { Container } from "./styles";
import Heading1 from "../../../../common/Heading1";
import LoadingAnimation from "../../../../common/LoadingAnimation";
import PersonalProfile from "../PersonalProfile";
import colors from "../../../../common/colors";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";
import usePortalSize from "../../../../../hooks/usePortalSize";

const PersonalProfilePage = () => {
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dimensionOptions, setDimensionOptions] = useState(null);

  const { getEmployeePD, getAccountDimensions } = useLightsApiPrivate();
  const size = usePortalSize();

  useEffect(() => {
    const fetchEmployee = async () => {
      setLoading(false);
      const employee = await getEmployeePD();
      const dimensionOptions = await getAccountDimensions();

      setDimensionOptions(dimensionOptions);
      setEmployee(employee);
    };

    if (loading) {
      fetchEmployee().catch(console.error);
    }
  });

  return (
    <Container className={size}>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Profil</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.agreementSection}>
        <ConsentSection />
      </AreaSection>
      <AreaSection content={sectionContent.profileSection}>
        {employee ? (
          <PersonalProfile
            employee={employee}
            setEmployee={setEmployee}
            dimensionOptions={dimensionOptions}
          />
        ) : (
          <LoadingAnimation />
        )}
      </AreaSection>
    </Container>
  );
};

export default PersonalProfilePage;

const sectionContent = {
  agreementSection: {
    id: "agreement",
    title: "Deine Teilnahme an den Pulse Checks",
  },
  profileSection: {
    id: "personalprofile",
    title: "Profil",
    infoText: "Bearbeite Deine Daten.",
  },
};
