import React, { useEffect, useState } from "react";

import DividerVertical from "../common/DividerVertical";
import { Flex } from "@react-spectrum/layout";
import { MenuItems } from "../../utils/pages";
import NavItem from "./NavItem";
import useLightsApiPrivate from "../../hooks/useLightsApiPrivate";
import { useLocation } from "react-router-dom";
import useRole from "../../hooks/useRole";

function Navigation() {
  const { pathname: path } = useLocation();
  const pathlocation = path.split("/")[1];
  const { role } = useRole();

  const { getAccount } = useLightsApiPrivate();
  const [lockDialog, setLockDialog] = useState(true);

  const isSurvee = role === "survee";

  useEffect(() => {
    getAccount().then((data) => {
      if (data.isDialogActive) setLockDialog(false);
    });
  }, [getAccount]);

  if (isSurvee)
    return (
      <Flex direction="row" alignItems="center">
        <DividerVertical />
        <NavItem
          active={pathlocation.includes(MenuItems.Pulse.path)}
          title={MenuItems.Pulse.title}
          path={MenuItems.Pulse.path}
        />
        {lockDialog ? (
          ""
        ) : (
          <NavItem
            active={pathlocation.includes(MenuItems.Dialog.path)}
            title={MenuItems.Dialog.title}
            path={MenuItems.Dialog.path}
          />
        )}
        <DividerVertical />
      </Flex>
    );

  return (
    <Flex direction="row" alignItems="center">
      <DividerVertical />
      <NavItem
        active={
          pathlocation.includes(MenuItems.Report.path) || pathlocation === ""
        }
        title={MenuItems.Report.title}
        path={MenuItems.Report.path}
      />
      <NavItem
        active={pathlocation.includes(MenuItems.Contrast.path)}
        title={MenuItems.Contrast.title}
        path={MenuItems.Contrast.path}
      />
      <NavItem
        active={pathlocation.includes(MenuItems.Areas.path)}
        title={MenuItems.Areas.title}
        path={MenuItems.Areas.path}
      />
      <NavItem
        active={pathlocation.includes(MenuItems.Dialog.path)}
        title={MenuItems.Dialog.title}
        path={MenuItems.Dialog.path}
      />
      <DividerVertical />
    </Flex>
  );
}

export default React.memo(Navigation);
