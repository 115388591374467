import { ReactComponent as LoadingIcon } from "../../../../icons/loading.svg";
import { ReactComponent as QuestionIcon } from "../../../../icons/question_mark.svg";
import { QuestionIconContainer } from "./styles";
import React from "react";
import Tooltip from "../../../common/Tooltip";
import colors from "../../../common/colors";

const LoadingSection = ({
  answersLeft,
  id,
  stroke = colors.midnight,
  width = 100,
  showQuestionMark = true,
}) => {
  const answersString =
    answersLeft === 1
      ? `Für diesen Bereich fehlt noch eine Antwort`
      : `Für diesen Bereich fehlen noch ${answersLeft} Antworten`;

  return (
    <div data-tip data-for={id}>
      {showQuestionMark && (
        <QuestionIconContainer>
          <QuestionIcon width={30} stroke={stroke} />
        </QuestionIconContainer>
      )}
      <LoadingIcon width={width} stroke={stroke} />
      <Tooltip
        id={id}
      >{`${answersString}, bevor ein Wert angezeigt werden kann.`}</Tooltip>
    </div>
  );
};

export default React.memo(LoadingSection);
