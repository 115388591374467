import { FormControl, ListSubheader, MenuItem, Select } from "@mui/material";

import { LabelArea } from "./styles";
import React from "react";

const DropdownArea = ({ selectedArea, updateSelection, areas }) => {
  const hasCustomAreas = areas.some((area) => area.isCustom);
  return (
    <FormControl sx={{ width: "250px" }}>
      <LabelArea shrink={true}>Bereich</LabelArea>
      <Select
        id="area-select"
        value={selectedArea || ""}
        label="Bereich"
        onChange={(event) => updateSelection(event.target.value)}
        displayEmpty
        variant="outlined"
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 250,
              overflowY: "auto",
            },
          },
        }}
      >
        <MenuItem key="" value="">
          Alle Bereiche
        </MenuItem>

        {hasCustomAreas && (
          <ListSubheader disabled>Diverlyze Bereiche</ListSubheader>
        )}
        {areas
          .filter((area) => !area.isCustom)
          .map((area) => (
            <MenuItem key={area._id} value={area._id}>
              {area.name}
            </MenuItem>
          ))}

        {hasCustomAreas && (
          <ListSubheader disabled>Custom Bereiche</ListSubheader>
        )}

        {areas
          .filter((area) => area.isCustom)
          .map((area) => (
            <MenuItem key={area._id} value={area._id}>
              {area.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DropdownArea;
