export const columns = [
  { field: "firstName", headerName: "Vorname", width: 100 },
  { field: "lastName", headerName: "Nachname", width: 100 },
  {
    field: "mail",
    headerName: "E-Mail",
    width: 230,
  },
  {
    field: "teams",
    headerName: "Teams",
    width: 120,
  },
  {
    field: "error",
    headerName: "Formatierungsfehler",
    width: 450,
  },
  { field: "id", headerName: "CSV Reihe", width: 80 },
];
