import { Container, Logo } from "./styles";

import ContentContainer from "./styles";
import React from "react";
import logo from "../../../../assets/images/diverlyze_logo.png";
import usePortalSize from "../../../../hooks/usePortalSize";

const SurveeOnboardingContainer = ({ children }) => {
  const windowSize = usePortalSize();

  return (
    <ContentContainer className={windowSize}>
      <Logo src={logo} className={windowSize} />
      <Container className={windowSize}>{children}</Container>
    </ContentContainer>
  );
};

export default SurveeOnboardingContainer;
