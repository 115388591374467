import {
  FormContainer,
  LoginContainer,
  StyledButton,
  StyledDiv,
} from "./styles";
import React, { memo } from "react";

import Heading2 from "../../../common/Heading2";
import LoginPageContainer from "../../LoginPageContainer";
import { useNavigate } from "react-router-dom";
import usePortalSize from "../../../../hooks/usePortalSize";

const PasswordAlreadyCreatedPage = () => {
  const navigate = useNavigate();
  const size = usePortalSize();
  return (
    <LoginPageContainer>
      <LoginContainer className={size}>
        <FormContainer className={size}>
          <Heading2>Du hast Dir bereits ein Passwort erstellt.</Heading2>
          <StyledDiv>Du kannst Dich jetzt einloggen.</StyledDiv>
          <StyledButton variant="contained" onClick={() => navigate(`/Login`)}>
            Zum Login
          </StyledButton>
        </FormContainer>
      </LoginContainer>
    </LoginPageContainer>
  );
};

export default memo(PasswordAlreadyCreatedPage);
