import { FormControl, TextField } from "@mui/material";

import { CloseRounded } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import styled from "@emotion/styled";

export const Container = styled("form")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  width: 95%;
  margin-left: 34px;
  margin-right: 34px;
`;

export const TitleContainer = styled("div")`
  align-self: flex-start;
  height: 70px;
`;

export const CheckBoxContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Checkbox = styled("input")`
  margin-right: 10px;
  margin-left: 10px;
`;

export const IconContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const CloseIcon = styled(CloseRounded)`
  cursor: pointer;
`;

export const AdminInfoText = styled("i")`
  margin-top: 10px;
  font-size: 12px;
`;

export const ButtonContainer = styled("div")`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: space-between;
`;

export const NameContainer = styled("div")`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const Subheader = styled("h3")`
  font-weight: 500;
`;

export const GenderFormControl = styled(FormControl)`
  width: 100%;
`;

export const RoleFormController = styled(FormControl)`
  width: 50%;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;
