import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";

export const StyledDataGrid = styled(DataGrid)`
  width: 95%;
`;

export const cellStyle = {
  "& .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
};
