import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import moment from "moment";

const BirthdateInput = ({ props }) => {
  const { employee, setEmployee, dynamicSize } = props;
  const currentYear = moment().year();

  const handleDateChange = (newDate) => {
    if (newDate) {
      const birthdate = moment(newDate).startOf("year").toDate();
      setEmployee({ ...employee, birthdate });
    } else {
      setEmployee({ ...employee });
    }
  };
  return (
    <FieldContainer>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          slotProps={{ textField: { size: dynamicSize } }}
          label="Geburtsjahr"
          views={["year"]}
          maxDate={moment().year(currentYear)}
          value={
            employee.birthdate
              ? moment(employee.birthdate).startOf("year")
              : null
          }
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </FieldContainer>
  );
};

export default BirthdateInput;
