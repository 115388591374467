import { DISABILITY_STEP_TITLE } from "./stepTitles";
import Dropdown from "../../../common/Dropdown";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { disabilityOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const DisabilityStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [disabilities, setDisabilities] = React.useState(
    employee.disabilities.length === 0
      ? ["no_disability"]
      : employee.disabilities
  );

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, disabilities });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, disabilities });
    handleNextStep();
  };

  const updateDisabilities = (disabilities) => {
    if (
      (disabilities[0] === "not_applicable" ||
        disabilities[0] === "no_disability") &&
      disabilities.length > 1
    ) {
      setDisabilities(disabilities.slice(1));
    } else if (disabilities.includes("not_applicable")) {
      setDisabilities(["not_applicable"]);
    } else if (disabilities.includes("no_disability")) {
      setDisabilities(["no_disability"]);
    } else {
      setDisabilities(disabilities);
    }
  };

  props = {
    ...props,
    selected: disabilities,
    step,
    saveAndNext,
    dimension: DISABILITY_STEP_TITLE,
  };

  return (
    <OptionStepContainer props={props}>
      <Dropdown
        value={disabilities ? disabilities : ["not_applicable"]}
        label="Behinderung"
        menuItems={disabilityOptions}
        onChange={({ target }) => updateDisabilities(target.value)}
        multiple
        size="small"
      />
    </OptionStepContainer>
  );
};

export default DisabilityStep;
