import React from "react";
import { Text } from "./styles";

const ConfirmationMessage = ({ isAlreadyAnswered }) => {
  if (isAlreadyAnswered) {
    return <Text>Du hast diese Frage bereits beantwortet.</Text>;
  }
  return (
    <>
      <Text>Wir haben Deine Antwort erhalten.</Text>
    </>
  );
};

export default ConfirmationMessage;
