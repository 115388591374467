import {
  BIRTHDAY_STEP_TITLE,
  DISABILITY_STEP_TITLE,
  ETHNICITY_STEP_TITLE,
  GENDER_STEP_TITLE,
  PARENTAL_LEAVE_STEP_TITLE,
  RELIGION_STEP_TITLE,
  SEXUALITY_STEP_TITLE,
  SOCIAL_BACKGROUND_STEP_TITLE,
  THANK_YOU_STEP_TITLE,
  WELCOME_STEP_TITLE,
} from "./stepTitles";

import BirthdayStep from "./BirthdateStep";
import DisabilityStep from "./DisabilityStep";
import EthnicityStep from "./EthnicityStep";
import GenderStep from "./GenderStep";
import ParentalLeaveStep from "./ParentalLeaveStep";
import ReligionStep from "./ReligionStep";
import SexualityStep from "./SexualityStep";
import SocialBackgroundStep from "./SocialStep";
import SurveeWelcomeStep from "../SurveeWelcomeSteps";
import ThankYouStep from "../ThankYouStep";

export const dimensionSteps = [
  {
    title: WELCOME_STEP_TITLE,
    component: (props) => <SurveeWelcomeStep props={props} />,
  },
  {
    title: WELCOME_STEP_TITLE,
    component: (props) => <SurveeWelcomeStep props={props} />,
  },
  {
    title: WELCOME_STEP_TITLE,
    component: (props) => <SurveeWelcomeStep props={props} />,
  },
  {
    title: GENDER_STEP_TITLE,
    component: (props) => <GenderStep props={props} />,
  },
  {
    title: BIRTHDAY_STEP_TITLE,
    component: (props) => <BirthdayStep props={props} />,
  },
  {
    title: PARENTAL_LEAVE_STEP_TITLE,
    component: (props) => <ParentalLeaveStep props={props} />,
  },
  {
    title: DISABILITY_STEP_TITLE,
    component: (props) => <DisabilityStep props={props} />,
  },
  {
    title: RELIGION_STEP_TITLE,
    component: (props) => <ReligionStep props={props} />,
  },
  {
    title: SOCIAL_BACKGROUND_STEP_TITLE,
    component: (props) => <SocialBackgroundStep props={props} />,
  },
  {
    title: ETHNICITY_STEP_TITLE,
    component: (props) => <EthnicityStep props={props} />,
  },
  {
    title: SEXUALITY_STEP_TITLE,
    component: (props) => <SexualityStep props={props} />,
  },
  {
    title: THANK_YOU_STEP_TITLE,
    component: (props) => <ThankYouStep props={props} />,
  },
];

export const defaultSteps = [
  WELCOME_STEP_TITLE,
  PARENTAL_LEAVE_STEP_TITLE,
  THANK_YOU_STEP_TITLE,
];
