import colors from "../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  height: 55vh;
  width: 50%;
  overflow: scroll;
  border-right: 1px solid ${colors.darkGrey};
  align-self: start;
  margin-left: 34px;
  margin-bottom: 24px;
`;
