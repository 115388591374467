import "moment/locale/de";
import "react-toggle/style.css";

import {
  CloseIconContainer,
  Container,
  ContentContainer,
  DescContainer,
  Description,
  Footer,
  Link,
  Logo,
  MobileUpperContainer,
  SideContainer,
  Title,
  TitleContainer,
} from "./styles";

import { ReactComponent as CloseIcon } from "../../../../icons/close_rounded.svg";
import Modal from "react-modal";
import colors from "../../../common/colors";
import { getModalStyle } from "../../../common/modalStyle";
import logo from "../../../../assets/images/Diverlyze_Logo_Large_Midnight-Coral.png";
import usePortalSize from "../../../../hooks/usePortalSize";

const DimensionInfoModal = ({ isModalOpen, closeModal, content }) => {
  const { dimensionName, description, externalLink } = content;

  const hasExternalLink = externalLink !== undefined;

  const size = usePortalSize();
  const isMobile = size === "s";

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={getModalStyle(size)}
      contentLabel="Dimension Info Modal"
    >
      <Container className={size}>
        {isMobile && (
          <MobileUpperContainer>
            <Logo src={logo} alt="Diverlyze_Logo"></Logo>
            <CloseIconContainer>
              <CloseIcon
                onClick={() => closeModal()}
                color="primary"
                style={{ color: colors.lavender }}
                fontSize="large"
              />
            </CloseIconContainer>
          </MobileUpperContainer>
        )}
        <SideContainer className={size}>
          <Logo src={logo} alt="Diverlyze_Logo"></Logo>
        </SideContainer>
        <ContentContainer className={size}>
          <TitleContainer>
            <Title className={size}>{dimensionName}</Title>
          </TitleContainer>
          <DescContainer>
            <Description>{description}</Description>
          </DescContainer>
          {hasExternalLink && (
            <Footer className={size}>
              <Description>
                Mehr Informationen zu dieser Dimension auf unserer Website:
              </Description>
              <Link
                className={size}
                onClick={() => window.open(content.externalLink, "_blank")}
              >
                {externalLink}
              </Link>
            </Footer>
          )}
        </ContentContainer>
        <SideContainer>
          <CloseIconContainer className={size}>
            <CloseIcon
              onClick={() => closeModal()}
              color="primary"
              style={{ color: colors.lavender }}
              fontSize="large"
            />
          </CloseIconContainer>
        </SideContainer>
      </Container>
    </Modal>
  );
};

export default DimensionInfoModal;
