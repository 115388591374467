export const getActions = ({
  selected,
  isInviteActive,
  isRemindActive,
  isDeleteActive,
}) => {
  return [
    {
      label:
        selected.length === 1 ? "Mitglied einladen" : "Mitglieder einladen",
      action: "invite",
      disable: !isInviteActive,
      tooltip: "Mind. 1 markiertes Mitglieder wurde bereits eingeladen.",
    },
    {
      label:
        selected.length === 1
          ? "Erinnerung versenden"
          : "Erinnerungen versenden",
      action: "reminder",
      disable: !isRemindActive,
      tooltip:
        "Mind. 1 markiertes Mitglieder ist bereits angemeldet oder noch nicht eingeladen.",
    },
    {
      label: selected.length === 1 ? "Mitglied löschen" : "Mitglieder löschen",
      action: "delete",
      disable: !isDeleteActive,
      tooltip: "Mind. 1 markiertes Mitglied ist ein Super Admin.",
    },
  ];
};
