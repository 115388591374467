const surveeMenuItems = [
  {
    title: "Admin-Ansicht",
    subtitle: "Wechsel zu den Ergebnissen Deiner Organisation.",
    path: "",
    navigateTo: "report",
  },
  {
    title: "Profil",
    subtitle: "Verwalte Deine persönlichen Profildaten.",
    path: "profile",
    navigateTo: "/settings/profile",
  },
  {
    title: "Ausloggen",
    subtitle: "Bis zum nächsten Mal!",
    path: "logout",
    navigateTo: "login",
  },
];

const adminMenuItems = [
  {
    title: "Survee-Ansicht",
    subtitle: "Wechsel zu Deiner persönlichen Ansicht.",
    path: "",
    navigateTo: "/pulse",
  },
  {
    title: "Teams",
    subtitle: "Verwalte Deine Teams und deren Führungskräfte.",
    path: "teams",
    navigateTo: "/settings/teams",
  },
  {
    title: "Mitglieder",
    subtitle: "Verwalte alle Mitglieder und deren Rechte.",
    path: "users",
    navigateTo: "/settings/users",
  },
  {
    title: "Umfrage",
    subtitle: "Stelle Deine individuellen Umfragezeiten ein.",
    path: "schedule",
    navigateTo: "/settings/schedule",
  },
  {
    title: "Ausloggen",
    subtitle: "Bis zum nächsten Mal!",
    path: "logout",
    navigateTo: "login",
  },
];

const menuItems = {
  survee: surveeMenuItems,
  admin: adminMenuItems,
  "super admin": adminMenuItems,
};

export default menuItems;
