import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const LabelText = styled("span")`
  font-size: 12px;
  color: #000;
  font-weight: 500;
  margin: 0 5px;

  &.m {
    margin: 0px 4px;
  }
  &.s {
    margin: 0px 2px;
  }
`;
