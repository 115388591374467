import { Container, InputContainer, StyledTextField, Text } from "./styles";
import React, { useEffect, useState } from "react";

import OnboardingPageContainer from "../OnboardingPageContainer/index.js";
import applyRegexToString from "./applyRegexToString";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const OrgaPrefixStep = ({ props }) => {
  const { handleNextStep } = props;

  const [loading, setLoading] = useState(true);
  const [prefix, setPrefix] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const { getAccount, patchAccountPrefix } = useLightsApiPrivate();

  useEffect(() => {
    if (loading) {
      getAccount().then((data) => {
        if (data.prefix) setPrefix(data.prefix);
        setLoading(false);
      });
    }
  });

  const MINLENGTH = 3;
  const MAXLENGTH = 6;

  const handleUpdate = (event) => {
    const prefix = applyRegexToString(event.target.value)
      .toUpperCase()
      .slice(0, 6);
    setPrefix(prefix);
    setErrMsg("");
  };

  const handleNext = async () => {
    if (prefix.length < MINLENGTH || prefix.length > MAXLENGTH) {
      setErrMsg(
        `Das Kürzel muss aus ${MINLENGTH} bis ${MAXLENGTH} Buchstaben oder Ziffern bestehen.`
      );
      return;
    }
    try {
      await patchAccountPrefix({ prefix });

      handleNextStep();
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Der Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        setErrMsg("Eine andere Organisation verwendet bereits diesen Prefix.");
      } else {
        setErrMsg("Es gab ein Problem. Bitte versuche es später erneut.");
      }
      console.error("Fehler beim erstellen", err);
    }
  };

  return (
    <OnboardingPageContainer
      props={{
        ...props,
        headline: "Wähle einen Kürzel.",
        buttonLabel: "Weiter",
        buttonActive: !errMsg,
        handleNextStep: handleNext,
      }}
    >
      <Container>
        <Text>
          Das Kürzel wird benötigt, um User-IDs für alle Mitarbeitenden zu
          generieren.
        </Text>
        <Text>
          Es muss aus {MINLENGTH} bis {MAXLENGTH} Buchstaben oder Ziffern
          bestehen.
        </Text>
        <InputContainer>
          <StyledTextField
            value={prefix}
            defaultValue={prefix}
            autoComplete="false"
            onChange={(event) => handleUpdate(event)}
            id="outlined-basic"
            variant="outlined"
            error={errMsg}
            helperText={errMsg}
            label="Kürzel"
          />
        </InputContainer>
      </Container>
    </OnboardingPageContainer>
  );
};

export default OrgaPrefixStep;
