import Alert from "@mui/material/Alert";
import colors from "../../common/colors";
import styled from "@emotion/styled";

export const StyledAlert = styled(Alert)`
  display: flex;
  position: relative;
  background-color: ${colors.lavenderLight};
  border-radius: 20px;
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 10px 0 -10px 0;

  & .MuiAlert-icon {
    position: absolute;
    top: 10%;
    left: 4%;
    color: ${colors.midnight};
  }
`;

export const TextContainer = styled("div")`
  display: flex;
  text-align: center;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-height: 250px;

  a {
    font-weight: 300;
    margin: 14px 0;
    color: ${colors.midnight};
  }
`;

export const FactHeading = styled("h2")`
  font-weight: 500;
  color: ${colors.midnight};
  font-size: 20px;
  margin: 10px 0 18px 0;
  }

`;

export const StyledParagraph = styled("p")`
  font-weight: 300;
  color: ${colors.midnight};
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
`;
