import colors from "../../common/colors";
import styled from "@emotion/styled";

export const Dropdown = styled("div")`
  width: 233px;
  background: ${colors.white};
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 0px 11px;
  border-left: 10px;
`;
