import { CloseIcon, ModalContainer } from "./styles";

import Modal from "react-modal";
import PreviewSwitch from "../PreviewSwitch";
import React from "react";
import { getModalStyle } from "../../../../../common/modalStyle";

const PreviewModal = ({
  isModalOpen,
  closeModal,
  previewData,
  errorPreviewData,
  confirmUpload,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={getModalStyle()}
      contentLabel="Preview Modal"
    >
      <ModalContainer>
        <PreviewSwitch
          previewData={previewData}
          errorPreviewData={errorPreviewData}
          confirmUpload={confirmUpload}
          closeModal={closeModal}
        />
      </ModalContainer>
      <CloseIcon onClick={closeModal} />
    </Modal>
  );
};

export default PreviewModal;
