import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LoadingAnimation from "../components/common/LoadingAnimation";
import useLightsApiPrivate from "../hooks/useLightsApiPrivate";
import useRole from "../hooks/useRole";

const SurveeRedirect = () => {
  const navigate = useNavigate();
  const { getEmployeePD } = useLightsApiPrivate();
  const location = useLocation();
  const { setRole } = useRole();

  useEffect(() => {
    getEmployeePD().then(({ role, onboardingStep }) => {
      const ONBOARDING_DONE = "done";
      const redirectToPulse = location.state?.redirectToPulse;

      if (onboardingStep === ONBOARDING_DONE && redirectToPulse) {
        setRole("survee");
        return navigate("/pulse");
      }

      if (role === "survee") {
        if (onboardingStep === ONBOARDING_DONE) {
          return navigate("/pulse");
        } else {
          return navigate("/settings/profile/onboarding");
        }
      }
      if (role === "admin") {
        if (onboardingStep === ONBOARDING_DONE) {
          return navigate("/report");
        } else {
          return navigate("/settings/profile/onboarding");
        }
      }
      if (role === "super admin") {
        if (onboardingStep === ONBOARDING_DONE) {
          return navigate("/report");
        } else {
          return navigate("/onboarding");
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingAnimation />;
};

export default SurveeRedirect;
