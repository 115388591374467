import { Outlet, useLocation } from "react-router-dom";

import React from "react";
import RoleModal from "./RoleModal";
import checkAllowedRoutes from "./checkAllowedRoutes";
import useRole from "../../hooks/useRole";

const RequireRole = () => {
  const { role } = useRole();
  const { pathname } = useLocation();

  const isAllowedRoute = checkAllowedRoutes(role, pathname);

  return (
    <>
      <RoleModal isOpen={!isAllowedRoute}></RoleModal>
      <Outlet />
    </>
  );
};

export default RequireRole;
