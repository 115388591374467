import React, { useContext, useEffect, useState } from "react";

import ActionArea from ".././ActionArea";
import AreaSection from "../../../common/AreaSection";
import { AreaSectionHeader } from "../../../common/AreaSectionHeader";
import EmployeeContext from "../../../../context/EmployeeContext";
import Heading1 from "../../../common/Heading1";
import LoadingAnimation from "../../../common/LoadingAnimation";
import { MissingTeamsInfo } from "./styles";
import TeamsTable from "../TeamsTable";
import colors from "../../../common/colors";
import compare from "../../../../utils/sort";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const TeamsPage = () => {
  const [teams, setTeams] = useState();
  const [loading, setLoading] = useState(true);
  const { employees, setEmployees } = useContext(EmployeeContext);

  const { getTeams, getEmployees } = useLightsApiPrivate();

  useEffect(() => {
    if (loading) {
      getTeams().then((data) => {
        const preparedTeams = prepareTeamsData(data);
        setTeams(preparedTeams);
        setLoading(false);
      });
    }
  });

  if (!employees || loading) {
    getEmployees().then((data) => {
      setEmployees(data);
    });
  }

  const prepareTeamsData = (teams) => {
    const newList = teams.map((team) => {
      const { id, name, womenShare, amount, teamLeads, createdAt } = team;
      const teamLeadNames = teamLeads.map(
        (teamLead) => `${teamLead.firstName} ${teamLead.lastName}`
      );

      return {
        id,
        name,
        lead: teamLeadNames.join(", "),
        amount,
        womenShare: `${womenShare}%`,
        createdAt,
      };
    });
    return newList.sort(compare);
  };

  const updateTeamList = async () => {
    const data = await getTeams();
    const preparedEmployees = prepareTeamsData(data);
    setTeams(preparedEmployees);
  };

  const isLoading = !employees || !teams || loading;

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Teams</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.TeamsSection}>
        <ActionArea employees={employees} updateTeamList={updateTeamList} />
        {teams.length === 0 ? (
          <MissingTeamsInfo>Du hast noch kein Team erstellt.</MissingTeamsInfo>
        ) : (
          <TeamsTable teams={teams}></TeamsTable>
        )}
      </AreaSection>
    </>
  );
};

export default TeamsPage;

const sectionContent = {
  TeamsSection: {
    id: "teams",
    title: "Deine Teams",
    infoText:
      "Hier kannst Du alle eingeteilten Gruppen im Detail anschauen und verwalten.",
    hoverText:
      "Die Informationen werden genutzt, damit Du die Scores der Teams vergleichen kannst. Diese findest Du auf der Teams Seite.",
  },
};
