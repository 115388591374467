import { FactSourceLink, FactText } from "./styles";

import { Flex } from "@react-spectrum/layout";
import React from "react";
import usePortalSize from "../../../../../hooks/usePortalSize";

const Fact = ({ content, openModal }) => {
  const { fact, factSource } = content;
  const size = usePortalSize();

  return (
    <Flex
      width={"100%"}
      height={"100%"}
      direction={"column"}
      justifyContent={"space-around"}
    >
      <FactText className={size}>{fact}</FactText>
      <FactSourceLink onClick={() => openModal()} className={size}>
        Quelle: {factSource}
      </FactSourceLink>
    </Flex>
  );
};

export default Fact;
