import DimensionStep from "./DimensionStep";
import OrgaPrefixStep from "./OrgaPrefixStep";
import React from "react";
import ScheduleStep from "./ScheduleStep";
import SurveeInvitaionStep from "./SurveeInvitationStep";
import UserImportStep from "./UserImportStep";
import WelcomeStep from "./WelcomeStep";
import importImg from "../../../assets/images/onboarding/importStep.jpg";
import introImg from "../../../assets/images/onboarding/introStep.jpg";
import scheduleImg from "../../../assets/images/onboarding/scheduleStep.jpg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";
import welcomeImg from "../../../assets/images/login_background.jpg";

const OnboardingPage = () => {
  const query = useQuery();
  const queryStepString = query.get("step");
  const navigate = useNavigate();
  const queryStep = queryStepString ? parseInt(queryStepString) : 0;

  const [step, setStep] = React.useState(queryStep);

  const handleNextStep = () => {
    if (step === 5) {
      navigate("/report");
    } else {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const props = {
    handleNextStep,
    handlePrevStep,
    step,
  };

  switch (step) {
    case 0:
      return (
        <WelcomeStep props={{ ...props, img: onboardingImages.welcome }} />
      );
    case 1:
      return (
        <DimensionStep props={{ ...props, img: onboardingImages.import }} />
      );
    case 2:
      return (
        <OrgaPrefixStep props={{ ...props, img: onboardingImages.import }} />
      );
    case 3:
      return (
        <UserImportStep props={{ ...props, img: onboardingImages.import }} />
      );
    case 4:
      return (
        <SurveeInvitaionStep
          props={{ ...props, img: onboardingImages.import }}
        />
      );
    case 5:
      return (
        <ScheduleStep props={{ ...props, img: onboardingImages.schedule }} />
      );
    default:
      return (
        <WelcomeStep props={{ ...props, img: onboardingImages.welcome }} />
      );
  }
};

const onboardingImages = {
  welcome: welcomeImg,
  import: importImg,
  schedule: scheduleImg,
  intro: introImg,
};

export default OnboardingPage;
