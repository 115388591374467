import Dropdown from "../../../../../common/Dropdown";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import { ethnicityOptions } from "../../../diversityOptions";

const EthnicityInput = ({ props }) => {
  const { employee, updateEthnicities, dynamicSize } = props;
  return (
    <FieldContainer>
      <Dropdown
        size={dynamicSize}
        value={employee.ethnicities}
        label="Ethnische Herkunft"
        menuItems={ethnicityOptions}
        multiple
        onChange={({ target }) => updateEthnicities(target.value)}
      />
    </FieldContainer>
  );
};

export default EthnicityInput;
