import { AlertMailLink, StyledAlert } from "./styles";

import React from "react";

const CustomAlert = () => {
  return (
    <StyledAlert variant="outlined" severity="warning">
      Das Dialog Feature ist in Deinem aktuellen Abonnement nicht enthalten.
      Wenn Du einen Chat eröffnen willst, wende Dich bitte an{" "}
      <AlertMailLink href="mailto:support@diverlyze.com">
        support@diverlyze.com
      </AlertMailLink>
      , um das Feature zu aktivieren.
    </StyledAlert>
  );
};

export default CustomAlert;
