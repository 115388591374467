import colors from "../../../../common/colors";
import dropdown_arrow from "../../../../../assets/images/dropdown_arrow.png";
import styled from "@emotion/styled";

export const menuStyles = {
  borderRadius: "3px",
  backgroundColor: `${colors.grey}`,
  color: `${colors.midnight}`,
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
  listStyle: "none",
  fontWeight: "500",
  textAlign: "center",
  width: "100px",
  zIndex: "100",
};

export const menuStylesClosed = {
  backgroundColor: `${colors.grey}`,
  color: `${colors.midnight}`,
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
  listStyle: "none",

  height: "160px",
  fontWeight: "500",
  overflow: "scroll",
  textAlign: "center",
  cursor: "pointer",
  width: "100px",
  position: "absolute",
  zIndex: "100",
  marginTop: "56px",
};

export const Label = styled("label")`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.color};
  margin-bottom: 5px;
  margin-left: 5px;
`;

export const DropdownContainer = styled("div")`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const Button = styled("button")`
  margin-top: 3px;
  background-color: ${(props) =>
    props.activeSchedule ? `${colors.coral}` : `${colors.grey}`};
  border-radius: 5px;
  height: 30px;
  width: 145px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.midnight};
  cursor: pointer;
`;

export const ListItem = styled("li")`
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: ${colors.grey};
`;

export const DropdownIcon = styled("div")`
  background-image: url(${dropdown_arrow});
  background-size: cover;
  margin-left: 5px;
  margin-top: -14px;
  width: 11px;
  height: 10px;
`;
