import React, { useState } from "react";

import CsvExport from "../CsvExport";
import ErrorPreviewTable from "../ErrorPreviewTable";
import PreviewTable from "../PreviewTable";

const PreviewSwitch = ({
  errorPreviewData,
  previewData,
  confirmUpload,
  closeModal,
}) => {
  const [previewStep, setPreviewStep] = useState("error");

  switch (previewStep) {
    case "error":
      return (
        <ErrorPreviewTable
          previewData={errorPreviewData}
          setPreviewStep={setPreviewStep}
          closeModal={closeModal}
        />
      );
    case "export":
      return (
        <CsvExport
          errorPreviewData={errorPreviewData}
          setPreviewStep={setPreviewStep}
        />
      );
    case "upload":
      return (
        <PreviewTable
          previewData={previewData}
          confirmUpload={confirmUpload}
          closeModal={closeModal}
        />
      );
    default:
      return (
        <PreviewTable
          previewData={previewData}
          confirmUpload={confirmUpload}
          closeModal={closeModal}
        />
      );
  }
};

export default PreviewSwitch;
