import { Checkbox } from "@mui/material";
import { Flex } from "@react-spectrum/layout";
import OptionStepContainer from "../OptionStepContainer";
import { PARENTAL_LEAVE_STEP_TITLE } from "./stepTitles";
import React from "react";
import Text from "../../../common/Text";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const ParentalLeaveStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [hadParentingTime, setHadParentingTime] = React.useState(
    employee.hadParentingTime
  );

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, hadParentingTime });

    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, hadParentingTime });
    handleNextStep();
  };

  props = {
    ...props,
    selected: true,
    step,
    saveAndNext,
    dimension: PARENTAL_LEAVE_STEP_TITLE,
  };

  return (
    <OptionStepContainer props={props}>
      <Flex width={"100%"} justifyContent={"center"} marginStart={"40px"}>
        <Checkbox
          checked={hadParentingTime}
          onChange={({ target }) => {
            setHadParentingTime(target.checked);
          }}
        />
        <Text>Ja, habe ich</Text>
      </Flex>
    </OptionStepContainer>
  );
};

export default ParentalLeaveStep;
