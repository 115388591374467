import React, { useContext, useEffect } from "react";

import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import EmployeeContext from "../../../context/EmployeeContext";
import Heading1 from "../../common/Heading1";
import HistoryContext from "../../../context/HistoryContext";
import LoadingAnimation from "../../common/LoadingAnimation";
import TeamEditForm from "./TeamEditForm";
import colors from "../../common/colors";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import { useParams } from "react-router-dom";

const TeamProfilePage = () => {
  const { id } = useParams();
  const { getTeam, getEmployees } = useLightsApiPrivate();

  const [team, setTeam] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const { employees, setEmployees } = useContext(EmployeeContext);
  const { history } = useContext(HistoryContext);

  useEffect(() => {
    const fetchTeam = async () => {
      setLoading(false);
      const team = await getTeam(id);
      const employees = await getEmployees();
      setTeam(team);
      setEmployees(employees);
    };

    if (loading) {
      fetchTeam().catch(console.error);
    }
  });

  if (!employees) {
    getEmployees().then((data) => {
      setEmployees(data);
    });
  }
  const isLoading = !employees || !team || loading;

  const sectionContent = {
    focusSection: {
      id: "profile",
      title: `Profil`,
      infoText:
        "Hier kannst Du Dir ein Team im Detail anschauen und verwalten.",
      hoverText: "Diese Daten wurden beim Onboarding importiert.",
    },
    historySection: {
      id: "history",
      title: "Edit history",
      infoText: "see all changes made to this team",
      hoverText: "",
    },
  };

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Teams</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.focusSection} backLinkTo={history}>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <TeamEditForm team={team} employees={employees} />
        )}
      </AreaSection>
      {/* <AreaSection content={sectionContent.historySection}></AreaSection> */}
    </>
  );
};

export default TeamProfilePage;
