import styled from "@emotion/styled/macro";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Checkcontainer = styled("img")`
  min-width: 150px;
  min-height: 150px;
  width: 35%;
  margin: -6% 0;
`;

export const FactContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -30px;
`;
