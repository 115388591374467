import {
  Container,
  MenuContainer,
  Settings,
  SettingsPictureContainer,
} from "./styles";
import React, { useRef } from "react";

import Logo from "../Logo";
import MenuDropdown from "../MenuDropdown";
import MenuDropdownMobile from "../MenuDropdownMobile";
import NavIcon from "../NavIcon";
import Navigation from "../Navigation";
import { useLocation } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import usePortalSize from "../../../hooks/usePortalSize";

const Header = () => {
  const [showMenu, setShowMenu] = React.useState(false);

  const ref = useRef();
  const profileRef = useRef();
  useOnClickOutside(ref, () => setShowMenu(false), profileRef);

  const { pathname: path } = useLocation();
  const pathlocation = path.split("/")[1];
  const isSettingsActive = pathlocation === "settings" || showMenu === true;
  const windowSize = usePortalSize();
  const mobileView = windowSize === "s" ? false : true;

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <Container>
      <Logo />
      {mobileView ? (
        <>
          <Navigation />
          <Settings>
            <SettingsPictureContainer
              ref={profileRef}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              <NavIcon path={"settings"} active={isSettingsActive} />
            </SettingsPictureContainer>
          </Settings>
          <MenuContainer>
            {showMenu && <MenuDropdown innerRef={ref} closeMenu={closeMenu} />}
          </MenuContainer>
        </>
      ) : (
        <MenuDropdownMobile innerRef={ref} />
      )}
    </Container>
  );
};

export default React.memo(Header);
