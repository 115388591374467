import { Button } from "@mui/material";
import colors from "../../common/colors";
import styled from "@emotion/styled";

/*Outer Container*/
export const LoginContainer = styled("div")`
  &.m {
    width: 100%;
  }
  &.s {
    width: 100%;
  }
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/*Container for buttons and inputfields*/
export const FormContainer = styled("div")`
  &.m {
    max-width: 80%;
  }
  &.s {
    min-width: 80%;
  }
  width: 35%;
  overflow: hidden;
`;

/*Container for button*/
export const ButtonContainer = styled("div")`
  margin-top: 20px;
  margin-bottom: 10px;
`;

/*Attributes for subheader*/
export const SubHeader = styled("div")`
  color: ${colors.darkGrey};
  font-weight: 500;
  margin-bottom: 1rem;
`;

/*Container for inputfields*/
export const InputContainer = styled("form")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 20px;
`;

/*Attributes for button*/
export const StyledButton = styled(Button)`
  width: 100%;
`;

export const MailLink = styled("a")`
  color: white;
`;
