import { LoadingContainer, ModalButtonContainer } from "./styles";

import { Button } from "@mui/material";
import Heading2 from "../../../../../common/Heading2";
import LoadingAnimation from "../../../../../common/LoadingAnimation";
import Modal from "react-modal";
import React from "react";
import { getModalStyle } from "../../../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../../hooks/useLightsApiPrivate";

const InvitationModal = ({
  isModalOpen,
  closeModal,
  setNewEmps,
  updateEmployeeList,
  setSelected,
  selected,
}) => {
  const [inviteLoading, setInviteLoading] = React.useState(false);

  const { postSelectedEmployeeInvite } = useLightsApiPrivate();
  const alert = useAlert();

  const inviteSelectedEmployees = async () => {
    setInviteLoading(true);
    try {
      const selectedIds = selected
        .filter((emp) => emp.status === "created")
        .map((emp) => emp.id);
      await postSelectedEmployeeInvite(selectedIds);
      setNewEmps([]);
      updateEmployeeList();
      alert.success("Einladungen versendet.");
      setSelected([]);
      setInviteLoading(false);
    } catch (e) {
      alert.error("Es ist ein Fehler aufgetreten.");
      setInviteLoading(false);
      closeModal();
    }
  };

  const confirmModal = async () => {
    inviteSelectedEmployees();
    closeModal();
  };

  const displayedText =
    selected > 1
      ? `${selected} neu erstellte Mitglieder`
      : `Ein neu erstelltes Mitglied`;

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={getModalStyle()}
      contentLabel="Invitation"
    >
      {inviteLoading && (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      )}
      <Heading2>Einladungen versenden</Heading2>
      <p>{displayedText} per E-Mail einladen?</p>
      <ModalButtonContainer>
        <Button onClick={closeModal} variant="outlined">
          Abbrechen
        </Button>
        <Button onClick={confirmModal} variant="confirm">
          Einladen
        </Button>
      </ModalButtonContainer>
    </Modal>
  );
};

export default InvitationModal;
