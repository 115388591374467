import { Autocomplete, TextField } from "@mui/material";

import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  margin-left: 34px;
  margin-right: 34px;
  margin-bottom: 20px;
  width: 94%;
`;

export const TopContainer = styled("div")`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const MemberContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 14px;
`;

export const ButtonContainer = styled("div")`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LeadAutoComplete = styled(Autocomplete)`
  margin-left: 20px;
  width: 50%;
`;

export const StyledTextField = styled(TextField)`
  width: 50%;
`;

export const DiversityInfoText = styled("i")`
  margin-top: 10px;
  font-size: 12px;
`;
