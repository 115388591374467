import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import Heading1 from "../../common/Heading1";
import React from "react";
import Scheduler from "./schedule/Scheduler";
import colors from "../../common/colors";

const SchedulePage = () => {
  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Umfrage</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.ScheduleSection}>
        <Scheduler />
      </AreaSection>
    </>
  );
};

export default SchedulePage;

const sectionContent = {
  ScheduleSection: {
    id: "schedule",
    title: "Pulse-Check-Mails",
    infoText:
      "Hier kannst Du einstellen, wann die Teammitglieder ihre Fragen erhalten sollen. Die Beantwortungsfrist ist zeitlich unbegrenzt.",
    hoverText:
      "Anhand dieser Planung bestimmt Du den Zeitpunkt, an dem alle Mitarbeiter*innen die Fragen erhalten sollen. Die Änderungen sind ab dem nächsten Tag gültig.",
  },
};
