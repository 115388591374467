import {
  LoadingContainer,
  ModalButtonContainer,
} from "../modals/InvitationModal/styles";

import { Button } from "@mui/material";
import Heading2 from "../../../../common/Heading2";
import LoadingAnimation from "../../../../common/LoadingAnimation";
import Modal from "react-modal";
import React from "react";
import { getModalStyle } from "../../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";

const ReminderModal = ({
  isModalOpen,
  closeModal,
  updateEmployeeList,
  selected,
  setSelected,
}) => {
  const [inviteLoading, setInviteLoading] = React.useState(false);

  const { postEmployeesReminder } = useLightsApiPrivate();
  const alert = useAlert();

  const sendReminder = async () => {
    setInviteLoading(true);
    try {
      const selectedIds = selected
        .filter((emp) => emp.status === "invited")
        .map((emp) => emp.id);
      await postEmployeesReminder(selectedIds);
      updateEmployeeList();
      alert.success("Erinnerungen versendet.");
      setInviteLoading(false);
      setSelected([]);
      closeModal();
    } catch (e) {
      alert.error("Es ist ein Fehler aufgetreten.");
      setInviteLoading(false);
      closeModal();
    }
  };

  const displayText =
    selected.length > 1
      ? `${selected.length} neue Mitglieder an die ausstehende Registrierung erinnern?`
      : "Ein neues Mitglied an die ausstehende Registrierung erinnern?";
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={getModalStyle()}
      contentLabel="Invitation"
    >
      {inviteLoading && (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      )}
      <Heading2>Erinnerung versenden</Heading2>
      <p>{displayText}</p>
      <ModalButtonContainer>
        <Button onClick={closeModal} variant="outlined">
          Abbrechen
        </Button>
        <Button onClick={sendReminder} variant="confirm">
          Senden
        </Button>
      </ModalButtonContainer>
    </Modal>
  );
};

export default ReminderModal;
