import Dropdown from "../../../common/Dropdown";
import OptionStepContainer from "../OptionStepContainer";
import { RELIGION_STEP_TITLE } from "./stepTitles";
import React from "react";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { religionOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const ReligionStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [religion, setReligion] = React.useState(
    employee.religion ? employee.religion : "no_religion"
  );

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, religion });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, religion });
    handleNextStep();
  };

  props = {
    ...props,
    selected: religion,
    step,
    saveAndNext,
    dimension: RELIGION_STEP_TITLE,
  };

  return (
    <OptionStepContainer props={props}>
      <Dropdown
        value={religion ? religion : "not_applicable"}
        label="Religion"
        menuItems={religionOptions}
        onChange={({ target }) => setReligion(target.value)}
        size="small"
      />
    </OptionStepContainer>
  );
};

export default ReligionStep;
