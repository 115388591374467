import colors from "../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 45%;
  height: 55vh;
  border-radius: 10;
  margin-left: 34px;
  margin-right: 34px;
  margin-bottom: 24px;
`;

export const CommentContainer = styled("div")`
  display: flex;
  align-self: end;
  width: 100%;
`;

export const Question = styled("div")`
  left: 20px;
  font-weight: 500;
  font-size: 16px;
  font-style: italic;
`;

export const SendButton = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  background-color: ${colors.lavender};
  width: 55px;
  height: 35px;
  cursor: pointer;
`;

export const SendButtonContainer = styled("div")`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 10px;
`;

export const TextFieldContainer = styled("div")`
  width: 100%;
`;
