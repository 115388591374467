import background from "../../../assets/images/background.jpg";
import styled from "@emotion/styled";

export const Container = styled("div")`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled("div")`
  position: absolute;
  height: fit-content;
  min-height: 100%;
  top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-repeat: repeat;
  background-size: cover;
  background-image: url(${background});
  padding-bottom: 34px;
  &.s {
    background-position: top;
    background-size: 300%;
  }
`;
