import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 600px;
`;

export const Footer = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StyledDataGrid = styled(DataGrid)`
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;
