import styled from "@emotion/styled";
export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 0;
  cursor: pointer;
  margin-bottom: 10px;
  width: 150px;
  min-width: 150px;
  margin-top: 5px;
  font-size: 13px;
  margin-left: 0px;
`;
